const CardOrCardEMI = (method) => {
  let card = /^card$/;
  let emi = /^emi$/;
  if (card.test(method) || emi.test(method)) {
    return true;
  }
};

const paymentValidation = (val, upiAppName) => {
  let { method } = val.method;
  let { paidThrough } = val;
  if (method === "netbanking") {
    // Netbanking
    if (val.method.bank.length > 3) {
      return true;
    } else {
      return "Select Bank name";
    }
  } else if (method === "upi") {
    // UPI
    if (upiAppName) {
      return true;
    }
    if (method === "upi" && "gpay" in val.method) {
      if (val.method.gpay) {
        return true;
      } else {
        return false;
      }
    } else {
      if (Object.keys(val?.method?.upi)) {
        return true;
      } else {
        return "Not a Valid UPI";
      }
    }
  } else if (CardOrCardEMI(method)) {
    // card and cardless emi
    let yearValid = null;
    if (val.method["card[expiry_year]"] && val.method["card[expiry_month]"]) {
      let expiryDate = new Date();
      let expiryYear = parseInt(val.method["card[expiry_year]"]) + 2000;

      let expiryCompleteYear = expiryDate.setFullYear(expiryYear, val.method["card[expiry_month]"]);
      if (parseInt(val.method["card[expiry_month]"]) <= 12) {
        if (expiryCompleteYear > new Date()) {
          yearValid = true;
        } else {
          yearValid = false;
        }
      } else {
        yearValid = false;
      }
    } else {
      yearValid = false;
    }

    let size = {
      "card[number]": 16,
      "card[cvv]": 3,
      "card[name]": 3,
    };
    let res = [val.method].map((object) =>
      Object.keys(object).filter((key) => {
        return object[key] === "" || object[key].length < size[key];
      })
    )[0];

    if (yearValid === false) {
      if (res?.indexOf("card[expiry_month]") === -1) {
        res?.push(["card[expiry_month]"][0]);
      }
    }

    return res.length > 0 ? res.push(paidThrough) && res : true;
  } else if (method === "cardless_emi") {
    return true;
  } else if (method === "BNPL") {
    return true;
  }
};

const handleOnlyNumber = (e, callback) => {
  const re = /^\d+$/;
  let res = re.test(e.target.value);
  if (res) {
    callback(e);
  } else if (e.target.value.length === 0) {
    callback(e);
  }
};

const handleExpiryDate = (e, callback) => {
  if (e.target.value.replace(/[^0-9]/g, "")) {
    return callback(e);
  }
};

function hasNumber(myString) {
  var regex = /^[a-zA-Z]*$/;
  return regex.test(myString);
}

const handleOnlyCharacters = (e, callback, len = 1) => {
  const val = hasNumber(e.target.value);
  if (!val) {
    return callback(e);
  }
};

const handleOnlyAlphabets = (e, callback) => {
  const re = /^[A-Za-z\s]+$/;
  let res = re.test(e.target.value);
  if (res) {
    callback(e);
  } else if (e.target.value.length === 0) {
    callback(e);
  }
};

export { paymentValidation, handleOnlyNumber, handleExpiryDate, handleOnlyCharacters, handleOnlyAlphabets };
