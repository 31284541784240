import { useState } from "react";

// maximum business logic will be moved here

export default function useCardPayment() {
  const [positionTop, setPositionTop] = useState(0);
  const [bank, setBank] = useState(null);

  const handleEMIPopUPPosition = (popUpRef) => {
    setPositionTop(popUpRef.current.el.offsetHeight - popUpRef.current.el.children[0].offsetHeight + 50);
  };

  return {
    positionTop,
    handleEMIPopUPPosition,
    bank,
    setBank,
  };
}
