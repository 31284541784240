import { Row, Sheet, PageContent, Icon, Input } from "framework7-react";
import React from "react";
import useBankList from "./useBankList.hook";

const BankList = ({ open, setOpen, banks = [], selectedBank, onChange }) => {
  const { searchText, setSearchText, filteredBanks } = useBankList(banks, open);
  return (
    <Sheet
      opened={open}
      style={{
        zIndex: 11000000,
        height: "100%",
        paddingTop: "40vh",
        "--f7-sheet-bg-color": "rgba(0, 0, 0, 0.3)",
      }}
    >
      <PageContent className="bank-list-content">
        <Row className="banks-header">
          <h3>Select Bank</h3>
          <div onClick={() => setOpen(false)}>
            <Icon size="25" f7="multiply"></Icon>
          </div>
        </Row>
        <Row style={{ margin: "0px 20px 20px 20px" }}>
          <div className="search-container">
            <Input
              type="text"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              inputStyle={{ height: 40 }}
            ></Input>
            <div className="search-icon">
              <Icon size="24" f7="search"></Icon>
            </div>
          </div>
        </Row>
        <div className="banks-list">
          {filteredBanks.map((bank) => {
            const isSelected = bank.key === selectedBank;
            return (
              <div
                key={bank.key}
                className="banks-list-item"
                onClick={() => {
                  setOpen(false);
                  onChange(bank.key, bank.value);
                }}
              >
                <div className="bank-icon">
                  <img src={bank.icon} alt="bank" />
                </div>
                <div className="name">{bank.value && bank.value.replace(" (", "\n(")}</div>
                {isSelected ? (
                  <div style={{ marginRight: 8, color: "green" }}>
                    <Icon size="20" f7="checkmark_square_fill"></Icon>
                  </div>
                ) : (
                  <div style={{ marginRight: 8, color: "#7E7E7E" }}>
                    <Icon size="20" f7="app"></Icon>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </PageContent>
    </Sheet>
  );
};

export default BankList;
