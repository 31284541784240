import UpiMethod from "../../components/PaymentInputMethods/Upi";
import NetBanking from "../../components/PaymentInputMethods/NetBanking";
import CardPayment from "../../components/PaymentInputMethods/CardPayment";
// import DebitCardEmi from "../../components/PaymentInputMethods/DebitCardEmi";
import CardLessEMI from "../../components/PaymentInputMethods/CardLessEMI";
import Rupifi from "../../components/PaymentInputMethods/Rupifi";
const paymentMethods = {
  CARDLESS_EMI: {
    text: "Cardless EMI",
    Component: CardLessEMI,
    order: 7,
    paymentSuccessMessage: "Your Card less EMI was set up successfully.",
    Icon: "/images/payment_method_icons/emi.svg",
  },
  CREDIT_CARD_EMI: {
    text: "Credit Card EMI",
    Component: CardPayment,
    order: 4,
    paymentSuccessMessage: "Your Credit Card EMI was set up successfully.",
    Icon: "/images/payment_method_icons/emi.svg",
  },
  UPI: { text: "UPI", Component: UpiMethod, order: 1, paymentSuccessMessage: "Your UPI payment has been successful.", Icon: "/images/payment_method_icons/shipping-3.svg" },
  CREDIT_CARD: {
    text: "Credit Card",
    Component: CardPayment,
    order: 2,
    paymentSuccessMessage: "Your Credit card payment has been successful.",
    Icon: "/images/payment_method_icons/cc.svg",
  },
  DEBIT_CARD: {
    text: "Debit Card",
    Component: CardPayment,
    order: 3,
    paymentSuccessMessage: "Your Debit card payment has been successful.",
    Icon: "/images/payment_method_icons/dc.svg",
  },
  NET_BANKING: {
    text: "Net Banking",
    Component: NetBanking,
    order: 6,
    paymentSuccessMessage: "Your Net Banking payment has been successful.",
    Icon: "/images/payment_method_icons/netbanking.svg",
  },
  DEBIT_CARD_EMI: {
    text: "Debit Card EMI",
    Component: CardPayment,
    order: 5,
    paymentSuccessMessage: "Your Debit Card EMI was set up successfully.",
    Icon: "/images/payment_method_icons/emi.svg",
  },
  BNPL: {
    text: "Arzooo Credit",
    Component: Rupifi,
    order: 5,
    paymentSuccessMessage: "Your Debit Card EMI was set up successfully.",
    Icon: "/images/payment_method_icons/emi.svg",
  }
};

export default paymentMethods;
