import { ListInput, Button, List } from "framework7-react";
import useShopseSendOtp from "./useProtiumSendOtp.hook";

const SendOTP = ({ orderId, handleClose, handleOTPSentCallback, mobileNumber, disabledButton }) => {
  const { handleSendOTP, phoneNumber, setPhoneNumber, errorMsg, handleSheetClose } = useShopseSendOtp(mobileNumber, handleClose);

  const handleSubmit = (e, orderId, handleOTPSentCallback) => {
    e.preventDefault();
    handleSendOTP(orderId, handleOTPSentCallback);
  };

  return (
    <>
      <h4 style={{ marginTop: "0px", marginBottom: "0px", textAlign: "center" }}>Check your eligibility</h4>
      <form onSubmit={(e) => handleSubmit(e, orderId, handleOTPSentCallback)}>
        <div style={{ paddingBottom: "10px", width: "80%", margin: "auto" }}>
          <List noHairlinesMd style={{ marginBottom: "10px" }}>
            <ListInput
              label="Mobile No."
              floatingLabel
              type="tel"
              placeholder="Your phone number"
              value={phoneNumber || ""}
              clearButton
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              minlength="10"
              maxlength="10"
              validate
              errorMessage="Enter valid mobile number"
              pattern="^[6-9]\d{9}$"
            />
          </List>
        </div>
        {errorMsg && <div style={{ textAlign: "center", color: "red", marginBottom: "10px" }}>{errorMsg}</div>}
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button type="submit" fill color="green" style={{ width: "30%" }} disabled={disabledButton}>
            Send OTP
          </Button>

          <Button color="green" outline style={{ width: "30%" }} onClick={handleSheetClose}>
            Close
          </Button>
        </div>
      </form>
    </>
  );
};

export default SendOTP;
