import Api from "./api.service";

const fetchOfferDetailsApi = async (offerId) => {
  const resp = await Api.currentCategoryOffers({}, offerId);
  if (resp.ok) {
    return resp.data;
  }
};

export { fetchOfferDetailsApi };
