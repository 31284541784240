import React from "react";
import { f7 } from "framework7-react";
import { fetchOfferDetailsApi } from "../../services/apiAbstractions.service";
import { DISPATCH_SET_SELECTED_OFFER } from "../../store";

export const useFetchOfferDetails = (f7router) => {
  const [sheetOpened, setSheetOpened] = React.useState(false);
  const [offerDetail, setOfferDetail] = React.useState([]);
  const [offer, setOffer] = React.useState(false);
  const [offerData, setOfferData] = React.useState({});
  const [showEMIButton, setEMIButton] = React.useState(false);

  React.useEffect(() => {
    const { offerId } = f7router.currentRoute.params;
    //
    const fetchOfferDetails = async () => {
      const resp = await fetchOfferDetailsApi(offerId);
      const categoryOfferDetails = resp.categoryOffers[0];
      setOffer(categoryOfferDetails);
      f7.store.dispatch(DISPATCH_SET_SELECTED_OFFER, categoryOfferDetails);
      setOfferDetail(resp.offerDetails);
      setTimeout(() => setSheetOpened(true), 200);
    };
    fetchOfferDetails();
    return () => {
      f7.store.dispatch(DISPATCH_SET_SELECTED_OFFER, null);
      document.getElementById("offer-detail-sheet")?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (offer) {
      setOfferData(offer);
      if (offer.showEmiDetails > 0) {
        setEMIButton(true);
      }
    }
  }, [offer]);

  return [sheetOpened, offerDetail, offer, showEMIButton, offerData];
};
