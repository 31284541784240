import { event } from "./subscriber.events";
const Publisher = {
  upi: (content) => {
    event.emit("upiValidation", content);
  },

  openGpayApp: (content) => {
    event.emit("openGpay", content);
  },

  applyAndPay: (content, ...args) => {
    event.emit("applyAndPay", content, ...args);
  },

  togglePayBtn: (content) => {
    event.emit("togglePayBtn", content);
  },

  openZestWidget: (content) => {
    event.emit("openZestWidget", content);
  },
  onboardingPropmt: (content) => {
    event.emit("onboardingPropmt", content);
  },
  showPayModeError: (content) => {
    event.emit("showPayModeError", content);
  },
  openUpiApp: (content) => {
    event.emit("openUpiApps", content);
  },
};
export default Publisher;
