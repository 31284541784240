// import config from "../config";

const verifyVPAUpi = async (vpaAddress) => {
  let razorpay = new window.Razorpay({
    key: process.env.REACT_APP_UPI_VERIFICATION,
  });
  let res;
  try {
    res = await razorpay.verifyVpa(vpaAddress);
  } catch (e) {
    return {
      success: false,
    };
  }
  return res;
};

export default verifyVPAUpi;
