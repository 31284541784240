import React from "react";
import PaymentSuccess from "../../components/PaymentSuccess";
import PaymentFailed from "../../components/PaymentFailed";
import { Preloader } from "framework7-react";
import logo from "../../assets/logo/gostor-white.png";
import "./order_status.css";
import useOrderStatus from "./useOrderStatus";
import useToast from "../../components/Toast/useToast";
import Toast from "../../components/Toast";

export default function OrderStatus(f7router) {
  const { orderStatusData, loading, orderId } = useOrderStatus(f7router);
  const { isActive, openSnackBar } = useToast();
  const handleOnCopyClick = () => {
    openSnackBar();
    navigator.clipboard.writeText(orderStatusData.orderId);
  };
  return (
    <div className="page order_status">
      {loading && (
        <div className="loading_container theme-dark-text">
          <div className="loading_content">
            <div className="logo_holder">
              <img src={logo} alt="logo" />
            </div>
            {orderId && <p className="order-id">YOUR PAYMENT ID - {orderId}</p>}
            <div className="order-loader">
              <Preloader color="white" size={52} />
              <div className="status_text">Checking Payment Status</div>
              <p>Please wait ...</p>
            </div>
          </div>
        </div>
      )}

      {orderStatusData && orderStatusData?.transactionStatus === "PAYMENT_COMPLETED" && (
        <PaymentSuccess data={orderStatusData} handleOnCopyClick={handleOnCopyClick} route={f7router} />
      )}
      {orderStatusData !== null && orderStatusData?.transactionStatus !== "PAYMENT_COMPLETED" && (
        <PaymentFailed data={orderStatusData} handleOnCopyClick={handleOnCopyClick} route={f7router} />
      )}
      <Toast isActive={isActive} message="Payment ID Copied" />
    </div>
  );
}
