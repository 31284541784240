/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Icon, Link, Row } from "framework7-react";
import "./payment.css";
import React, { useEffect, useState } from "react";
import useCardlessEMI from "./useCardLessEMI.hook";
import NotificationPopUp from "../NotificationPopUp";
import { useFeature } from "@growthbook/growthbook-react";
import { AXIO, ZEST, KREDITBEE, FLEX } from "../../utilities/constants";
import SubLenders from "./SubLenders";

const skipped = [ZEST, KREDITBEE, FLEX];
export default function CardlessEMI({ updatePaymentPayload, selected, label, protium = false, paymentData, totalOrderAmount, orderId, f7router }) {
  const {
    selectedLender,
    onSelectLender,
    promptShown,
    proceedToPay,
    onBoardNewMember,
    onboardingPrompt,
    resetTransaction,
    errorMessage,
    flexLenders,
    showFlexLenders,
    // redirectToFlex,
  } = useCardlessEMI(updatePaymentPayload, selected, protium, f7router, paymentData, totalOrderAmount, orderId);

  const zestService = useFeature("gopay.enable-zest").on;
  const axioService = useFeature("gopay.enable-axio").on;
  // const instacredEnabled = useFeature("gopay.enable-instacred").on;

  const [lenders, setLenders] = useState(paymentData?.activeLenders);

  useEffect(() => {
    if (paymentData?.instrumentData?.lender) {
      console.log("activeLenders ===>", paymentData?.activeLenders);
      console.log("instrumentData ===>", paymentData?.instrumentData);
      const activeLender = paymentData?.activeLenders.filter((lender) => paymentData?.instrumentData?.lender === lender.partner);
      setLenders(activeLender);
      onSelectLender(activeLender[0]);
      // if (paymentData?.instrumentData?.lender === FLEX) {
      //   if (activeLender[0].mode === FLEX) {
      //     proceedToPay();
      //   } else {
      //     redirectToFlex();
      //   }
      // } else
      if (!skipped.includes(paymentData?.instrumentData?.lender)) {
        proceedToPay();
      }
    }
  }, []);

  useEffect(() => {
    if (!paymentData?.instrumentData?.lender) {
      if (lenders && lenders.length === 1) onSelectLender(lenders[0]);
    }
  }, [paymentData]);

  return (
    <>
      <div className="cardless_emi_container">
        {errorMessage ? (
          <Row>
            <Col style={{ padding: "0 0px 10px 25px" }}>
              <div className="wrong">{errorMessage}</div>
            </Col>
          </Row>
        ) : null}
        {lenders.length > 0 && (
          <div className="gopay__grid">
            {lenders.map((lender) => {
              if (!zestService && lender.partner === ZEST) return null;
              if (!axioService && lender.partner === AXIO) return null;
              return (
                <div className="arz_col_lender_max" key={lender.partner} onClick={() => onSelectLender(lender)}>
                  {selectedLender === lender.partner ? (
                    <Icon size={20} icon="arz__selected_lender" f7="checkmark_circle_fill" />
                  ) : (
                    <Icon size={20} icon="arz__selected_lender" f7="circle" />
                  )}
                  <Icon size={40} className="arz__icon_lender">
                    <img slot="media" src={lender.lenderIcon} width="40" alt={lender.partner} style={{ verticalAlign: "top", objectFit: "scale-down" }} />
                  </Icon>
                  <p style={{ color: "#000", margin: "5px 0 0" }}>{lender.partner}</p>
                </div>
              );
            })}
          </div>
        )}
        <div className="emi_calculator_title">
          Avail instant no-cost emi upto 12 months, check
          <span>
            <Link className="emi_detail_text" popupOpen=".emi-popup">
              details
            </Link>
          </span>
        </div>
      </div>
      {/* Confirmation Propmpt for Zest */}
      <NotificationPopUp sheetStatus={promptShown} onCancel={onBoardNewMember} title="Are you an existing customer on zest?" onSubmit={proceedToPay} />
      {/* Confirmation either the */}
      <NotificationPopUp sheetStatus={onboardingPrompt} onCancel={resetTransaction} title="Were you eligible for a limit on zest" onSubmit={proceedToPay} />
      {/* Flex Lenders */}
      <SubLenders open={showFlexLenders} lenders={flexLenders} />
    </>
  ); // we dont need to render anything when this is a CARDLESS EMI payment method
}
