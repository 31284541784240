import React from "react";

export default function ProductDetails({ data }) {
  return (
    <>
      {data.map((product, i) => {
        return (
          <div key={i} className="payment_item">
            <div className="product_image_store">
              <div className="product_image_container">
                <img src={product.categoryIcon} alt="product_category" />
              </div>
            </div>
            <div className="item_name">
              <p>
                <span style={{ fontWeight: "500", color: "#283b75" }}>{product.displayName}</span>
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
}
