import "./payment.css";
import React, { useEffect } from "react";
import { List, Icon, Row, Col, f7, Button } from "framework7-react";

import useGooglePayActiveStatus from "../../hooks/useGooglePayActiveStatus.hook";
import useUpi from "./useUpi.hook";
import { GPAY } from "../../utilities/constants";
import useUpiApps from "../../hooks/useUpiApps";
import { event } from "../../utilities/subscriber.events";
import UpiPaymentVerification from "../UpiPaymentVerification";

const UpiMethod = (props) => {
  const { onChange, onUpiUpdate, vpaAddress, showOptions, suggestions, upiInputRef, selectedUpiAPP, upiResMsg, upiIconsMap, onSelectUPIApp } = useUpi(props);
  const { isGooglePayActive } = useGooglePayActiveStatus();
  const { supportedApps, verifying, payNow, cancelPayment, timerCount, showTimer } = useUpiApps(props.f7router);
  const isIOS = f7.device.ios || f7.device.macos;
  const isAndroid = f7.device.android || f7.device.androidChrome;

  useEffect(() => {
    event.on("openUpiApps", (evetObj) => {
      const { upiAppName, finalPayLoad, storeId, transactionId } = evetObj;
      payNow(upiAppName, finalPayLoad, storeId, transactionId);
    });
    return () => {
      event.unload("openUpiApps");
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (selectedApp !== null && !verifying) {
  //     onUpiUpdate(upiIconsMap[selectedApp].upiExt, upiIconsMap[selectedApp].name);
  //   }
  //   // eslint-disable-next-line
  // }, [selectedApp]);

  return (
    <>
      <div className="other-upi-container">
        <List className="no-padding-input-list" noHairlines>
          <Row style={{ padding: "10px 0" }}>
            {/* TODO: remove the condition check once the UPI intent will works fine on prod  */}
            {/* {upiIntentEnabled && supportedApps && supportedApps.length > 0 ? (
              <> */}
            {supportedApps.map((app) => (
              <Col width={25} style={{ textAlign: "center" }} key={app} onClick={() => onSelectUPIApp(upiIconsMap[app].upiExt, app, true)}>
                <Icon size={40}>
                  <img slot="media" src={upiIconsMap[app].icon} width="39" alt={app} style={{ verticalAlign: "middle" }} />
                </Icon>
                <p className="gopay__upi_text">{app === "any" ? "others" : app}</p>
              </Col>
            ))}
            {isIOS ? (
              <Col width={25} style={{ textAlign: "center" }} onClick={() => onSelectUPIApp(upiIconsMap["any"].upiExt, "any", true)}>
                <Icon size={40}>
                  <img slot="media" src={upiIconsMap["any"].icon} width="39" alt={"Any"} style={{ verticalAlign: "middle" }} />
                </Icon>
                <p className="gopay__upi_text">{"others"}</p>
              </Col>
            ) : null}

            {isAndroid ? (
              <Col width={100} style={{ paddingTop: 15 }}>
                <Button style={{ textTransform: "none", maxWidth: "max-content" }} onClick={() => onUpiUpdate("", "Other UPI")}>
                  Try with UPI ID
                </Button>
              </Col>
            ) : null}
            {/* </>
            ) : (
              popularUPIs.map((upi) => (
                <Col width={25} style={{ textAlign: "center" }} key={upi.name} onClick={() => onUpiUpdate(upi.upiExt, upi.name)}>
                  {upi.icon ? (
                    <Icon size={40}>
                      {selectedUpiAPP === upi.name && <Icon size={20} icon="arz__selected_lender arz__selected_upi" f7="checkmark_circle_fill" />}
                      <img slot="media" src={upi.icon} width="39" alt={upi.name} style={{ verticalAlign: "middle" }} />
                    </Icon>
                  ) : (
                    <p style={{ color: "#000", fontSize: 14, fontWeight: selectedUpiAPP === upi.name ? 500 : 400 }}>{upi.name}</p>
                  )}
                </Col>
              ))
            )} */}
            <Col width={100} style={{ paddingTop: 10 }}>
              <div className="arz__upi_input_wrapper" hidden={!selectedUpiAPP || (isGooglePayActive && selectedUpiAPP === GPAY)}>
                <input ref={upiInputRef} id="arz__upi_input" className="arz__upi_input" onChange={onChange} placeholder="Enter the UPI ID" value={vpaAddress} spellCheck="false" />
                {showOptions && suggestions.length > 0 && (
                  <div className="arz__upi_options">
                    <ul className="arz__upi_ul">
                      {suggestions.map((upiId) => (
                        <li className={`arz__upi_li`} key={upiId} onClick={() => onUpiUpdate(upiId, "Other UPI")}>
                          {upiId}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
            {upiResMsg && (
              <Col>
                <div className="wrong arz__upi_msg">{upiResMsg}</div>
              </Col>
            )}
          </Row>
        </List>
      </div>
      <UpiPaymentVerification open={verifying} onCancel={cancelPayment} showTimer={showTimer} timerCount={timerCount} />
    </>
  );
};

export default UpiMethod;
