import { Row, Col } from "framework7-react";
const PaymentPageBanner = ({ productName, categoryName, fwdRef, onImageLoad }) => {
  return (
    <Row ref={fwdRef} style={{ paddingTop: 5 }}>
      {categoryName !== "NO_CATEGORY" && (
        <Col>
          <div className="arz__swiper_product-w">
            <span className="arz__swiper_category-b">{categoryName}</span>
            <span className="arz__swiper_product_name">{productName}</span>
          </div>
        </Col>
      )}
    </Row>
  );
};
export default PaymentPageBanner;
