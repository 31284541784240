import { Page, PageContent } from "framework7-react";
import "./b2btandc.css";

const TNC = () => (
  <Page name="login" isLoading={false} className="terms-container">
    <PageContent style={{ backgroundColor: "white" }} className="terms-content">
      <div className="logo-container">
        <h2 className="gopay-title">goPay</h2>
        <p className="gopay-title">A revolutionary digital payment platform</p>
      </div>
      <div className="tnc-content">
        <h3 className="text-center">Shipping, Return and Refund Policy</h3>

        <p>
          Please read the processes and terms mentioned below for better understanding of how the platform works. You can get started and use the service once you accept the terms
          & conditions and process compliance -
        </p>

        <h4>Pricing</h4>
        <p>Every Product Distributor list, with price and quantity, goes live on the Orbit retailer App with price to be decided by distributor.</p>
        <h4>Pick up & Delivery </h4>
        <p>Delivery to be completed in a maximum of 3 days.</p>

        <h4>Cancellation</h4>
        <p>
          In the event of any order getting canceled after the product is picked up will be returned back in the same condition it was picked up and it needs to be accepted by the
          distributor within agreed time between distributor and retailer.
        </p>
        <h4>Returns and Refund</h4>
        <p>
          If the payment is completed for the order and is requested for the return, it takes 5-7 days for the payment to be refunded to the retailer against that order, from the
          day the receipt of returns is requested either on distributor dashboard or any other means of communication as agreed by distributor and retailer.{" "}
        </p>
        <p>Distributor can accept or reject the claim for return based on the terms of the Agreement, agreed between Distributor and retailer at time of onboarding retailer.</p>
        <p>Operations/Logistics or any other costs involved in product pick up and return to Origin ( distributor) to be taken care of as agreed by retailer and distributor. </p>
        <h4>QC Norms to be checked: </h4>
        <p>As agreed by distributor with retailer on agreement.</p>
        <p>
          Quality control to be performed by distributor and will follow norms as per industry standards. Any deviation in this accord may be referred to our grievance officer.
        </p>
        <p>
          Please write to <a href="mailto:care@orbitplace.com">care@orbitplace.com</a> for all relevant information/ queries.
        </p>

        <h4>Grievance officer</h4>
        <p>Name : Sameer Khan</p>
        <p>Phone number : 7999106229</p>
        <p>Address: Sharkfin Tech Pvt Ltd, 1668A RJP Towers, HSR Layout Sector 2, Bengaluru - 560102 Karnataka.</p>
      </div>
    </PageContent>
  </Page>
);

export default TNC;
