import React from "react";
import { Icon } from "framework7-react";
import BankList from "./BankList";
import "./payment.css";
import useNetBanking from "./useNetBanking.hook";

const NetBanking = ({ updatePaymentPayload, type }) => {
  const { bankList, open, setOpen, netBankingData, handleInputChange, popularBankList, handlePopularBankListChange } = useNetBanking(updatePaymentPayload, type);
  return (
    <div className="netbanking-container">
      <p>Popular Banks</p>
      <div className="row popular-banks">
        {popularBankList &&
          popularBankList.map((bank) => {
            return (
              <div key={bank.bankCode}>
                <div className={`bank-container ${bank.active && "active_bank"}`} onClick={() => handlePopularBankListChange(bank.bankCode, bank.name)}>
                  <img src={bank.logo} alt="banks" />
                </div>
                <p className="text-center" style={{ fontSize: "12px" }}>
                  {bank.label}
                </p>
              </div>
            );
          })}
      </div>

      <div className="bank-data" onClick={() => setOpen(true)}>
        <div className="title">Select Bank</div>
        <div className="name">{netBankingData.bankName ? netBankingData.bankName : <Icon size="20" f7="chevron_right" />}</div>
      </div>
      <BankList open={open} setOpen={setOpen} banks={bankList} onChange={handleInputChange} selectedBank={netBankingData.bank} />
    </div>
  );
};

export default NetBanking;
