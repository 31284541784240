import React, { useRef } from "react";
import PageHeader from "../../components/LenderOffers/PageHeader";
import { Button, PageContent, f7 } from "framework7-react";
import Page from "../../container/PageWithBg";
import "./kbStyle.css";
import { usePageReady } from "../../hooks/usePageReady.hook";
import useKreditBee from "./useKreditbee";

const KreditBee = ({ f7router, newStateLink }) => {
  const allowedAccess = "microphone; camera; geolocation;";
  const { isPageReady } = usePageReady(1000);
  const iframeRef = useRef(null);
  // const logoUrl = "https://gopay-prod-static.gostor.com/icons/banks/kreditbee";
  const KBPageUrl = newStateLink ? newStateLink : "https://pwa-web1.kreditbee.in/login";
  const { processPayment } = useKreditBee({ f7router });

  const onGoBack = () => {
    const { storeId, transactionId } = f7router.currentRoute.params;
    f7.dialog.confirm("Are you sure want to go back? your onboarding still in progress", "Alert", () => {
      f7router.navigate(`/${storeId}/pay/${transactionId}`);
      localStorage.removeItem("storeId");
      localStorage.removeItem("transactionId");
    });
  };
  const completedOnboarding = () => {
    f7.dialog.confirm("Is you onboarding is completed?", "Onboarding", () => {
      processPayment("CARDLESS_EMI");
    });
  };

  return (
    <Page isLoading={!isPageReady}>
      <div>
        <PageHeader showBack={true} onBackPress={onGoBack} />
        <PageContent className="gopay__kb_pg_content">
          <iframe loading="eager" id="kb__iframe" src={KBPageUrl} ref={iframeRef} title="KB Onboarding" className="gopay__kb_iframe" allow={allowedAccess} />
          <Button className="no-transform" onClick={completedOnboarding}>
            Completed Onboarding?
          </Button>
        </PageContent>
      </div>
    </Page>
  );
};

export default KreditBee;
