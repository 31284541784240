import { Button, Popup } from "framework7-react";
import React from "react";
import OtpInput from "../OtpInput";
import PageHeader from "./PageHeader";

const OTPVerification = ({ open, onDismiss = () => {}, value, setValue = () => {}, onSumbit = () => {}, onResend = () => {}, otpErr = null, logoUrl = null }) => {
  return (
    <Popup className="demo-popup" opened={open}>
      {/*onPopupClosed={onDismiss}*/}
      <PageHeader showBack={true} onBackPress={onDismiss} />
      <div className="gopay_otp_screen_container">
        <div className="gopay__otp_wrapper card">
          <h2 className="mt-10">Enter OTP</h2>
          {otpErr ? <div className="gopay__color_red mb-5">{otpErr}</div> : null}
          <OtpInput value={value} onChange={setValue} numInputs={6} isInputNum={true} isInputSecure={true} containerStyle={`space-around mb-10 ${otpErr ? "error" : ""}`} />
          <div className="flex items-center justify-center">
            <p>Not received OTP?</p>
            <Button className="gopay__color_green no-transform" onClick={onResend}>
              Resend it
            </Button>
          </div>
          <Button large raised fill className="no-transform gopay__bg_green" onClick={onSumbit}>
            Verify OTP
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default OTPVerification;
