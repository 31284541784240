import { Button, Row, Col, PageContent, Sheet, Icon } from "framework7-react";
import React from "react";
import PaymentPageBanner from "./paymentPageBanner";
import "./order_summary.css";

const ProductListing = ({ theBlock, products, onImageLoad, f7router }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Row style={{ alignItems: "center", paddingTop: 5 }}>
        <Col width={70}>
          {products && products?.length > 0 && (
            <PaymentPageBanner
              fwdRef={theBlock}
              onImageLoad={onImageLoad}
              f7router={f7router}
              categoryName={products[0].categoryName}
              productName={products[0].displayName}
              modelName={products[0].modelName}
            />
          )}
        </Col>
        <Col width={30}>{products && products?.length > 1 && <Button onClick={() => setOpen(true)}>More</Button>}</Col>
      </Row>

      {/* To Show All Products */}
      <Sheet
        opened={open}
        style={{
          zIndex: 11000000,
          height: "100%",
          paddingTop: "40vh",
          "--f7-sheet-bg-color": "rgba(0, 0, 0, 0.3)",
        }}
      >
        <PageContent className="arz__products_list_content">
          <Row className="arz__products_header">
            <h3>Products</h3>
            <div onClick={() => setOpen(false)}>
              <Icon size="25" f7="multiply"></Icon>
            </div>
          </Row>
          <div className="arz__products_list">
            {products.map((product) => (
              <div key={product.serialNumber} className="arz__products_list_item">
                <div className="arz__products_icon">
                  <img src={product.categoryIcon} alt="category" />
                </div>
                <div className="arz__swiper_product-w">
                  {/* <div className="arz__swiper_category-b">{product.categoryName}</div> */}
                  <div className="arz__swiper_product_name">{product.brand}</div>
                  <div className="arz__swiper_product_name" style={{ fontSize: 13 }}>
                    {product.modelName}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </PageContent>
      </Sheet>
    </>
  );
};

export default ProductListing;
