import { useEffect } from "react";
import { INSTACRED_PUBLIC_KEY, INSTACRED_SCRIPT } from "../constants/values";

const useLoadInstacred = () => {
  function loadScript() {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.async = true;
      script.src = INSTACRED_SCRIPT;
      script.type = "text/javascript";
      script.onload = () => {
        window.onICMerchantSDKReady = function () {
          window.ICMerchantSDK.init({
            merchantKey: INSTACRED_PUBLIC_KEY,
            onSDKInit: function () {
              console.log("Script loaded successfully.....");
              resolve(true);
              //This callback will be executed once the SDK initialization is complete
            },
          });
        };
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  useEffect(() => {
    const loadInstaCred = async () => {
      const res = await loadScript();
      if (!res) {
        console.log("InstaCred is not loaded");
      }
    };
    loadInstaCred();
  }, []);
};

export default useLoadInstacred;
