import { useEffect, useState } from "react";
import { fetchOfferDetailsApi } from "../../../../services/apiAbstractions.service";

const useCurrentOffer = (handleOfferSelect, selectedOffer) => {
  const [offerDetailsLoading, setOfferDetailsLoading] = useState(null);
  const [accordionActive, setAccordionActive] = useState(null);
  const [activeOfferData, setActiveOfferData] = useState(null);
  const [activeOfferId, setActiveOfferId] = useState(null);

  const offerDetails = async (offerId, accordionActive) => {
    if (!accordionActive) {
      const resp = await fetchOfferDetailsApi(offerId);
      setAccordionActive(true);
      // setActiveOfferId(resp.categoryOffers[0].offerId);
      setOfferDetailsLoading(false);
      setActiveOfferData({
        offerDetails: resp?.offerDetails,
        title: resp?.categoryOffers[0]?.offerTitle,
        description: resp?.categoryOffers[0]?.description,
      });
    } else {
      setAccordionActive(null);
    }
  };

  const offerChange = (offer) => {
    if (!activeOfferId) setActiveOfferId(null);
    else if (activeOfferId === offer.offerId) {
      setActiveOfferId(null);
    } else {
      setActiveOfferId(offer.offerId);
    }
    handleOfferSelect(offer);
  };

  useEffect(() => {
    if (selectedOffer === "") {
      setActiveOfferId(null);
    }
  }, [selectedOffer]);

  const deactivateAccordion = () => {
    setAccordionActive(false);
  };

  return { offerDetails, offerDetailsLoading, accordionActive, activeOfferData, activeOfferId, offerChange, deactivateAccordion };
};

export default useCurrentOffer;
