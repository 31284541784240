import React, { useEffect, useState } from "react";
import { Button, Icon } from "framework7-react";
import success from "../../assets/payment-success.png";
import "./payment_success.css";
import paymentMethods from "../../screens/orderSummary/paymentMethods";
import copyIcon from "../../assets/copy_black_24dp.svg";
import AppliedOffers from "./AppliedOffers";

export default function PaymentSuccess({ route, data, handleOnCopyClick }) {
  const { paidThrough = "" } = data;
  const [hideProductDetails, setHideProductDetails] = useState(false);
  const paymentSuccessMessage = paymentMethods[paidThrough]?.paymentSuccessMessage ?? "";

  useEffect(() => {
    if (data.orderData[0].categoryName === "NO_CATEGORY") {
      setHideProductDetails(true);
    }
  }, [data]);

  return (
    <div className="page pg-bgcolor">
      <div className="success_card">
        <div className="card-header">
          <img className="card-img" alt="party" src={success} />
        </div>
        <div className="card_content_success">
          <h3 className="payment_name_success">Congratulations</h3>
          <p className="payment_desc">{paymentSuccessMessage}</p>
          <p className="payment_desc">
            {" "}
            An SMS has been sent to your mobile number - <b>{data.paymentData.mobileNumber}</b>
          </p>
          <p className="transaction_id_success">
            Your Payment Id is &nbsp;<b style={{ backgroundColor: "#e1e4e1", padding: "2%", borderRadius: "5px" }}>#{data.orderId}</b>{" "}
            <img src={copyIcon} width="17" height="17" style={{ verticalAlign: "bottom" }} alt="copy" onClick={handleOnCopyClick} />
          </p>
          {!hideProductDetails && (
            <div className="products_container">
              <div className="products_header">
                <p>Your Products</p>
              </div>
              <div className="product_divider"></div>
              {data.productData.map((product, i) => {
                return (
                  <div key={i} className="payment_item">
                    <div className="product_image_store">
                      <div className="product_image_container">
                        <img src={product.categoryIcon} alt="product_category" />
                      </div>
                    </div>
                    <div className="item_name">
                      <p>
                        <span style={{ fontWeight: "500" }}>{product.displayName}</span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <AppliedOffers data={data} />
          <div className="btm_drop"></div>
        </div>

        <div className="home-footer">
          <Button
            fill
            color="green"
            onClick={() => {
              window.location = `/`;
            }}
          >
            {" "}
            <Icon f7="house" size="16px" color="white"></Icon> <span>Home</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
