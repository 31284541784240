import "./emi_details.css";
import React, { useRef } from "react";
import { Link, Block, Range, Col, Row, BlockTitle, Radio, Button } from "framework7-react";
import Page from "../../container/PageWithBg";
import useEmiDetails from "./useEmiDetails.hook";

import BankIcon from "../../assets/icons-bank@3x.png";
import useEMITenureSelect from "./useEMITenureSelect.hook";
import BankList from "../PaymentInputMethods/BankList";

const EMIDetails = ({ offer = {}, smartSelect, totalOrderAmount = null, onSelect, popupActivated, disabled, providerName = "Bank", hideRadioButton = false }) => {
  const titleRef = useRef(null);
  const bankSelectorRef = useRef(null);
  const { formatScaleLabel, bankList, bankEMIList, initialLoanAmount, handleRangeChange, open, setOpen, selectedBank, interestRates, handleBankSelect } = useEmiDetails(
    offer,
    totalOrderAmount,
    popupActivated,
    titleRef,
    bankSelectorRef
  );
  const { selectedTenure, changeSelectedOption } = useEMITenureSelect(onSelect, bankEMIList);

  return (
    <Page style={{ height: "auto", backgroundColor: "white", backgroundImage: "none" }}>
      {!smartSelect && (
        <Block ref={titleRef}>
          <Link popupClose className="emi_back_button" icon="icon-back">
            &nbsp; EMI Interest Rates
          </Link>
        </Block>
      )}
      <Block className={!smartSelect ? "emi-page" : "emi-page top-space"} style={{ padding: "11px", marginBottom: 0, border: "1px solid grey" }} ref={bankSelectorRef}>
        <Row>
          <Col width="20">
            <div
              style={{
                padding: 6,
                borderRadius: 10,
                width: 25 + 7,
                textAlign: "center",
              }}
            >
              <img src={BankIcon} style={{ width: 20, height: 20, marginTop: 3 }} alt="bank" />
            </div>
          </Col>
          <Col width="80" className="input input-dropdown select_bank">
            <div onClick={() => setOpen(true)}>
              <span style={{ color: "#222222c4" }}>{providerName}</span>
              <div style={{ color: "#222222c4", fontWeight: "500" }}>{selectedBank}</div>
            </div>
            <BankList open={open} setOpen={setOpen} banks={bankList} onChange={(value) => handleBankSelect(value, () => changeSelectedOption(""))} selectedBank={selectedBank} />
          </Col>
        </Row>
      </Block>
      {totalOrderAmount ? <BlockTitle style={{ color: "grey", fontWeight: "500" }}>Principal Amount {totalOrderAmount} INR</BlockTitle> : ""}
      <Block
        style={{
          marginTop: 10,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          overflow: "auto",
        }}
      >
        <Block className=" emi-container" style={{ marginTop: 0 }}>
          <div className="data-table  table-emilist">
            {bankEMIList && (
              <table>
                <thead>
                  <tr>
                    {hideRadioButton ? null : smartSelect && <th></th>}
                    <th className="label-cell">Months</th>
                    <th className="label-cell numeric-cell">Interest Rate</th>
                    <th className="label-cell numeric-cell">EMI</th>
                    <th className="label-cell">Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {interestRates.map(({ emi, amount, monthDuration, rate }) => {
                    return (
                      <tr key={monthDuration}>
                        {hideRadioButton
                          ? null
                          : smartSelect && (
                              <td className="label-cell">
                                <Radio name="tenure" checked={selectedTenure === monthDuration} onChange={(e) => changeSelectedOption(monthDuration, selectedBank)} />
                              </td>
                            )}
                        <td className="label-cell">{monthDuration}</td>
                        <td className="label-cell">{rate}%</td>
                        <td className="label-cell numeric-cell">
                          <b style={{ fontWeight: 500 }}>₹{emi}</b>
                        </td>
                        <td className="label-cell">₹{amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          {!smartSelect && (
            <div className="calculator-card">
              <Row className="calculator-text-container">
                <Col width="50">Loan Amount</Col>
                <Col width="50" className="loan_amount">
                  <div className="emi-input">
                    <label>₹ </label>
                    <input
                      id="loan-amount"
                      type="number"
                      style={{
                        display: "inline-block",
                        maxWidth: "90%",
                        marginLeft: "5%",
                      }}
                      onChange={(e) => handleRangeChange(parseInt(e.target.value))}
                      value={initialLoanAmount}
                      disabled={disabled ? true : false}
                    />
                  </div>
                </Col>
              </Row>
              <div className="range-holder">
                <Range
                  min={0}
                  max={150000}
                  step={1}
                  value={initialLoanAmount}
                  formatScaleLabel={formatScaleLabel}
                  scale={disabled ? false : true}
                  scaleSteps={10}
                  scaleSubSteps={4}
                  color="white"
                  onRangeChange={(e) => handleRangeChange(e)}
                  disabled={disabled ? true : false}
                />
              </div>
            </div>
          )}

          {smartSelect && (
            <Block>
              <Button fill color="green" popupClose>
                Done
              </Button>
              {providerName !== "Partner" && (
                <Button color="black" style={{ fontSize: 11 }} popupClose>
                  cancel
                </Button>
              )}
            </Block>
          )}
        </Block>
      </Block>

      {!smartSelect && <div style={{ width: "100%", height: "100px" }}></div>}
    </Page>
  );
};

export default React.memo(EMIDetails);
