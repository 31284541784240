import NotFound404 from "./screens/404";
import CategoryView from "./screens/CategoryView";
import HomePage from "./screens/HomePage";
import OfferDetails from "./screens/OfferDetails";
import OrderSummary from "./screens/orderSummary";
import OrderStatus from "./screens/OrderStatus";
import OtpView from "./screens/Otp";
import TermsAndCondition from "./screens/TermsAndCondition";
import GopayHomeProduct from "./screens/GopayHomeProduct";
import ProtiumOffers from "./screens/ProtiumOffers";
import LenderOffers from "./screens/LenderOffers";
import B2bTandC from "./screens/B2bTandC";
import KreditBee from "./screens/KreditBee";
import RedirectToKB from "./screens/KreditBee/RedirectToKB";

// http://go-pay.in/9641556687/9f38c20fb9780ad6343b69081b97ccd6.html
const routes = [
  {
    path: "/signin",
    component: RedirectToKB,
    name: "redirect to KB",
  },
  {
    path: "/:storeId",
    component: HomePage,
    name: "home",
    keepAlive: true,
    // showPayBtn: true,
    routes: [
      // {
      // 	path: "/9f38c20fb9780ad6343b69081b97ccd6.html",
      // 	name: "PT",
      // 	// exact: true,
      // 	component: HomePage,
      // },
      {
        path: "/offer/category/:category",
        component: CategoryView,
        name: "category-offer",
        showPayBtn: true,
      },
      {
        path: "/pay/:transactionId",
        component: OrderSummary,
        name: "payment",
        showFab: false, // this is a custom property to be used in one of the url based components. Default showFab value is set to be true
      },
      {
        path: "/pay/:transactionId/otp",
        component: OtpView,
        name: "send-otp",
        showFab: false,
      },
      {
        path: "/order-status/:transactionId",
        component: OrderStatus,
        name: "category-offer",
        showFab: false,
      },
      {
        path: "/emi_offers/:paymentId",
        component: ProtiumOffers,
        name: "offer-list",
        showFab: false,
      },
      {
        path: "/lender-onboarding/:transactionId",
        component: KreditBee,
        name: "lender-onboarding",
        showFab: false,
      },
      {
        path: "/lender-offers/:orderId",
        component: LenderOffers,
        name: "lender-offers-page",
        showFab: false,
      },
    ],
  },
  {
    path: "/offer/:storeId/:category/:offerId",
    component: OfferDetails,
    name: "offer-details-view",
    showPayBtn: true,
    options: { ignoreCache: true },
  },
  {
    path: "/",
    component: GopayHomeProduct,
    name: "gopay-home",
  },
  {
    path: "/home/product",
    component: GopayHomeProduct,
    name: "gopay-home",
  },
  {
    path: "/gopay/terms-conditions",
    component: TermsAndCondition,
    name: "tnc",
  },
  {
    path: "/gopay/tnc/b2b",
    component: B2bTandC,
    name: "b2bTandC",
  },
  {
    path: "(.*)",
    component: NotFound404,
  },
];

export default routes;
