import "./order_summary.css";
import React, { useEffect, useRef } from "react";
import { PageContent, Row, Col, Sheet, Button, List, ListItem, AccordionContent, Block, Popup } from "framework7-react";
import { usePaymentMethod } from "./paymentHooks/usePaymentMethod.hooks";
import paymentMethods from "./paymentMethods";
import NotificationPopUp from "../../components/NotificationPopUp";
import NotificationPopUpV2 from "../../components/NotificationPopUp/NotificationPopUpV2";
import ProtiumVerification from "../../components/ProtiumVerification";
import OfferList from "./OfferList/OfferList";
import useEMIPopup from "./paymentHooks/useEMIPopup";
import EMIDetails from "../../components/EMIDetails";
import { CARDLESS_EMI, UPI } from "../../utilities/constants";
import Verifying from "../../components/PaymentInputMethods/Verifying";

const transformArrayPosition = (fistMethod, nextMethod) => {
  const paymentMethod1 = paymentMethods[fistMethod];
  const paymentMethod2 = paymentMethods[nextMethod];
  return paymentMethod1.order - paymentMethod2.order;
};

const Accordion = ({ open = false, paymentMethod, isCardOpen, children }) => {
  if (!open) return null;
  return (
    <AccordionContent style={{ overflow: isCardOpen && paymentMethod === UPI ? "visible" : "hidden" }} t>
      <Block>{children}</Block>
    </AccordionContent>
  );
};

const SelectPaymentMethod2 = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {
    paymentSheet,
    sheetStyle,
    totalOrderAmount,
    paymentData,
    orderId,
    paymentId,
    offerData,
    selectedOffer,
    f7router,
    handleOfferSelect,
    setSelectedOffer,
    hideCategory,
    notifyCardLessEMI,
  } = props;
  const popupRef = useRef(null);
  const { activeEMIPOPup, closePOPup, positionTop, handleSetTop } = useEMIPopup();
  const {
    loading,
    protiumPaymentTypes,
    razorPayPaymentTypes,
    updatePaymentPayload,
    processPayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    showNotice,
    setShowNotice,
    handlePaymentModeSelection,
    handleNoticeProceed,
    errorMsg,
    handleCallBack,
    offerAmount,
    protiumNotificationStatus,
    handleCloseProtiumNotication,
    handleProtiumSuccess,
    minimumAmountSheet,
    handleMinAmountCloseSheet,
    minOfferPrice,
    mop,
    isCardOpen,
    setIcardOpen,
    disabledPayBtn,
    instrumentData,
    rupifiType, rupifiEligibleValue,
    isVerifying
  } = usePaymentMethod(paymentData, orderId, paymentId, totalOrderAmount, selectedOffer, f7router, paymentSheet, offerData, handleOfferSelect);

  useEffect(() => {
    // As it should run after initialisation of razorpay to window object
    const timeoutId = setTimeout(() => {
      if (instrumentData?.paidThrough) {
        setSelectedPaymentMethod(instrumentData?.paidThrough);
      }
    }, 100);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentData]);

  return (
    <Sheet opened={paymentSheet} style={sheetStyle} backdrop={false}>
      {paymentSheet && (
        <PageContent style={{ height: !hideCategory ? "100%" : sheetStyle.height - 150 || 100 - 90, paddingTop: "0px" }}>
          <OfferList offerData={offerData} totalOrderAmount={totalOrderAmount} handleOfferSelect={handleOfferSelect} selectedOffer={selectedOffer} />
          <List id="payment-selector" accordionList noHairlines={true} noHairlinesBetween>
            <ListItem
              title={
                <div className="offer-label">
                  <img src="/images/payment_icons/payment.svg" className="percentage-icon" alt="percentage" />
                  <p className="avaliable_text" style={{ color: "black" }}>
                    Payment Option
                  </p>
                </div>
              }
              className="payment-select-options-title"
            />
            {protiumPaymentTypes.sort(transformArrayPosition).map((paymentType, i) => {
              const Method = paymentMethods[paymentType];

              if (!Method) return <React.Fragment key={i}>{null}</React.Fragment>;

              const paymentTitle = (
                <div>
                  <span className="payment_icon_container">
                    <img src={Method.Icon} alt="title_icon" className="payment_icon" />
                  </span>
                  <span>Cardless EMI</span>
                </div>
              );
              return (
                <ListItem
                  checked={selectedPaymentMethod === paymentType}
                  radioIcon="end"
                  radio
                  noChevron={true}
                  accordionItem
                  accordionItemOpened={selectedPaymentMethod === paymentType}
                  title={paymentTitle}
                  link="#"
                  key={paymentType}
                  onClick={() => handlePaymentModeSelection(paymentType)}
                >
                  <Accordion open={selectedPaymentMethod === paymentType}>
                    <Method.Component
                      selected={selectedPaymentMethod}
                      protium={true}
                      totalOrderAmount={totalOrderAmount}
                      type={paymentType}
                      updatePaymentPayload={updatePaymentPayload}
                    />
                  </Accordion>
                </ListItem>
              );
            })}
            {razorPayPaymentTypes.sort(transformArrayPosition).map((paymentType, i) => {
              const Method = paymentMethods[paymentType];
              if (!Method) return <React.Fragment key={i}>{null}</React.Fragment>;
              // Hide the CardLess Option if there is no active lenders
              if (Method.text === "Cardless EMI" && paymentData?.activeLenders.length < 1) return <React.Fragment key={i}>{null}</React.Fragment>;
              const titleWithIcon = (
                <div>
                  <span className="payment_icon_container">
                    <img src={Method.Icon} alt="title_icon" className="payment_icon" />
                  </span>
                  <span>{Method.text}</span>
                </div>
              );

              return (
                <ListItem
                  checked={selectedPaymentMethod === paymentType}
                  radioIcon="end"
                  radio
                  noChevron={true}
                  accordionItem
                  accordionItemOpened={selectedPaymentMethod === paymentType}
                  title={titleWithIcon}
                  link="#"
                  key={paymentType}
                  onClick={() => handlePaymentModeSelection(paymentType, notifyCardLessEMI)}
                  onAccordionBeforeClose={() => setIcardOpen(false)}
                  onAccordionBeforeOpen={() => setIcardOpen(true)}
                  className={paymentType === CARDLESS_EMI ? "arz_cardless_emi_section" : ""}
                >
                  <Accordion open={selectedPaymentMethod === paymentType} paymentMethod={paymentType} isCardOpen={isCardOpen}>
                    <Method.Component
                      errorMsg={errorMsg?.indexOf(paymentType) > -1 ? errorMsg : false}
                      selected={selectedPaymentMethod}
                      totalOrderAmount={totalOrderAmount}
                      type={paymentType}
                      updatePaymentPayload={updatePaymentPayload}
                      paymentData={paymentData}
                      orderId={orderId} 
                      f7router={f7router}
                    />
                  </Accordion>
                </ListItem>
              );
            })}
            {rupifiEligibleValue > 0
              ? rupifiType.map((paymentType, i) => {
                  const Method = paymentMethods[paymentType];

                  if (!Method) return <React.Fragment key={i}>{null}</React.Fragment>;

                  const paymentTitle = (
                    <div>
                      <span className="payment_icon_container">
                        <img src={Method.Icon} alt="title_icon" className="payment_icon" />
                      </span>
                      <span>{Method.text}</span>
                    </div>
                  );
                  return (
                    <ListItem
                      checked={selectedPaymentMethod === paymentType}
                      radioIcon="end"
                      radio
                      noChevron={true}
                      accordionItem
                      accordionItemOpened={selectedPaymentMethod === paymentType}
                      title={paymentTitle}
                      link="#"
                      key={paymentType}
                      onClick={() => handlePaymentModeSelection(paymentType)}
                    >
                      <Accordion open={selectedPaymentMethod === paymentType}>
                        <Method.Component
                          rupifiValue={rupifiEligibleValue}
                          selected={selectedPaymentMethod}
                          totalOrderAmount={totalOrderAmount}
                          type={paymentType}
                          updatePaymentPayload={updatePaymentPayload}
                        />
                      </Accordion>
                    </ListItem>
                  );
                })
              : null}
          </List>
        </PageContent>
      )}
      <Row style={{ backgroundColor: "#ffffff", padding: 20, color: "black" }}>
        <Col width="60">
          <span style={{ display: "block" }}>
            <span>Total payable</span>{" "}
          </span>
          <p style={{ fontSize: 20, margin: 0 }}>
            <b>₹{offerAmount > 0 ? totalOrderAmount - offerAmount : totalOrderAmount} </b>
            {offerAmount > 0 && (
              <span className="offer-amount-content" style={{ margin: 0 }}>
                ₹{totalOrderAmount}
              </span>
            )}
          </p>
        </Col>
        <Col width="40">
          <Button disabled={loading || !selectedPaymentMethod || disabledPayBtn} onClick={() => processPayment(handleCallBack)} color="green" fill large>
            {loading ? "Processing..." : selectedPaymentMethod === "CARDLESS_EMI" ? "Apply" : "Pay"}
          </Button>
        </Col>
      </Row>
      <NotificationPopUp
        sheetStatus={!!showNotice}
        onClose={() => setShowNotice(null)}
        image="/images/offer_no.png"
        details="The offer is not applicable for this payment mode. Do you want to continue?"
        cancelButtonText="Cancel"
        onSubmit={() => handleNoticeProceed(setSelectedOffer)}
      />
      <NotificationPopUpV2 opened={protiumNotificationStatus} onClose={handleCloseProtiumNotication}>
        <ProtiumVerification
          handleClose={handleCloseProtiumNotication}
          orderId={paymentId}
          onSuccess={handleProtiumSuccess}
          mobileNumber={props.paymentData.mobileNumber}
          isSheetOpened={protiumNotificationStatus}
        />
      </NotificationPopUpV2>
      <NotificationPopUp
        sheetStatus={minimumAmountSheet}
        onClose={handleMinAmountCloseSheet}
        image="/images/offer_no.png"
        details={`Minimum order value should be ₹${minOfferPrice}`}
        cancelButtonText="Close"
        hideSubmit={true}
      />
      <Popup
        onPopupOpen={() => handleSetTop(popupRef)}
        backdrop={true}
        ref={popupRef}
        className="emi-popup"
        style={{ top: positionTop }}
        opened={activeEMIPOPup}
        onPopupClosed={closePOPup}
      >
        <EMIDetails
          providerName="Partner"
          smartSelect={true}
          hideRadioButton={true}
          disabled={true}
          offer={{ modeOfPayment: mop === "PROTIUM" ? "PROTIUM" : "RAZORPAY", paidThrough: "CARDLESS_EMI" }}
          popupActivated={activeEMIPOPup}
          totalOrderAmount={totalOrderAmount}
        />
      </Popup>
      <Verifying open={isVerifying} />
    </Sheet>
  );
};

export default SelectPaymentMethod2;
