import { Col, Icon, Row } from "framework7-react";
import React from "react";
import formatter from "../../utilities/formatter";

const LenderOfferCard = ({ offer, onSelectOffer = () => {}, opened = false }) => {
  return (
    <div className={`gopay__lender_offer_card card ${opened ? "remove__bottom_radius" : ""}`} onClick={() => onSelectOffer(offer?.scheme_id)}>
      <div className="card-header gopay__lender_tenure_card_header">
        <div className="gopay_tenure_card_title">
          <h3>{offer.tenure}</h3>
          {/* <Icon f7="multiply" className="multiply_icon" size={18} /> */}
          <div className="gopay__lender_tenure">
            <p>{formatter(offer.amount, "currency")}</p>
            <small>/Month</small>
          </div>
        </div>
        {/* <div className="gopay_lender_card_tag">
          No Cost
          <Icon f7="arrow_right_circle_fill" className="multiply_icon" size={28} />
        </div> */}
      </div>
      <div className="card-content">
        <Row>
          <Col>
            <div>
              <p>Pay now</p>
              <h4>
                {formatter(offer.amount, "currency")} + {formatter(offer.fee, "currency")}
              </h4>
            </div>
          </Col>
          <Col>
            <div>
              <p>Next {offer.tenure - 1} Months</p>
              <h4>
                {formatter(offer.amount, "currency")} <small>/month</small>
              </h4>
            </div>
          </Col>
        </Row>
      </div>
      {opened ? null : (
        <div className="gopay__color_green mt-5">
          View Details <Icon f7="chevron_down" size={18} />
        </div>
      )}
    </div>
  );
};

export default LenderOfferCard;
