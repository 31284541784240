import { useState, useEffect } from "react";
const useProtiumVerification = (mobileNumber, isSheetOpened, handleClose) => {
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const [shopseRefId, setShopeseRefId] = useState(null);

  const handleOTPSent = (val) => {
    setDisabledButton(true);
    if (val) {
      setShopeseRefId(val.shopSeRefNo);
      setIsOTPSent(true);
    }
  };

  useEffect(() => {
    if (isSheetOpened && mobileNumber.toString().length === 10) {
      setDisabledButton(false);
    }
  }, [isSheetOpened, mobileNumber]);

  const handleCloseForOTPVerification = () => {
    setIsOTPSent(false);
    handleClose();
  };

  return { handleCloseForOTPVerification, handleOTPSent, shopseRefId, disabledButton, isOTPSent, setIsOTPSent };
};

export default useProtiumVerification;
