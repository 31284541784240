import { INSTACRED_PUBLIC_KEY, INSTACRED_SCRIPT } from "../constants/values";
import Api from "../services/api.service";
import { f7 } from "framework7-react";

const useHandleFlexPayment = (f7router) => {
  const loadICScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.async = true;
      script.src = INSTACRED_SCRIPT;
      script.type = "text/javascript";
      script.onload = () => {
        window.onICMerchantSDKReady = function () {
          window.ICMerchantSDK.init({
            merchantKey: INSTACRED_PUBLIC_KEY,
            onSDKInit: function () {
              console.log("Script loaded successfully.....");
              resolve(true);
              //This callback will be executed once the SDK initialization is complete
            },
          });
        };
      };
      script.onerror = () => {
        reject();
      };
      document.body.appendChild(script);
    });
  };
  const flexPaymentCallback = async (payload) => {
    const { storeId, transactionId } = f7router.currentRoute.params;
    f7.dialog.preloader("Please wait...");
    await Api.flexPaymentCallback(payload);
    f7.dialog.close();
    f7router.navigate(`/${storeId}/order-status/${transactionId}`, { transition: "f7-cover" });
  };
  const handlePaymentCallBack = ({ payload, signature }) => {
    console.log({ payload });
    flexPaymentCallback({ payload, signature });
  };

  const startFlexPayment = async (paymentPayload) => {
    const payloadObj = {
      orderToken: paymentPayload?.paymentData?.orderToken,
      orderAmount: paymentPayload?.paymentData?.orderAmount,
    };
    if (window && window?.ICMerchantSDK && window?.ICMerchantSDK?.startPayment) {
      console.log("Start Flex", payloadObj);
      window?.ICMerchantSDK.startPayment(payloadObj, handlePaymentCallBack);
    } else {
      console.log("inside Else");
      await loadICScript()
        .then(() => {
          console.log('Inside Then');
          window?.ICMerchantSDK.startPayment(payloadObj, handlePaymentCallBack);
        })
        .catch(() => {
          console.log('Inside catch');
          console.error("Error while loading Flex Script");
        });
    }
  };

  return { startFlexPayment };
};
export default useHandleFlexPayment;
