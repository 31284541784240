import { useState } from "react";

const useEMITenureSelect = (onSelect) => {
  const [selectedTenure, setSelectedOption] = useState("");
  const changeSelectedOption = (d, selectedBank) => {
    setSelectedOption(d);
    onSelect && onSelect(d, selectedBank);
  };

  return { selectedTenure, changeSelectedOption };
};

export default useEMITenureSelect;
