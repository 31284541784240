import React, { forwardRef } from "react";
import { Navbar, NavLeft, NavTitle, NavRight, Link, f7 } from "framework7-react";
import logo from "../../assets/logo/arzooo_pro.png";

const theNavBar = (props, ref) => {
  const { showBack = false, showLogo = false, transparent = true, title, onBackPress = () => {}, style = {}, showRightLogo = false, storeName = false } = props;
  const showHideScanButton = () => {
    hideSplash();
    onBackPress();
    props.f7router.back();
    return;
  };
  const hideSplash = () => {
    f7.store.dispatch("setSplashStatus", false);
  };
  return (
    <>
      {/* <Navbar noHairline noShadow transparent /> */}
      <Navbar style={{ ...style, width: "100%" }} noHairline noShadow transparent={transparent} ref={ref}>
        <NavLeft>
          {showBack && <Link onClick={showHideScanButton} className="color-theme-white" icon="icon-back" />}
          {showLogo && <img className="logoPositioning" src={logo} alt="logo" style={{ height: 13 }} />}
          {storeName && <p style={{ fontSize: 17, paddingLeft: 10 }}>{storeName}</p>}
        </NavLeft>
        <NavTitle style={{ opacity: 1, margin: 0, fontSize: 15 }}>{title}</NavTitle>
        <NavRight style={{ color: "#fff" }}>
          {showRightLogo && (
            <span>
              <div style={{ fontSize: 10, paddingRight: 2, alignSelf: "start" }}>Powered by</div>
              <span style={{ paddingRight: 11, display: "flex", alignItems: "start", marginTop: 3 }}>
                <img className="gopayLogo" src={logo} alt="logo" style={{ height: 13 }} />
              </span>
            </span>
          )}
        </NavRight>
      </Navbar>
    </>
  );
};

export default forwardRef(theNavBar);
