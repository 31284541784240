import { Button, Popup } from "framework7-react";
import React from "react";
import PageHeader from "../LenderOffers/PageHeader";
import "../../screens/LenderOffers/style.css";
import PageLoader from "../pageLoader";

const UpiPaymentVerification = ({ open, onCancel = () => {}, timerCount, showTimer = false, onPopupClose = () => {} }) => {
  return (
    <Popup className="demo-popup" opened={open} onPopupClose={onPopupClose}>
      <PageHeader showBack={false} />
      <div className="gopay_otp_screen_container">
        <div className="gopay__otp_wrapper">
          {showTimer && <h3>{timerCount}</h3>}
          <PageLoader noMargin />
          <p className="mb-0">Your payment is being verified</p>
          <h3 className="mb-0 mt-5">Please do not close the browser or refresh</h3>
          <Button className="button mt-10 no-transform" onClick={onCancel}>
            Cancel Payment
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default UpiPaymentVerification;
