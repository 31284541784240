import { useState, useEffect } from "react";
// import config from "../config";

const useGooglePayActiveStatus = () => {
  const [isGooglePayActive, setIsGooglePayActive] = useState(null);
  useEffect(() => {
    const razorPay = new window.Razorpay({ key: process.env.REACT_APP_UPI_VERIFICATION });
    razorPay
      .checkPaymentAdapter("gpay")
      .then(() => {
        setIsGooglePayActive(true);
      })
      .catch(() => {
        setIsGooglePayActive(false);
      });
  }, []);

  return { isGooglePayActive };
};

export default useGooglePayActiveStatus;
