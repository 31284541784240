const EventEmitter = require("events");

class EventClient {
  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  on(eventName, listner) {
    this.eventEmitter.on(eventName, listner);
  }

  removeListener(eventName, listner) {
    this.eventEmitter.removeListener(eventName, listner);
  }

  emit(eventName, data) {
    this.eventEmitter.emit(eventName, data);
    // add other tracking code here
  }
}

const eventClient = new EventClient();
export default eventClient;
