import React, { useState } from "react";
import Api from "../../services/api.service";
import jwt_decode from "jwt-decode";
import { MIN_OFFER_AMOUNT } from "../../constants/values";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import config from "../../config";

const isValidToken = (time) => {
  // return saved time difference in minute
  const timeDifference = time * 1000 - new Date().getTime(); // convert milliseconds to minute
  return timeDifference > 0;
};

const useOrderDetails = (f7router, selectedOffer) => {
  const [sheetOpened, setSheetOpened] = React.useState();
  const [paymentSheet, setPaymentSheet] = React.useState();
  const [orderSummary, setOrderSummary] = React.useState({});
  const [isVerified, setIsVerified] = React.useState(false);
  const [offerAmount, setOfferAmount] = React.useState();
  const [hideCategory, setHideCategory] = React.useState(false);
  const [notifyCardLessEMI, setNotifyCardlessEMI] = React.useState(false);
  const [streamData, setStreamData] = useState(false);

  const toggleOfferSheet = () => {
    setSheetOpened(!sheetOpened);
  };

  const togglePaymentSheet = (fromPayPage) => {
    if (fromPayPage) {
      f7router.refreshPage();
    }
    setPaymentSheet(!paymentSheet);
    setSheetOpened(!sheetOpened);
  };

  const verifyPhoneNumber = (offer) => {
    const { params: { storeId, transactionId } = {} } = f7router.currentRoute;
    setPaymentSheet(false);
    setSheetOpened(false);
    f7router.navigate(`/${storeId}/pay/${transactionId}/otp`, {
      props: { productData: orderSummary.productData, offer },
    });
  };

  React.useEffect(() => {
    if (orderSummary?.orderData?.totalBillingPrice >= parseInt(selectedOffer?.minOfferAmount)) {
      setOfferAmount(selectedOffer.discountAmount);
    } else {
      setOfferAmount(0);
    }
  }, [selectedOffer, orderSummary]);

  React.useEffect(() => {
    if (orderSummary.productData) {
      if (orderSummary.productData[0]) {
        if (orderSummary.productData[0].categoryName === "NO_CATEGORY") {
          setHideCategory(true);
          if (orderSummary.totalOrderAmount <= MIN_OFFER_AMOUNT) {
            setNotifyCardlessEMI(true);
          }
        }
      }
    }
  }, [orderSummary]);

  const fetchData = async (token) => {
    const { params: { transactionId } = {} } = f7router.currentRoute;
    const controller = new AbortController();
    const url = `${config.apiUrl}transactions/v2/payment/stream/${transactionId}`;
    await fetchEventSource(url, {
      method: "GET",
      headers: {
        Accept: "text/event-stream",
        Authorization: `Bearer ${token}`
      },
      signal: controller.signal,
      onopen(res) {
        if (res.ok && res.status === 200) {
          // console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        // console.log('E V E N T  D A T A\n', event.data);
        const parsedData = JSON.parse(event.data);
        // console.log(parsedData);
        setStreamData(parsedData);
        if (parsedData.error) {
          controller.abort();
        }
      },
      onclose() {
        console.log("Connection closed by the server");
      },
      onerror(err) {
        console.log("There was an error from server", err);
      },
    });
  };

  React.useEffect(() => {
    // A functions that make Api.getOrderSummary request to get order summary
    const OrderSummary = async () => {
      const { params: { transactionId } = {} } = f7router.currentRoute;
      const res = await Api.getOrderSummary({}, `${transactionId}`, true);
      setOrderSummary(res.data);
      localStorage.setItem("storeName", res?.data?.paymentData?.storeName || "Arzooo Pro");
      setSheetOpened(true);

      // getting the old AUTH_TOKEN has highly chance of diff transactioid so we are setting the data on each request
      const token = res.data?.token?.accessToken;
      fetchData(token);
      localStorage.setItem("AUTH_TOKEN", token);

      if (token) {
        const decoded = jwt_decode(token);
        if (isValidToken(decoded.exp) && decoded.payment_id === transactionId) {
          setIsVerified(true);
        }
      }
    };
    OrderSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { sheetOpened, orderSummary, paymentSheet, togglePaymentSheet, verifyPhoneNumber, isVerified, toggleOfferSheet, offerAmount, hideCategory, notifyCardLessEMI, streamData };
};

export default useOrderDetails;
