import React from "react";
import { Sheet, Block, Button } from "framework7-react";
import "./notificationpopup.css";

const NotificationPopUp = ({
  sheetStatus,
  onClose = () => {},
  onSubmit = () => {},
  title,
  details = "",
  submitButtonText = "Yes",
  cancelButtonText = "No",
  hideSubmit = false,
  onCancel = () => {},
}) => (
  <Sheet
    id="notification-pop-up"
    opened={sheetStatus}
    onSheetClosed={onClose}
    push
    style={{
      height: "100%",
      "--f7-sheet-bg-color": "rgba(0, 0, 0, 0.3)",
      width: "100%",
    }}
    swipeToClose
    backdrop
  >
    <Block className="pop-up-content" style={{ width: "100%" }}>
      <div style={{ textAlign: "center" }}>
        {/* <img src={image} alt="No offer" /> */}
        <p style={{ fontWeight: "bold", fontSize: 18 }}>{title}</p>
        <p style={{ margin: 30, marginTop: 10 }}>{details}</p>
        <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
          {!hideSubmit && (
            <Button fill large color="green" style={{ width: "40%" }} onClick={onSubmit}>
              {submitButtonText}
            </Button>
          )}
          <Button
            color="green"
            outline
            large
            style={{ width: "40%" }}
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </Block>
  </Sheet>
);

export default NotificationPopUp;
