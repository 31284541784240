import "./otp.css";
import React from "react";
import { Sheet } from "framework7-react";
import Page from "../../container/PageWithBg";
import NavBar from "../../components/NavBar";
import PaymentPageBanner from "../orderSummary/paymentPageBanner";
import useOTPValidation from "./useOTPValidation.hooks";
import SendOtp from "./send-otp";
import VerifyOtp from "./verify-otp";
import { usePageReady } from "../../hooks/usePageReady.hook";

import { NAV_BAR_HEIGHT_OFFSET } from "../../constants/values";
const INNER_HEIGHT = window.innerHeight; // we needed this since for every render after keyboard open and input change the height of the screen is changed

const OtpView = ({ f7router, productData = {}, offer }) => {
  const { isPageReady } = usePageReady(500);
  const { categoryName = "", displayName = "", modelName = "", categoryIcon } = productData;
  const { sent, ...rest } = useOTPValidation(f7router, offer, productData, isPageReady);
  const theBlock = React.useRef(null);
  const sheetStyle = { height: theBlock.current ? INNER_HEIGHT - theBlock.current.el.offsetHeight - NAV_BAR_HEIGHT_OFFSET : "auto" };
  const keyboardActiveJumpFix = { position: "fixed", top: theBlock.current ? theBlock.current.el.offsetHeight + NAV_BAR_HEIGHT_OFFSET : "auto" };

  return (
    <Page isLoading={categoryName.length === 0}>
      <NavBar closeSheetBeforeNav={true} f7router={f7router} showBack={true} title="Payment Details" />
      {categoryName.length > 0 && (
        <>
          <div>
            <PaymentPageBanner fwdRef={theBlock} f7router={f7router} categoryName={categoryName} productName={displayName} modelName={modelName} productImage={categoryIcon} />
          </div>

          <Sheet opened={isPageReady} className="sheet-wrapper" style={{ ...sheetStyle, ...keyboardActiveJumpFix }} backdrop={false}>
            {sent ? <VerifyOtp {...rest} /> : <SendOtp containerHeight={sheetStyle.height} {...rest} />}
          </Sheet>
        </>
      )}
    </Page>
  );
};

export default OtpView;
