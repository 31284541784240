import React from "react";
import "./payment_success.css";
import useAppliedOffers from "./useAppliedOffers";

const AppliedOffers = ({ data }) => {
const {appliedOfferId, totalOrderAmount, appliedOffer} = useAppliedOffers(data)

  return (
    <div className="item_price">
      <p>{`Total Order Amount - ₹ ${appliedOfferId ? totalOrderAmount - appliedOffer?.discountAmount : totalOrderAmount}`}</p>
    </div>
  );
};

export default AppliedOffers;
