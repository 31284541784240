import React, { createRef } from "react"
import { isStyleObject } from './otp-input.utils'
import useSingleOtpInput from "./useSingleOtpInput.hooks"

const SingleOtpInput = (props) => {
  const input = createRef()
  const { getClasses, getType } = useSingleOtpInput(props, input)
  const {
    focus,
    shouldAutoFocus,
    isInputSecure,
    isInputNum,
    placeholder,
    separator,
    isLastChild,
    inputStyle,
    isDisabled,
    hasErrored,
    errorStyle,
    focusStyle,
    disabledStyle,
    index,
    value,
    className,
    ...rest
  } = props

  return (
    <div
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      <input
        aria-label={`${index === 0 ? "Please enter verification code. " : ""}${
          isInputNum ? "Digit" : "Character"
        } ${index + 1}`}
        autoComplete="off"
        style={Object.assign(
          isStyleObject(inputStyle) && inputStyle,
          focus && isStyleObject(focusStyle) && focusStyle,
          isDisabled && isStyleObject(disabledStyle) && disabledStyle,
          hasErrored && isStyleObject(errorStyle) && errorStyle
        )}
        placeholder={placeholder}
        className={getClasses(
          inputStyle,
          focus && focusStyle,
          isDisabled && disabledStyle,
          hasErrored && errorStyle,
          "otp-input"
        )}
        type={getType()}
        maxLength="1"
        ref={input}
        disabled={isDisabled}
        value={value ? value : ""}
        {...rest}
      />
      {!isLastChild && separator}
    </div>
  )
}

export default SingleOtpInput
