import { useState } from "react";

const useOfferAmount = () => {
  const [minOfferAmountData, setMinOfferAmountData] = useState(null);
  const [minimumAmountSheet, setMinimumAmountSheet] = useState(false);

  const handleMinAmountCloseSheet = () => {
    setMinimumAmountSheet(false);
  };

  return { minOfferAmountData, setMinOfferAmountData, minimumAmountSheet, handleMinAmountCloseSheet, setMinimumAmountSheet };
};

export default useOfferAmount;
