import SendOTP from "./SendOTP";
import OtpVerification from "./OtpVerification";
import useProtiumVerification from "./useProtiumVerification.hook";

const ProtiumVerification = ({ handleClose, orderId, onSuccess, mobileNumber, isSheetOpened }) => {
  const { handleCloseForOTPVerification, handleOTPSent, shopseRefId, disabledButton, isOTPSent, setIsOTPSent } = useProtiumVerification(mobileNumber, isSheetOpened, handleClose);

  return (
    <div>
      {isOTPSent ? (
        <SendOTP
          orderId={orderId}
          handleClose={handleClose}
          isOTPSent={setIsOTPSent}
          handleOTPSentCallback={handleOTPSent}
          mobileNumber={mobileNumber}
          disabledButton={disabledButton}
        />
      ) : (
        <OtpVerification orderId={orderId} shopseRef={shopseRefId} onSuccess={onSuccess} handleClose={handleCloseForOTPVerification} />
      )}
    </div>
  );
};

export default ProtiumVerification;
