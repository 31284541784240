import { f7 } from "framework7-react";
import { useState } from "react";
import Api from "../../../services/api.service";

const useLenderOffers = (f7router) => {
  const [activeOfferID, setActiveOfferID] = useState(null);
  const [otpActive, setOtpActive] = useState(false);
  const [otp, setOtp] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [otpErr, showOTPErr] = useState(null);

  // to Open the Verify Otp Screen
  const proceedWithPlan = async (offerObj, obj) => {
    let offer = Object.assign({}, offerObj)
    const { paymentData, paymentId, transactionAmount } = obj;
    const { storeId, orderId } = f7router.currentRoute.params;
    setIsloading(true);
    f7.dialog.preloader("Please wait...");
    delete offer.interest_payable;
    offer.amount = transactionAmount;
    const payload = {
      offer,
      lr_id: paymentData?.lr_id,
      orderId: Number(orderId),
    };

    const resp = await Api.initFinvervLoan(payload);
    setIsloading(true);
    f7.dialog.close();
    console.log("resp", resp);
    if (resp.status === 200) {
      setOtpActive(true);
      setOtp(null);
    } else {
      localStorage.removeItem("lenderOffers_store");
      f7router.navigate(`/${storeId}/order-status/${paymentId}`, { transition: "f7-cover" });
    }
  };
  const onCloseOtpScreen = () => {
    f7.dialog.confirm("Are you sure want close?", "Caution", (e) => {
      setOtpActive(false);
    });
  };

  const onVerifyOtpOtp = async (obj) => {
    if (!otp) {
      showOTPErr("Please Enter the OTP");
      return;
    }
    if (otp?.length < 6) {
      showOTPErr("Please Enter a valid 6 digit OTP");
      return;
    }
    const { orderId, storeId } = f7router.currentRoute.params;
    setIsloading(true);
    f7.dialog.preloader("Verifying OTP...");
    const { paymentData, paymentId } = obj;
    const payload = {
      otp,
      lr_id: paymentData?.lr_id,
      orderId: Number(orderId),
    };
    const apiRes = await Api.confirmFinvervLoan(payload);
    console.log("apiRes", apiRes);
    setIsloading(false);
    f7.dialog.close();

    const resData = apiRes?.data;
    if (apiRes?.status === 400) {
      f7.dialog.alert("Please Enter a valid OTP", "Invalid OTP", () => {
        setOtp(null);
      });
    } else {
      localStorage.removeItem("lenderOffers_store");
      if (resData.status === "success") {
        setOtpActive(false);
      }
      f7router.navigate(`/${storeId}/order-status/${paymentId}`, { transition: "f7-cover" });
    }
  };

  return {
    setActiveOfferID,
    activeOfferID,
    otpActive,
    otp,
    isLoading,
    setOtp,
    onCloseOtpScreen,
    proceedWithPlan,
    onVerifyOtpOtp,
    otpErr,
    showOTPErr,
  };
};
export default useLenderOffers;
