import React from "react";
import { Sheet, Block } from "framework7-react";
import "./notificationpopup.css";

const NotificationPopUpV2 = ({ children, opened, onClose }) => {
  return (
    <Sheet
      id="notification-pop-up"
      opened={opened}
      onSheetClosed={onClose}
      push
      style={{
        height: "100%",
        "--f7-sheet-bg-color": "rgba(0, 0, 0, 0.3)",
        width: "100%",
      }}
      swipeToClose
      backdrop
    >
      <Block className="pop-up-content" style={{ width: "100%" }}>
        {children}
      </Block>
    </Sheet>
  );
};

export default NotificationPopUpV2;
