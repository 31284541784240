import { useState, useEffect } from "react";
import Api from "../../services/api.service";

const paymentSuccess = "PAYMENT_COMPLETED";
const paymentInitiated = "PAYMENT_INITIATED";

const useOrderStatus = (f7router) => {
  const [orderStatusData, setOrderStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const { transactionId } = f7router;

  useEffect(() => {
    let time = 15; // 15 seconds
    let intervalId;
    const timeZero = (timeIntervalId, res) => {
      setLoading(false);
      clearInterval(timeIntervalId);
      setOrderStatusData(res.data);
    };
    async function callOrderStatusAPI(timeIntervalId) {
      const res = await Api.getOrderSummary(transactionId);
      if (res?.data?.orderId) {
        setOrderId(res.data.orderId);
      }
      if (res?.data?.transactionStatus === paymentSuccess) {
        setLoading(false);
        clearInterval(timeIntervalId);
        setOrderStatusData(res?.data);
      } else if (res?.data?.transactionStatus === paymentInitiated) {
        if (time === 0) {
          timeZero(timeIntervalId, res);
        }
      } else if (time === 0) {
        timeZero(timeIntervalId, res);
      }
    }

    intervalId = window.setInterval(function () {
      callOrderStatusAPI(intervalId);
      time -= 5;
    }, 5000); // for each 5 sec call api

    callOrderStatusAPI(intervalId); // initial request

    return () => clearInterval(intervalId);
  }, [transactionId]);

  return { orderStatusData, loading, orderId };
};

export default useOrderStatus;
