import "./homepage.css";
import React, { useRef, useCallback, useState } from "react";
import {
  Block,
  // Row, Col, BlockTitle,
  BlockTitle,
  PageContent,
} from "framework7-react";
import Page from "../../container/PageWithBg";
import NavBar from "../../components/NavBar";
import SwipeCarousel from "../../components/SwipeCarousel";
import CategoryGrid from "../../components/categoryGrid";
import useHomePageInit from "./useHomePageInit.hook";
import StoreSplash from "./SplashView";
import { getShellMode } from "../../utilities";
import { useStore } from "framework7-react";
import { GET_SPLASH_STATUS } from "../../store";

// const SAMPLE_CARD = require("../../assets/card1@3x.png");

const HomePage = ({ f7router }) => {
  const navBarRef = useRef(null);
  const swipeCarouselRef = useRef(null);
  const [categoryHeight, setCategoryHeight] = useState(null);
  const { categories, banners, storeData, showHomepage, retailerId } = useHomePageInit(f7router);
  const splashIsActive = useStore(GET_SPLASH_STATUS);

  const updateCategoryViewHeight = useCallback(() => {
    const ADDITIONAL_BOTTOM_SPACE = 50;
    navBarRef.current && setCategoryHeight(navBarRef.current.el.offsetHeight + swipeCarouselRef.current.el.offsetHeight + ADDITIONAL_BOTTOM_SPACE);
  }, []);

  const isShellMode = getShellMode();
  return (
    <>
      <Page style={{ height: "auto" }}>
        {!splashIsActive && <NavBar f7router={f7router} showLogo={true} ref={navBarRef} />}

        <PageContent style={{ paddingTop: 0, height: "100vh" }}>
          <Block style={{ marginTop: "5px", marginBottom: "10px", padding: 0 }} ref={swipeCarouselRef}>
            <SwipeCarousel f7router={f7router} banners={banners} />
          </Block>
          <BlockTitle style={{ marginTop: "2px", marginBottom: "2px" }}>Explore offers by category</BlockTitle>
          <CategoryGrid f7router={f7router} categories={categories} height={categoryHeight} />
        </PageContent>
        {!isShellMode && <StoreSplash retailerId={retailerId} storeData={storeData} showHomepage={showHomepage} updateCategoryViewHeight={updateCategoryViewHeight} />}
      </Page>
    </>
  );
};

export default HomePage;
