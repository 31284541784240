import React, { useEffect, useState } from "react";
import { PageContent, useStore } from "framework7-react";
import Page from "../../container/PageWithBg";

import useOrderDetails from "./useOrderDetails.hooks";
import SelectPaymentMethod from "./SelectPaymentMethod";
import eventClient from "../../services/system.events";
import { SHOW_FAB } from "../../constants/events";
import NotificationPopUp from "../../components/NotificationPopUp";
import NavBar from "../../components/NavBar";

import { GET_SELECTED_OFFER } from "../../store";
import useOfferAmount from "./useOfferAmount.hook";
import ProductListing from "./ProductListing";
import ZestWidget from "../../components/PaymentInputMethods/ZestWidget";
import { event } from "../../utilities/subscriber.events";
import ShowNotification from "./ShowNotification";
import useLoadInstacred from "../../hooks/useLoadInstacred";

const DEFAULT_PRODUCT_TOP = 100; // to avoid over animation , we define this

const OrderSummary = (props) => {
  const selectedOfferData = useStore(GET_SELECTED_OFFER); // get default offer selected
  useLoadInstacred();

  const { f7router, offer: finalOffer, showPaymentModule, mobileNumber } = props;
  const [selectedOffer, setSelectedOffer] = useState(finalOffer || selectedOfferData); // pass default offer
  const [sheetStatus, setSheetStatus] = useState(false);
  const [showWidget, setShowWidget] = useState(false);

  // eslint-disable-next-line
  const [sheetTop, setSheetTop] = useState(DEFAULT_PRODUCT_TOP);

  // eslint-disable-next-line
  const { sheetOpened, orderSummary, paymentSheet, toggleOfferSheet, togglePaymentSheet, verifyPhoneNumber, isVerified, offerAmount, hideCategory, notifyCardLessEMI, streamData } =
    useOrderDetails(f7router, selectedOffer);
  const { minOfferAmountData, setMinOfferAmountData, minimumAmountSheet, handleMinAmountCloseSheet, setMinimumAmountSheet } = useOfferAmount();
  // eslint-disable-next-line
  const { offerData = [], productData = {}, totalOrderAmount = "", paymentData: defaultPaymentData = {}, orderId, paymentId } = orderSummary;
  const paymentData = { ...defaultPaymentData };
  if (!paymentData.mobileNumber) {
    // this comes from the OTP verification callback
    paymentData.mobileNumber = mobileNumber;
  }
  // const { categoryName = "", displayName = "", modelName = "", categoryIcon } = orderSummary?.productData[0];
  const theBlock = React.useRef(null);

  const sheetStyle = {
    height: theBlock.current ? window.innerHeight - theBlock.current.el.offsetHeight : "auto",
    // added fix to avoid screen jump when keyboard is open
    position: "fixed",
    top: theBlock.current ? theBlock.current.el.offsetHeight + 70 : "auto",
    borderRadius: "30px 30px 0 0",
    "--f7-sheet-bg-color": "#F5F6F7",
    paddingTop: 2,
    overflow: "hidden",
  };

  useEffect(() => {
    if (offerData.length && showPaymentModule) {
      togglePaymentSheet();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerData]);
  // console.log(orderSummary);

  useEffect(() => {
    eventClient.emit(SHOW_FAB, false);
    return () => eventClient.emit(SHOW_FAB, true);
  }, []);

  const handleOfferSelect = (offer) => {
    if (!offer) {
      setSelectedOffer(null);
    }
    if (selectedOffer && offer?.offerId === selectedOffer?.offerId) {
      setSelectedOffer(undefined);
      return;
    }
    if (totalOrderAmount >= parseInt(offer?.minOfferAmount)) {
      setSelectedOffer(offer);
    } else {
      setMinOfferAmountData(offer?.minOfferAmount);
      setMinimumAmountSheet(true);
    }
  };

  const proceedWithNext = () => {
    const { params: { storeId, transactionId } = {} } = f7router.currentRoute;
    // coming back from payment screen needs this - else it works as expected.
    f7router.navigate(`/${storeId}/pay/${transactionId}`, {
      props: {
        offer: offerData,
        showPaymentModule: true,
        selectedOffer: selectedOffer,
        mobileNumber,
      },
    });

    togglePaymentSheet();
  };

  const handleNext = () => {
    setSheetStatus(false);
    const goToNextScreen = proceedWithNext;
    const goToPhoneVerify = () => verifyPhoneNumber(selectedOffer);
    const fn = isVerified ? goToNextScreen : goToPhoneVerify;
    fn();
  };

  const handleCloseNotification = () => {
    setSheetStatus(false);
  };

  const onImageLoad = () => {
    setSheetTop(theBlock.current ? theBlock.current.el.offsetHeight : "auto");
  };

  useEffect(() => {
    if (totalOrderAmount && selectedOffer) {
      if (parseInt(selectedOffer?.minOfferAmount) < totalOrderAmount) {
        // igone since it has normal flow
      } else {
        setSelectedOffer(null);
      }
    }
    // eslint-disable-next-line
  }, [totalOrderAmount]);

  React.useEffect(() => {
    event.on("openZestWidget", (e) => setShowWidget(e));
    // eslint-disable-next-line
  }, []);

  // console.log(streamData);

  return (
    <>
      {orderSummary && <NavBar f7router={f7router} storeName={orderSummary?.paymentData?.storeName} showRightLogo={true} style={{ width: "90%", margin: "0 auto" }} />}
      <Page isLoading={!orderSummary.offerData} hideQrScanner>
        <PageContent style={{ paddingTop: 0, backgroundColor: streamData?.error === true ? "#f5f6f7" : "white" }}>
          {/* TODO: Enable product listing page to remove the slider */}

          {streamData?.error === true ? <ShowNotification msg={streamData?.msg} /> : (
            <>
              <ProductListing products={orderSummary?.productData} theBlock={theBlock} onImageLoad={onImageLoad} f7router={f7router} />
              <SelectPaymentMethod
                hideCategory
                notifyCardLessEMI={notifyCardLessEMI}
                selectedOffer={selectedOffer ? selectedOffer : ""}
                handleOfferSelect={handleOfferSelect}
                setSelectedOffer={setSelectedOffer}
                paymentId={paymentId}
                paymentData={paymentData}
                orderId={orderId}
                paymentSheet={true}
                sheetStyle={sheetStyle}
                totalOrderAmount={totalOrderAmount}
                togglePaymentSheet={togglePaymentSheet}
                offerData={offerData}
                f7router={f7router}
              />
            </>
          )}

          {/* Handle Next */}
          <NotificationPopUp
            sheetStatus={sheetStatus}
            onClose={handleCloseNotification}
            image="/images/offer_no.png"
            details="You have not selected any offer. Do you still want to proceed?"
            onSubmit={handleNext}
          />
          {/* Handle < min order value */}
          <NotificationPopUp
            sheetStatus={minimumAmountSheet}
            onClose={handleMinAmountCloseSheet}
            image="/images/offer_no.png"
            details={`Minimum order value should be ₹${minOfferAmountData}`}
            cancelButtonText="Close"
            hideSubmit={true}
          />
        </PageContent>
        {showWidget && <ZestWidget />}
      </Page>
    </>
  );
};

export default OrderSummary;
