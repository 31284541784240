import React, { useEffect } from "react";
import { usePageReady } from "../../hooks/usePageReady.hook";
import Page from "../../container/PageWithBg";
import { f7 } from "framework7-react";

const RedirectToKB = () => {
  const { isPageReady } = usePageReady(1000);
  useEffect(() => {
    if (isPageReady) {
      let storeId = localStorage.getItem("storeId");
      let transactionId = localStorage.getItem("transactionId");
      const url = f7.views.main.router.currentRoute.url;
      const updatedURL = url.replace("/signin?iframe_redirect_url=", "");
      f7.view.main.router.navigate(`/${storeId}/lender-onboarding/${transactionId}`, { props: { newStateLink: updatedURL } });
    }
    // return () => {}
  }, [isPageReady]);
  return <Page isLoading={!isPageReady} />;
};

export default RedirectToKB;
