import React, { PureComponent } from 'react';
import "./toast.css";

export default class Toast extends PureComponent {
    render() {
        const { isActive, message } = this.props;
        let classType = "toast";
        classType += isActive ? " fadeIn" : " fadeOut" 
        return (
            <div
                className={classType}
            >
                {message}
            </div>
        )
    }
}