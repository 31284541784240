import React from "react";
import PaymentOfferCard from "./PaymentOfferCard";
import "./style/offerlist.css";

export default function OfferList({ offerData, handleOfferSelect, selectedOffer }) {
  const [offers, setOffers] = React.useState(offerData);

  const onSelectOffer = (event) => {
    let tempOffers = [...offers];
    handleOfferSelect(event);
    offers.forEach((offer, i) => {
      // Swaping the offer index to show the selected offer
      if (offer.offerId === event.offerId && i > 1) {
        let temp = tempOffers[0];
        tempOffers[0] = offer;
        tempOffers[i] = temp;
      }
    });
    setOffers(tempOffers);
  };

  return (
    <div style={{ paddingBottom: 2 }}>
      {offers.length > 0 && (
        <div className="offer-holder">
          <div className="offer-label">
            <img src="/images/payment_icons/percentage.svg" className="percentage-icon" alt="percentage" />
            <p className="avaliable_text">Available Offers</p>
          </div>
          <div>
            <PaymentOfferCard offerData={offers} handleOfferSelect={onSelectOffer} selectedOffer={selectedOffer} />
          </div>
        </div>
      )}
    </div>
  );
}
