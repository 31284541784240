import React from "react";
import { PageContent, Block, f7 } from "framework7-react";
import Page from "../../container/PageWithBg";
import { usePageReady } from "../../hooks/usePageReady.hook";

const NotFound404 = () => {
  const [loading, setLoading] = React.useState(true);
  const { isPageReady } = usePageReady(500);
  React.useEffect(() => {
    const f7router = f7.views.main.router;
    f7router.on("routeChanged", (newRoute, previousRoute, router) => {
      setLoading(true);
      if (previousRoute.params.storeId && newRoute.path === "/") {
        f7.store.dispatch("setSplashStatus", false);
        router.navigate(`/${previousRoute.params.storeId}`);
      }
    });
  }, []);

  return (
    <>
      <Page isLoading={!isPageReady}>
        {!loading && (
          <PageContent style={{ paddingTop: 0 }}>
            <Block>
              <h1 style={{ textAlign: "center" }}>Page you are looking for is not there!</h1>
            </Block>
          </PageContent>
        )}
      </Page>
    </>
  );
};

export default NotFound404;
