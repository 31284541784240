import React from "react";
import { Button, Block, Preloader, Icon } from "framework7-react";
import NotificationPopUpV2 from "../../../components/NotificationPopUp/NotificationPopUpV2";
import useCurrentOffer from "./Hook/useCurrentOffer";

const OffersCard = ({ offer, selectedOffer, handleOfferSelect, onSelectOffer = () => {} }) => {
  const { offerDetails, offerDetailsLoading, accordionActive, activeOfferData, deactivateAccordion, activeOfferId, offerChange } = useCurrentOffer(
    handleOfferSelect,
    selectedOffer
  );
  return (
    <div className={"offer-card"} key={offer.offerId}>
      <div className="offer-header-container">
        <span className="arz__offer_icon">
          <Icon size="18" f7="creditcard" />
        </span>
        <div className="offer-title">
          {offer.offerTitle.length > 56 ? `${offer.offerTitle.slice(0, 57)}... ` : offer.offerTitle}
          {/* Details Button */}
          {offer.description && (
            <span
              className="m-small"
              onClick={() => {
                offerDetails(offer.offerId, accordionActive);
              }}
              style={{ color: "#317d3a", marginLeft: 5, fontSize: 12 }}
            >
              More details
            </span>
          )}
        </div>
        <span className="arz__flex_space" />
        <div
          className="round-checkbox"
          onClick={() => {
            offerChange(offer);
            onSelectOffer(false);
          }}
        >
          <input type="checkbox" className="checkbox" checked={activeOfferId === offer.offerId || selectedOffer.offerId === offer.offerId} readOnly />
          <label htmlFor="checkbox"></label>
        </div>
      </div>

      <NotificationPopUpV2 opened={accordionActive} onClose={deactivateAccordion}>
        <div>
          {offerDetailsLoading ? (
            <Block style={{ width: "100%", textAlign: "center", padding: 20 }}>
              <Preloader color="green" />
            </Block>
          ) : (
            <Block className="offer-details-popup">
              {activeOfferData && (
                <>
                  <h4 className="offer-popup-title">{activeOfferData.title}</h4>
                  <p className="offer-popup-desc">{activeOfferData.description}</p>

                  <ul className="offer-conditions-list">
                    {activeOfferData?.offerDetails?.map((offerText) => {
                      return <li key={`offerText_${offerText.text}`}>{offerText.text}</li>;
                    })}
                  </ul>
                </>
              )}
              <Button className="button" onClick={deactivateAccordion}>
                Close
              </Button>
            </Block>
          )}
        </div>
      </NotificationPopUpV2>
    </div>
  );
};

export default OffersCard;
