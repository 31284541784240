import React from "react";
import { Block, Checkbox, Icon, List, ListItem, AccordionContent, Preloader } from "framework7-react";
import useOfferCard from "./useOfferCard.hook";
import "./offercard.css";
const SAMPLE_CARD = require("../../assets/card1@3x.png");

const routeNames = {
  categoryOffer: "category-offer",
  payment: "payment",
};

const OfferCard = ({ offer, onSelect, selected, onClick, f7router, categoryId, className }) => {
  // eslint-disable-next-line no-unused-vars

  const { offerTitle, subtitle, description, usage, offerIcon, offerId } = offer;
  const { handleCardSelect, handleCardTitleClick, offerDetails, activeOfferData, loading, accordionActive } = useOfferCard(onClick, onSelect, offer);
  // offerHighlightIcon => is actuall product image for OF 4.5 screen;
  let { name } = f7router ? f7router?.currentRoute?.route : true;
  const storeId = f7router?.currentRoute?.params?.storeId;

  return (
    <div className={`${className && className}`} onClick={handleCardSelect} style={{ marginBottom: 20 }}>
      <Block
        style={{
          display: "flex",
          alignItems: "center",
          padding: 15,
          background: "white",
          borderRadius: 10,
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <img style={{ width: "40px" }} className="ccIcon" src={offerIcon || SAMPLE_CARD.default} alt="logo" />
        <div style={{ marginLeft: 20 }}>
          <span style={{ fontWeight: "bold", fontSize: 24, color: "#000807" }}>{offerTitle}</span> <span style={{ fontSize: 14, color: "#000807" }}>{subtitle}</span>
        </div>
        <div style={{ marginLeft: "auto" }}>
          {/* {name !== "category-offer" && ( */}
          <div className="checkbox-container">
            <Checkbox checked={selected} disabled color="green" />
          </div>
          {/* )} */}
        </div>
      </Block>
      <div
        style={{
          width: "95%",
          margin: "auto",
          borderTop: "2px dashed #f5f9e9",
        }}
      ></div>
      <Block
        style={{
          padding: 15,
          background: "#f5f9e9",
          borderRadius: 10,
          marginTop: 0,
          marginBottom: 0,
        }}
      >
        <div className="description_container">
          <div className="description_details">
            <span style={{ fontSize: 14, color: "#000807" }}>
              {description}
              {name === routeNames.categoryOffer && (
                <span style={{ textDecoration: `${onClick && "underline"}`, color: "green", fontWeight: "bold", marginLeft: "4px" }} onClick={handleCardTitleClick}>
                  More Details
                </span>
              )}
            </span>
          </div>
          <div style={{ color: "#9c9c9c", textAlign: "center", display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
            <Icon style={{ color: "#3a9545", marginBottom: "3px" }} size="15" f7="person_2_fill" />
            &nbsp;
            <span>{usage}</span>
          </div>
        </div>
        {name === routeNames.payment && (
          <List accordionList style={{ margin: "0px" }} className="offer-details">
            <ListItem accordionItem title={`${accordionActive ? "Less Details" : "More Details"}`} onClick={(el) => offerDetails(el, storeId, categoryId, offerId, accordionActive)}>
              <AccordionContent>
                {loading ? (
                  <Block style={{ width: "100%", textAlign: "center", padding: 20 }}>
                    <Preloader color="green" />
                  </Block>
                ) : (
                  <Block>
                    <ul style={{ padding: 10 }}>
                      {activeOfferData &&
                        activeOfferData.map((offerText) => {
                          return (
                            <li style={{ listStyleType: "disc" }} key={`offerText_${offerText.row}`}>
                              {offerText.text}
                            </li>
                          );
                        })}
                    </ul>
                  </Block>
                )}
              </AccordionContent>
            </ListItem>
          </List>
        )}
      </Block>
    </div>
  );
};

export default OfferCard;
