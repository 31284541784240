import { useState } from "react";
import Api from "../../services/api.service";

const useShopseSendOtp = (mobileNumber, handleClose) => {
  const [phoneNumber, setPhoneNumber] = useState(mobileNumber);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSendOTP = async (orderId, handleOTPSentCallback) => {
    const res = await Api.sendShopseOtp({ orderId, phoneNumber });
    if (res.ok) {
      setIsOtpSent(true);
      handleOTPSentCallback(res.data.data);
    } else {
      handleOTPSentCallback(false);
      setErrorMsg(res.data.msg);
    }
  };

  const handleSheetClose = () => {
    setErrorMsg(null);
    handleClose();
  };

  return { handleSendOTP, setPhoneNumber, phoneNumber, isOtpSent, errorMsg, setErrorMsg, handleSheetClose };
};

export default useShopseSendOtp;
