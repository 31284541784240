import config from "../config";
import { create } from "apisauce";
window.cacheResp = {};
const LOCALSTORAGE_CACHEKEY = "api_cache";
const api = create({ baseURL: config.apiUrl });

const calls = {};

function cacheResponse(method, cacheKey, resp, useStorage) {
  if (method === "get") {
    window.cacheResp[cacheKey] = { resp, setAt: new Date() };
    useStorage && localStorage.setItem(LOCALSTORAGE_CACHEKEY, JSON.stringify(window.cacheResp));
  }
}
const hydrateCache = () => {
  try {
    const temp = localStorage.getItem(LOCALSTORAGE_CACHEKEY);
    window.cacheResp = JSON.parse(temp) || {};
  } catch (e) {}
};

const endpoints = {
  // Removed as redundant api call defination: getOrderSummary
  // orderStatus: {
  //   point: "gopay/payment_intent/", // same exact endpoint is repeated as `getOrderSummary`, use only one.
  //   method: "get",
  //   attachToken: false,
  // },
  fetchHomeBanners: {
    point: "identity/v2/retailer_details/get_banner/",
    method: "get",
    useStorage: true,
    useCache: true,
  },
  fetchCategories: {
    point: "identity/v2/retailer_products/category/",
    method: "get",
    useStorage: true,
    useCache: true,
  },
  currentCategoryOffers: {
    point: "identity/v2/retailer_products/current_offers/",
    method: "get",
    useCache: true,
  },
  getOrderSummary: {
    point: "/transactions/v2/payment_intent/",
    method: "get",
    attachToken: false,
    useCache: false,
  },
  startPaymentProcess: {
    point: "transactions/v2/paymentForOrder",
    method: "post",
    attachToken: true,
  },
  storeDetails: {
    point: "identity/v2/retailer_details/retailer_info/",
    method: "get",
    TTL: 24 * 60,
    useStorage: true,
    useCache: true,
  },
  bankList: {
    point: "identity/v2/razorpay/get_net_banking_details/",
    method: "get",
  },
  getEMIDetails: {
    point: "identity/v2/emi_plans",
    method: "post",
  },
  updateProtiumTranch: {
    point: "transactions/v2/protium/update_tranche",
    method: "post",
  },
  initFinvervLoan: {
    point: "transactions/v2/payment/finverv/loan-init",
    method: "post",
    attachToken: true,
  },
  confirmFinvervLoan: {
    point: "transactions/v2/payment/finverv/confirm-loan",
    method: "post",
    attachToken: true,
  },
  getFlexLenders: {
    point: "transactions/v2/payment/flexmoney/lenders/",
    method: "get",
    attachToken: true,
  },
  flexPaymentCallback: {
    point: "transactions/v2/payment/flexmoney/callback",
    method: "post",
    attachToken: true,
  },
  sendShopseOtp: { point: "transactions/v2/send_shopse_otp", method: "post" },
  // verifyShopeSe: { point: "transactions/v2/verify_shopse_otp", method: "post" },
  protiumOTPVerification: { point: "transactions/v2/protium/verify_otp", method: "post" },
  protiumOffers: { point: "transactions/v2/protium/status/", method: "get" },
  sendOtp: { point: "identity/v2/customer/send_otp", method: "post" },
  verifyOtp: { point: "identity/v2/customer/verify_otp", method: "post" },
  verifyToken: { point: "/transactions/v2/payment/verify_payment_code", method: "post" },
  paymentEvents: { point: "/transactions/v2/payment/events", method: "post", attachToken: true },
};

calls.preparePostHeaders = (headers) => {
  return { headers: { ...headers } };
};

const returnApiCall = ({ method, point: endPt, attachToken = false, multipart = false, TTL: CACHE_TTL_MINS = 2, useStorage, useCache }) => {
  return async (payload, ep = "", token, uploadProgress) => {
    let ePt = endPt; // copying to localscope was needed because of assign by reference behaviour of js
    ePt = ep ? endPt + ep : endPt;

    if (typeof payload != "object") {
      ePt = `${endPt}${payload ? payload : ""}`;
      payload = null;
    }

    let header = undefined;
    // added temp token for avoid otp blocker
    if (attachToken) {
      const token = localStorage.getItem("AUTH_TOKEN") || "";

      header = calls.preparePostHeaders({
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      });
    }

    if (multipart) {
      header = calls.preparePostHeaders({
        ...(header.headers || {}),
        "Content-type": "multipart/form-data",
      });
    }
    if (uploadProgress) {
      let config = { onUploadProgress: uploadProgress }; // axis config onUploadProgress
      header = { ...header, ...config };
    }

    // todo: add cache first strategy
    // creating an in browser memory cache, so the cache will be cleared when user refreshes the page
    const cacheKey = `${ePt}-${JSON.stringify(payload || {})}`;
    if (window.cacheResp[cacheKey]) {
      const ttlTime = new Date(window.cacheResp[cacheKey].setAt).getTime();
      const timeDifference = new Date().getTime() - ttlTime;
      const minutes = Math.floor(timeDifference / 1000 / 60);
      if (minutes < CACHE_TTL_MINS) {
        console.log("Using cache", ePt);
        return window.cacheResp[cacheKey].resp;
      }
      console.log("Cache Expired", ePt);
    }

    const resp = await api[method](ePt, payload, header);
    if (useCache && resp.status === 200 && resp.data.status === "success") {
      // store cache only when API response is successful
      cacheResponse(method, cacheKey, resp, useStorage);
    }

    return resp;
  };
};

const points = Object.keys(endpoints);
for (let i = 0; i < points.length; i++) {
  let key = points[i];
  let ep = endpoints[key];
  calls[key] = returnApiCall(ep);
}
hydrateCache();
window.apiCalls = calls;
export default calls;
