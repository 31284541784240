import React, { useEffect } from "react";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook_url } from "../../config";
import { getBrowserDetails, getPlatformId, parseJwt } from "./utils";

const growthbook = new GrowthBook({
  trackingCallback: (experiment, result) => {
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

export default function GrowthBookFeatureProvider({ children }) {
  const location = window.location;
  useEffect(() => {
    const callGrowthBook = async () => {
      fetch(growthbook_url)
        .then((res) => res.json())
        .then((json) => {
          growthbook.setFeatures(json.features);
        });

      let tokenPayload = parseJwt(localStorage?.AUTH_TOKEN);
      let platformID = getPlatformId(location.pathname);
      let tokenValues = { platform_id: Number(platformID) };
      let finalSetAttribute = { ...tokenValues, ...tokenPayload, browser: getBrowserDetails() };

      growthbook.setAttributes(finalSetAttribute);
    };

    if (location.pathname !== "/") {
      callGrowthBook();
    }
  }, [location]);
  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>;
}
