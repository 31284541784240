import React from "react";
import Api from "../../services/api.service";

const useOTPValidation = (f7router, offer, productData, isPageReady) => {
  const [isValidNumber, setIsValidNumber] = React.useState(false);
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState("");
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [offerData, setOfferData] = React.useState(null);

  const onVerificationSuccess = (token) => {
    localStorage.setItem("AUTH_TOKEN", token);
    const { params: { storeId, transactionId } = {} } = f7router.currentRoute;
    f7router.navigate(`/${storeId}/pay/${transactionId}`, {
      props: { offer: offerData, showPaymentModule: true, mobileNumber },
    });
  };

  const sendOTP = async () => {
    try {
      setError(false);
      setLoading(true);

      const response = await Api.sendOtp({ mobileNumber });

      if (response?.data?.status === "success") {
        setSent(true);
      } else {
        setError("Error sending OTP");
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError("Error sending OTP");
    }
  };

  const verifyOTP = async () => {
    try {
      setError(false);
      setLoading(true);

      const { params: { transactionId } = {} } = f7router.currentRoute;

      const response = await Api.verifyOtp({
        username: mobileNumber,
        OTP: otp,
        paymentId: transactionId,
      });

      if (response?.data?.status === "success") {
        onVerificationSuccess(response?.data?.token.accessToken);
      } else if (response?.data?.status === "error") {
        setError(response?.data?.msg);
      } else {
        setError("Error verifying OTP");
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError("Error verifying OTP");
    }
  };

  React.useEffect(() => {
    isPageReady && !productData.categoryName && f7router.back();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageReady, productData.categoryName]);

  React.useEffect(() => {
    if (offer) {
      setOfferData(offer);
    }
  }, [offer]);

  return {
    isValidNumber,
    setIsValidNumber,
    mobileNumber,
    setMobileNumber,
    otp,
    setOtp,
    sendOTP,
    verifyOTP,
    error,
    sent,
    loading,
  };
};

export default useOTPValidation;
