import "./pageLoader.css";
const PageLoader = ({ noMargin = false }) => {
  return (
    <div className="boxes" style={{ marginTop: noMargin ? "" : "calc(50vh - 50px)" }}>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
      <div className="box"></div>
    </div>
  );
};

export default PageLoader;
