import { Popup, f7 } from "framework7-react";
import Page from "../../container/PageWithBg";
import logo from "../../assets/logo/gostor-white@3x.png";
import percentIcon from "../../assets/icons-offers@3x.png";
import React from "react";
import eventClient from "../../services/system.events";
import { SHOW_FAB } from "../../constants/events";
import store, { DISPATCH_SET_SPLASH_ACTIVE } from "../../store";

const offerText = ["Exclusive Offers", "Credit Card EMI", "Debit Card EMI", "Cardless EMI"];

const StoreSplash = ({ showHomepage, storeData, retailerId, updateCategoryViewHeight }) => {
  const [openPopup, setOpenPopup] = React.useState(true);
  const [activeId, setActiveId] = React.useState(0);

  React.useEffect(() => {
    if (showHomepage) {
      eventClient.emit(SHOW_FAB, false);
      f7.dialog.close();
      setTimeout(() => {
        setOpenPopup(false);
        updateCategoryViewHeight();
        f7.store.dispatch(DISPATCH_SET_SPLASH_ACTIVE, false);
        eventClient.emit(SHOW_FAB, true);
      }, 3000);
    }
  }, [showHomepage, updateCategoryViewHeight]);

  React.useEffect(() => {
    let intervalHandle = setInterval(interValFunction, 800);
    function interValFunction() {
      if (activeId >= offerText.length - 1) {
        setActiveId(0);
      } else {
        setActiveId(() => {
          return activeId + 1;
        });
      }
    }
    return () => clearInterval(intervalHandle);
  }, [activeId]);

  return (
    <>
      {store.state.isSplashActive && (
        <Popup id="splashScreenPopup" opened={openPopup}>
          <Page isLoading={!showHomepage} hideQrScanner={!showHomepage}>
            {showHomepage && (
              <>
                <div style={{ minHeight: 100, width: "70%", backgroundColor: "#fff", margin: "auto", borderRadius: 20, marginBottom: 150, textAlign: "center" }}>
                  <div style={{ fontSize: 16, textAlign: "center", backgroundColor: "#f5f9e9", padding: 10 }} className="theme-dark-text">
                    Welcome to
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "60%", margin: "0 auto" }}>
                    {storeData.storeIcon && <img src={storeData.storeIcon} style={{ maxWidth: 100, maxHeight: 50, marginTop: 10, marginBottom: 10 }} alt="store icon" />}
                    <p style={{ color: "black", fontWeight: "bold" }}>{storeData.storeName}</p>
                  </div>
                </div>

                <div id="bigCircle">
                  <div id="innerCircle">
                    <div style={{ width: "100%" }}>
                      <img src={percentIcon} alt="" style={{ width: "20%", margin: "auto", marginBottom: 10, display: "block" }} />
                      {offerText[activeId]}
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: 30 }}>
                  <span style={{ opacity: 0.6, fontSize: 12 }}>Powered by</span>
                  <img src={logo} alt="" style={{ width: "80px", margin: "auto", marginTop: 15, display: "block" }} />
                </div>
              </>
            )}
          </Page>
        </Popup>
      )}
    </>
  );
};

export default React.memo(StoreSplash);
