import React from "react";
import { Fab, Sheet, Page, Button, Block } from "framework7-react";
import { getShellMode } from "../../utilities";
import usePayFloating from "./usePayFloating.hook";
import OtpInput from "../OtpInput";
import "./payfloatingbtn.css";

function PayFloatingButton({ storeId }) {
  const {
    sheetOpened,
    setSheetOpened,
    isLoading,
    setTokenValue,
    tokenValue,
    errorMsg,
    showPayBtn,
    resetSheetValueOnClose,
    handleTokenSubmit,
    handleOnBlur,
    handleOnFocus,
    defaultHeight,
  } = usePayFloating(storeId);
  const isShellMode = getShellMode();
  return (
    <>
      {showPayBtn && !isShellMode && (
        <Fab
          position="center-bottom"
          slot="fixed"
          text="pay now >>"
          color="green"
          style={{ zIndex: 100000, width: "80%" }}
          sheetOpen=".payToken-sheet"
          onClick={() => setSheetOpened(true)}
        />
      )}
      {/* keyboard will be present in mobile devices , and on web browser it will full screen */}
      <Sheet className="payToken-sheet" opened={sheetOpened} onSheetClosed={resetSheetValueOnClose} style={{ zIndex: 1000000, height: `${defaultHeight ? "75%" : "100%"}` }}>
        <Page className="payTokenContainer">
          <form onSubmit={handleTokenSubmit}>
            <Block>
              <h3>Enter Transaction ID</h3>
            </Block>
            <Block style={{ textAlign: "center" }} className="otp-container">
              <OtpInput className="align-center" value={tokenValue} numInputs={4} onChange={setTokenValue} onFocus={handleOnFocus} onBlur={handleOnBlur} />
            </Block>
            <Block className="error-msg-container" style={{ marginTop: "0px" }}>
              {errorMsg !== null ? <span style={{ color: "red", marginLeft: "20px", fontSize: "12px" }}>{errorMsg}</span> : null}
            </Block>

            <Block strong className="verify-container">
              <div>
                <Button color="black" onClick={resetSheetValueOnClose}>
                  CANCEL
                </Button>
              </div>
              <div>
                <Button type="submit" fill preloader loading={isLoading} disabled={tokenValue?.length < 4 || tokenValue === null} color="green">
                  Verify
                </Button>
              </div>
            </Block>
          </form>
        </Page>
      </Sheet>
    </>
  );
}

export default PayFloatingButton;
