import "./swipeCarousel.css";
import React from "react";
import { Swiper, SwiperSlide } from "framework7-react";

const SwipeCarousel = React.memo(({ banners, smallBanner, f7router }) => {
  // const slidesPerview = smallBanner ? 2.5 : 1.2;
  const spaceBetween = smallBanner ? 16 : 20;

  const onOfferSelect = (category, offerId) => {
    return () => {
      const { storeId } = f7router.currentRoute.params;
      f7router.navigate(`/offer/${storeId}/${category}/${offerId}`);
    };
  };

  return (
    <Swiper speed={500} slidesPerView="auto" spaceBetween={spaceBetween} scrollbar observer resistanceRatio={0} centeredSlides={true} resistance={false} centeredSlidesBounds={true}>
      {!smallBanner && (
        <>
          {banners.map((banner) => (
            <SwiperSlide onClick={onOfferSelect(banner.categoryId, banner.offerId)} style={{ backgroundImage: `url(${banner.img_url})` }} key={banner.img_url}></SwiperSlide>
          ))}
          {/* <SwiperSlide style={{ backgroundImage: `url(${bannerImg.default})` }} key={1}></SwiperSlide> */}
        </>
      )}
      {smallBanner && (
        <>
          {banners.map((banner) => (
            <SwiperSlide className="smallSwiper" key={banner.img_url}>
              <img src={banner.img_url} alt="banner" style={{ width: "100%" }} />
              <span>{banner.text}</span>
            </SwiperSlide>
          ))}
        </>
      )}
    </Swiper>
  );
});

export default SwipeCarousel;
