import { Page, PageContent } from "framework7-react";
import "./gopayhomeproduct.css";
import featured_image from "../../assets/featured_product.png";
import rightArrowImage from "../../assets/right-arrow.png";

const GopayHomeProduct = () => {
  return (
    <Page name="gopay-home" isLoading={false} className="product-home">
      <PageContent>
        <div className="main-dash">
          <h1 className="title">GOPAY</h1>
          <p className="slogon">
            goPay is a revolutionary digital platform that allows customers to avail in-store offers like never before. Customers can now pay using various modes of payment such as
            EMIs both on your Debit and Credit cards, Cardless financing options, and others.
          </p>
        </div>
        <div className="feature-image-container">
          <img src={featured_image} className="featured-img" alt="featured images" />
        </div>
        <div className="feature-content">
          <p className="heading-our">Our</p>
          <p className="heading-offer">Offerings</p>
          <div>
            <p className="feature-text">
              <p>
                <img src={rightArrowImage} alt="right arrow 1" width="20" height="20" />{" "}
                <span> Create and display offers to make every in-store shopping experience memorable</span>
              </p>
              <p>
                <img src={rightArrowImage} alt="right arrow 2" width="20" height="20" />{" "}
                <span> Say goodbye to age old bulky devices. Make your phone a point of sale to collect payments</span>
              </p>
              <p>
                <img src={rightArrowImage} alt="right arrow 3" width="20" height="20" /> <span> Enjoy low transaction costs like never before and ZERO monthly rentals</span>
              </p>
              <p>
                <img src={rightArrowImage} alt="right arrow 4" width="20" height="20" /> <span> No more individual tie-ups with brands or banks.</span>
              </p>
              <p>
                <img src={rightArrowImage} alt="right arrow 5" width="20" height="20" /> <span> Give brand agnostic offers to every in-store purchase</span>
              </p>
            </p>
          </div>
        </div>
        <div className="product-footer">
          <p>Email Us - gopay_support@arzooo.com | Contact - 7406667558 | Address: Sharkfin Tech Pvt Ltd, 1668A RJP Towers, HSR, Bengaluru, Karnataka 560102</p>
          <p>
            <a href="/gopay/terms-conditions">Terms and Conditions</a>
          </p>
        </div>
      </PageContent>
    </Page>
  );
};

export default GopayHomeProduct;
