const SHELLMODE = "SHELLMODE";
export const getShellMode = () => {
  const url = new URL(window.location.href);
  let isShellMode = localStorage.getItem(SHELLMODE) === "true";
  if (!isShellMode) {
    const temp = url.searchParams.get("shell") === "true";
    isShellMode = temp && localStorage.setItem(SHELLMODE, url.searchParams.get("shell"));
  }
  return isShellMode;
};
