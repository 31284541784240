import { useEffect, useState } from "react";
import Api from "../services/api.service";
import paymentKeys from "../paymentkeys";
import { f7 } from "framework7-react";
import { WAIT_TIME } from "../utilities/constants";

const useUpiApps = (f7router) => {
  const [supportedApps, setSupportedApps] = useState([]);
  const [verifying, setVerifying] = useState(false);
  const [timerCount, setTimerCount] = useState();
  const [showTimer, setShowTimer] = useState(true);

  // Method to fetch all Supported apps from Razorpay
  const checkInstalledApps = async () => {
    const { storeId } = f7router.currentRoute.params;
    let keySettings = {
      key: paymentKeys[storeId].razorpayKey,
    };
    if (window && window?.Razorpay) {
      const rzp = new window.Razorpay(keySettings);
      rzp
        .getSupportedUpiIntentApps()
        .then((availableApps) => {
          console.log("available for payment", availableApps);
          setSupportedApps(availableApps);
        })
        .catch((e) => {
          console.log("no apps available");
        });
    }
  };
  useEffect(() => {
    checkInstalledApps();
    // eslint-disable-next-line
  }, []);

  const redirectOnPaymentStatus = async (obj = null) => {
    const { storeId, transactionId } = f7router.currentRoute.params;
    if (obj) {
      await Api.paymentEvents(obj, `/${transactionId}`);
    }
    f7router.navigate(`/${storeId}/order-status/${transactionId}`, { transition: "f7-cover" });
    setVerifying(false);
  };

  /**
   * Method to intent the UPI apps as per the requested app name and payment data object
   *
   * @param {string} appName 'gpay' | 'phonepe' | 'paytm' and 'any' in case of android only
   * @param {object} paymentPaylod
   */
  const payNow = (appName, paymentPaylod, storeId, transactionId) => {
    f7.dialog.close();
    setVerifying(true);
    let keySettings = {
      key: paymentKeys[storeId].razorpayKey,
    };
    // Creating Payload for UPI payment
    const payload = {
      amount: paymentPaylod.amount,
      method: "upi",
      contact: paymentPaylod.contact,
      email: paymentPaylod.email,
      order_id: paymentPaylod.order_id,
    };

    const rzp = new window.Razorpay(keySettings);
    rzp.createPayment(payload, { app: appName });
    rzp.on("payment.success", (res) => {
      console.log("success", res);
      debugger;
      const successObj = {
        event_type: "SUCCESS",
        data: res,
      };
      redirectOnPaymentStatus(successObj);
    });

    // To handle the error in case of if user don't have to app installed or closed the App
    rzp.on("payment.error", (res) => {
      console.log("error", res);
      debugger;
      // console.log("error", res.error);
      if (res.error.reason === "intent_no_apps_error") {
        setVerifying(false);
        f7.dialog.alert(`Failed to open ${appName.toUpperCase()} App`, () => {
          f7router.refreshPage();
        });
      } else if (res.error.reason === "payment_cancelled") {
        const errorObj = {
          event_type: "FAILED",
          data: res,
        };
        redirectOnPaymentStatus(errorObj);
      }
    });

    // To handle the The failed case
    rzp.on("payment.failed", (res) => {
      console.log("failed", res);
      debugger;
      const failedObj = {
        event_type: "FAILED",
        data: res,
      };
      redirectOnPaymentStatus(failedObj);
    });
  };

  // Method to cancel the current ongoing payment.
  const cancelPayment = () => {
    f7.dialog.confirm("Are you sure want to cancel the payment", () => {
      const { storeId } = f7router.currentRoute.params;
      let keySettings = {
        key: paymentKeys[storeId].razorpayKey,
      };
      const rzp = new window.Razorpay(keySettings);
      rzp.emit(`payment.cancel`);
      redirectOnPaymentStatus();
    });
  };

  // Show the timer
  const timer = (remaining) => {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;
    setTimerCount(m + ":" + s);
    remaining -= 1;

    if (remaining >= 0) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    }
    // After completion of timer redirect user on Payment Status
    setShowTimer(false);
    redirectOnPaymentStatus();
  };

  useEffect(() => {
    timer(WAIT_TIME);
    // eslint-disable-next-line
  }, []);

  // Not in use for now
  const checkAppInstalled = (app, paymentPaylod) => {
    const { storeId } = f7router.currentRoute.params;
    let keySettings = {
      key: paymentKeys[storeId].razorpayKey,
    };
    setVerifying(true);
    if (app === "any") {
      payNow(app, paymentPaylod);
      return;
    }
    const rzp = new window.Razorpay(keySettings);
    rzp
      .checkPaymentAdapter(app)
      .then((res) => {
        //gpay is  installed
        console.log("checkRes", res);
        f7.dialog.alert(JSON.stringify(res.description, null, 4), `${app.toUpperCase()}: App Detected`);
        debugger;
        payNow(app, paymentPaylod);
      })
      .catch((err) => {
        console.log(app, "is not installed");
        setVerifying(false);
        debugger;
        // f7.dialog.close();
        f7.dialog.alert(err.description, `${app.toUpperCase()}: Not Detected`);
      });
  };

  return {
    supportedApps,
    verifying,
    payNow,
    checkAppInstalled,
    cancelPayment,
    timerCount,
    showTimer,
  };
};
export default useUpiApps;
