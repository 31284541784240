import React, { useState } from "react";
import Page from "../../container/PageWithBg";
import NavBar from "../../components/NavBar";
import { Block, PageContent, Sheet, Popup } from "framework7-react";
import OfferCard from "../../components/OfferCard";
import EMIDetails from "../../components/EMIDetails";
import { useFetchOfferDetails } from "./useFetchOfferDetails.hooks";
import "./popup_style.css";

const OfferDetails = ({ f7router }) => {
  const [sheetOpened, offerDetail, offer, showEMIButton, offerData] = useFetchOfferDetails(f7router);
  const [popupOpened, setPopupOpened] = useState(false);
  const theBlock = React.useRef(null);
  // const { amount } = f7router.currentRoute.query;

  return (
    <>
      <Page isLoading={!offer}>
        <NavBar closeSheetBeforeNav={true} f7router={f7router} showBack={true} />
        {/* <PageContent style={{ paddingTop: 0, overflow: "hidden" }}> WAS CAUSING SCROLL ISSUES */}
        <Block ref={theBlock} style={{ marginTop: 15, padding: 20, position: "fixed", width: "100%", top: "3%" }}>
          {offer && <OfferCard offer={offer} selected={true} />}
        </Block>

        <Sheet
          id="offer-detail-sheet"
          opened={sheetOpened}
          style={{
            height: theBlock.current ? window.innerHeight - theBlock.current.el.offsetHeight - 20 : "70%",
            borderRadius: "30px 30px 0 0",
            "--f7-sheet-bg-color": "#e1e4e1",
          }}
          backdrop={false}
        >
          <PageContent style={{ height: "100%", padding: "5px 15px" }}>
            <h4 style={{ margin: "10px" }}>Offer Details</h4>
            <Block style={{ background: "white", borderRadius: 10, marginTop: 1 }}>
              <ul style={{ padding: 20 }}>
                {offerDetail.map((offer) => (
                  <li key={offer.row}>{offer.text}</li>
                ))}
              </ul>
            </Block>
            {showEMIButton && (
              <div style={{ textAlign: "center" }}>
                <span
                  style={{ borderRadius: 10, width: "60%", margin: "auto", color: "green", fontWeight: "500", textTransform: "uppercase", textDecoration: "underline" }}
                  onClick={() => setPopupOpened(true)}
                >
                  EMI Calculator
                </span>
              </div>
            )}
          </PageContent>
        </Sheet>
        {/* </PageContent> */}
        <Popup className="popup-emi-calculator" opened={popupOpened} onPopupClosed={() => setPopupOpened(false)}>
          {offer.showEmiDetails > 0 && <EMIDetails offer={offerData} popupActivated={popupOpened} />}
        </Popup>
      </Page>
    </>
  );
};

export default OfferDetails;
