import { useState, useEffect } from "react";
import Api from "../../services/api.service";

const useProtium = (f7router) => {
  const [loading, setLoading] = useState(true);
  const [protiumOfferData, setProtiumOfferData] = useState();
  const [selectedTenor, setSelectedTenor] = useState({});
  const [isApproved, setIsApproved] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    if (timeLeft === 0) {
      const { paymentId, storeId } = f7router?.currentRoute?.params;
      console.log("TIME LEFT IS 0");
      setTimeLeft(null);
      window.location = `/${storeId}/order-status/${paymentId}`;
    }
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [timeLeft]);

  useEffect(() => {
    const fetchStoreData = async () => {
      const response = await Api.protiumOffers(f7router?.currentRoute?.params?.paymentId);
      if (response.data.data.status === "Rejected") {
        setIsApproved(false);
        setLoading(false);
        setTimeLeft(5);
        // window.location = `/${storeId}/order-status/${paymentId}`;
      }
      if (response.data.data.status === "Approved") {
        setIsApproved(true);
        setProtiumOfferData(response.data);
        setSelectedTenor(response.data?.data?.tranche_offers[0]);
        setLoading(false);
      }

      if (response.data.status === "success" && response.data.msg === "onboarding in progress") {
        setIsApproved(false);
        setLoading(false);
        setTimeLeft(5);
      }
    };

    fetchStoreData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedTenor = (selectedTenor) => {
    setSelectedTenor(selectedTenor);
  };

  const updateProtium = async () => {
    const { paymentId, storeId } = f7router?.currentRoute?.params;
    let payload = selectedTenor;
    payload.payment_id = paymentId;
    let response = await Api.updateProtiumTranch(payload);

    if (response.ok) {
      window.location = `/${storeId}/order-status/${paymentId}`;
    }
  };

  return { protiumOfferData, loading, selectedTenor, handleSelectedTenor, updateProtium, isApproved, timeLeft };
};

export default useProtium;
