import { useState } from "react";
import { Button } from "framework7-react";
import OtpInput from "../OtpInput";
import Api from "../../services/api.service";

import "./shopese_verification.css";

const OtpVerification = ({ shopseRef, orderId, onSuccess, handleClose }) => {
  const [otpValue, setOtpValue] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleVerficationSubmit = async (e) => {
    e.preventDefault();
    if (otpValue.length === 6) {
      setErrorMsg(null);
      let res = await Api.protiumOTPVerification({
        verification_code: otpValue,
        payment_id: orderId,
      });

      if (res.data.status === "success") {
        onSuccess();
      } else {
        setErrorMsg(res.data.msg);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleVerficationSubmit}>
        <h4 style={{ textAlign: "center" }}>Verify your OTP</h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <OtpInput className="align-center small_text" numInputs={6} value={otpValue} onChange={setOtpValue} isInputNum={true} />
        </div>
        {errorMsg && <div style={{ textAlign: "center", color: "red", marginBottom: "10px" }}>{errorMsg}</div>}
        <div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button type="submit" fill color="green" style={{ width: "30%" }} disabled={otpValue?.length === 6 ? false : true}>
              Verify
            </Button>

            <Button color="green" outline style={{ width: "30%" }} onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default OtpVerification;
