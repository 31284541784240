const popularBanks = [
  {
    name: "Axis Bank",
    logo: "/images/bank_icons/axis.png",
    bankCode: "UTIB",
    active: false,
    label: "Axis",
  },
  {
    name: "ICICI Bank",
    logo: "/images/bank_icons/icici-bank.png",
    bankCode: "ICIC",
    active: false,
    label: "ICICI",
  },
  {
    name: "Kotak Mahindra Bank",
    logo: "/images/bank_icons/Kotak.png",
    bankCode: "KKBK",
    active: false,
    label: "Kotak",
  },
  {
    name: "State Bank of India",
    logo: "/images/bank_icons/sbi.png",
    bankCode: "SBIN",
    active: false,
    label: "SBI",
  },
];

export default popularBanks;
