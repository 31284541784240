import React from 'react'

export default function Rupifi({ updatePaymentPayload, selected, rupifiValue }) {


    if (selected === "BNPL") {
        updatePaymentPayload({
            methodDetails: { method: "BNPL", selected },
            paidThrough: "BNPL",
            modeOfPayment: "RUPIFI"
        });

    }

    return (
        <div className='credit_container'>
            <p style={{ color: "black" }}>Approved Limit - <span className='rupifi_value'>₹ {rupifiValue}</span></p>
        </div>
    )
}
