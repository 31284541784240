import React, { useState, useEffect, useRef } from "react";
import { ListInput, Row, Col, List, ListItem, Popup } from "framework7-react";
import { handleOnlyNumber, handleOnlyAlphabets } from "../../utilities/paymentValidation";
import EMIDetails from "../../components/EMIDetails";
import useCardPayment from "./useCardPayment";

const paymentMethod = {
  DEBIT_CARD: "card",
  CREDIT_CARD: "card",
  CREDIT_CARD_EMI: "emi", // will need additonal field emi_duration
  DEBIT_CARD_EMI: "emi",
};

const emiMethods = ["CREDIT_CARD_EMI", "DEBIT_CARD_EMI"];

export default function CardPayment({ type, updatePaymentPayload, totalOrderAmount, modeOfPayment = "RAZORPAY", errorMsg }) {
  const payMethod = paymentMethod[type];
  const popUpRef = useRef(null);
  const { positionTop, handleEMIPopUPPosition, bank, setBank } = useCardPayment();
  const extraParamsForEmiPayload = { emi_duration: "" };
  const [tempExpiryDate, setTempExpiryDate] = useState("");

  const [inputData, setInputData] = useState({
    "card[number]": "",
    "card[cvv]": "",
    "card[name]": "",
    "card[expiry_month]": "",
    "card[expiry_year]": "",
    method: payMethod,
    ...(payMethod === "emi" ? extraParamsForEmiPayload : {}),
  });

  const [validationName, setValidationName] = useState([]);
  const [expiryValidation, setExpiryValidaion] = useState(null);

  const formatExpiryDate = (val) => {
    if (val.length >= 4) {
      let newVal = val.split("/");
      return {
        "card[expiry_month]": `${newVal[0]}`,
        "card[expiry_year]": `${newVal[1]}`,
      };
    }
  };

  const formatInputDate = (e) => {
    const { value } = e.target;
    var v = value.replace("/", "");
    if (v.length > 2) {
      return `${v.slice(0, 2)}/${v.slice(2, 4)}`;
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "expiry_date") {
      let val;
      val = formatInputDate(e);
      setTempExpiryDate(val);
      let validDateFormat = formatExpiryDate(e.target.value);
      setInputData({ ...inputData, ...validDateFormat });
    } else {
      setInputData({ ...inputData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "card[name]") {
      setValidationName((oldValue) => [...oldValue, "card[name]"]);
    }
  };

  useEffect(() => {
    updatePaymentPayload({
      methodDetails: inputData,
      paidThrough: type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  const handleValidationValues = (val) => {
    if (validationName.indexOf(val) > -1) {
      return false;
    }
    return errorMsg ? (errorMsg?.indexOf(val) === -1 ? false : true) : false;
  };

  useEffect(() => {
    if (errorMsg) {
      let a = validationName.filter((x) => errorMsg.includes(x));
      if (a.includes("card[expiry_month]")) {
        setExpiryValidaion(true);
      } else {
        setExpiryValidaion(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMsg]);

  return (
    <div className="card_payment_container" style={{ paddingBottom: "20px" }}>
      {emiMethods.indexOf(type) > -1 && (
        <>
          <Row className="emi_duration_container" style={{ marginBottom: 10, marginTop: 10 }}>
            <Col>
              <List>
                <ListItem
                  outline
                  type="text"
                  popupOpen=".popup-emi-calculator"
                  link
                  title="Select Bank"
                  required
                  validate
                  validateOnBlur={true}
                  errorMessageForce={errorMsg ? (errorMsg?.indexOf("emi_duration") === -1 ? false : true) : false}
                >
                  <div className={`item-after ${bank && "bank_selected"}`}>{inputData.emi_duration ? `${bank} - ${inputData.emi_duration} Months` : ">"}</div>
                </ListItem>

                {inputData.emi_duration ? (
                  true
                ) : errorMsg && errorMsg?.indexOf("emi_duration") > -1 ? (
                  <span style={{ fontSize: "13px", color: "red", marginLeft: "16px" }}>Please Select Valid EMI Duration</span>
                ) : null}
              </List>
            </Col>
          </Row>
          <Popup onPopupOpen={() => handleEMIPopUPPosition(popUpRef)} ref={popUpRef} className="popup-emi-calculator" style={{ top: positionTop }}>
            <EMIDetails
              offer={{ paidThrough: type, modeOfPayment }}
              totalOrderAmount={totalOrderAmount}
              smartSelect={true}
              onSelect={(d, bank) => {
                const payload = { target: { name: "emi_duration", value: d } };
                handleInputChange(payload);
                setBank(bank);
              }}
            />
          </Popup>
        </>
      )}
      <Row>
        <Col>
          <List>
            <ListInput
              outline
              type="text"
              name="card[number]"
              label="Card Number"
              floatingLabel
              placeholder="Ex. 5644121212121212"
              onChange={(e) => handleOnlyNumber(e, handleInputChange, inputData["card[number]"].length)}
              value={inputData["card[number]"]}
              pattern="\d*"
              minlength="16"
              maxlength="16"
              errorMessage="Enter 16 digit card number"
              errorMessageForce={handleValidationValues("card[number]")}
              validate
              onValidate={(val) => {
                if (val) {
                  if (validationName.indexOf("card[number]") < 0) {
                    setValidationName((oldValue) => [...oldValue, "card[number]"]);
                  }
                }
              }}
              required
            />
          </List>
        </Col>
      </Row>
      <Row>
        <Col>
          <List>
            <ListInput
              outline
              type="text"
              name="expiry_date"
              label="Expiry Date"
              errorMessage="Invalid Expiry Date"
              floatingLabel
              validate
              placeholder="Ex. MM/YY"
              maxlength="5"
              value={tempExpiryDate}
              onChange={(e) => handleInputChange(e)}
              errorMessageForce={expiryValidation ? expiryValidation : handleValidationValues("card[expiry_month]")}
              required
              onValidate={(val) => {
                if (val) {
                  setExpiryValidaion(false);
                  if (validationName.indexOf("card[expiry_month]") < 0) {
                    setValidationName((oldValue) => [...oldValue, "card[expiry_month]"]);
                  }
                }
              }}
            />
          </List>
        </Col>

        <Col>
          <List>
            <ListInput
              outline
              type="password"
              name="card[cvv]"
              placeholder="***"
              label="CVV"
              maxlength="3"
              validate
              pattern="\d{3}"
              floatingLabel
              value={inputData["card[cvv]"]}
              errorMessage="Enter a valid cvv"
              onChange={(e) => handleOnlyNumber(e, handleInputChange, inputData["card[cvv]"].length)}
              required
              errorMessageForce={handleValidationValues("card[cvv]")}
              onValidate={(val) => {
                if (val) {
                  if (validationName.indexOf("card[cvv]") < 0) {
                    setValidationName((oldValue) => [...oldValue, "card[cvv]"]);
                  }
                }
              }}
            />
          </List>
        </Col>
      </Row>

      <Row>
        <Col>
          <List>
            <ListInput
              outline
              type="text"
              name="card[name]"
              label="Name"
              floatingLabel
              placeholder="Ex. Your Name"
              validate
              value={inputData["card[name]"]}
              minlength="3"
              errorMessage="Enter Card holder name"
              onChange={(e) => handleOnlyAlphabets(e, handleInputChange)}
              required
              errorMessageForce={handleValidationValues("card[name]")}
              onValidate={(val) => {
                if (val) {
                  if (validationName.indexOf("card[name]") < 0) {
                    setValidationName((oldValue) => [...oldValue, "card[name]"]);
                  }
                }
              }}
            />
          </List>
        </Col>
      </Row>
    </div>
  );
}
