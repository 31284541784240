import { useState } from "react";

export default function useEMIPopup() {
  const [activeEMIPOPup, setActiveEMIPOPup] = useState(false);
  const [positionTop, setPositionTop] = useState(0);

  const closePOPup = () => {
    setActiveEMIPOPup(false);
  };

  const handleSetTop = (popupRef) => {
    setPositionTop(popupRef.current.el.offsetHeight - popupRef.current.el.children[0].offsetHeight + 50);
  };

  return {
    activeEMIPOPup,
    closePOPup,
    positionTop,
    handleSetTop,
  };
}
