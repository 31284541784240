import React from "react";
import ProductDetails from "./ProductDetails";
import { List, ListItem, AccordionContent, Block } from "framework7-react";

export default function ProtiumOffer({ protiumOfferData, selectedTenor, handleSelectedTenor, updateProtium }) {
  return (
    <>
      <List accordionList style={{ margin: "0px" }}>
        <ListItem className="product_details_container" accordionItem title="Product Details" style={{ backgroundColor: "#283B75", color: "#fff" }}>
          <AccordionContent>
            <Block>
              <ProductDetails data={protiumOfferData?.data?.productDetails} />
            </Block>
          </AccordionContent>
        </ListItem>
      </List>
      <Block>
        <p style={{ fontWeight: "bold" }}>Select tenure</p>
        <div className="row">
          {protiumOfferData
            ? protiumOfferData.data.tranche_offers.map((tranch, index) => {
                return (
                  <div className="col" key={`tranch_${index}`} onClick={() => handleSelectedTenor(tranch)}>
                    <div className={`tenure_card ${selectedTenor.tenor === tranch.tenor ? "tenure_card_active" : ""}`}>
                      <p className="tenure_month">{tranch.tenor}</p>
                      <p className="tenure_pay">x ₹{tranch.emi}</p>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </Block>
      <Block>
        <div className="payment_offer_details">
          <div className="row">
            <div className="col">
              <div className="pay_details">
                <div className="row" style={{ borderBottom: "1px solid #283B75" }}>
                  <div className="col" style={{ fontWeight: "bold" }}>
                    Down Payment
                  </div>
                  <div className="col" style={{ textAlign: "right", fontWeight: "bold" }}>
                    ₹{selectedTenor.downpayment}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col" style={{ fontWeight: "bold" }}>
              EMI
            </div>
            <div className="col" style={{ textAlign: "right", fontWeight: "bold" }}>
              ₹{selectedTenor.emi}
            </div>
          </div>
        </div>

        <div className="payment_split">
          <div className="row">
            <div className="col">Product Amount</div>
            <div className="col text-right">₹{selectedTenor.downpayment + selectedTenor.amount}</div>
          </div>
          <div className="row">
            <div className="col">
              Interest payable <span style={{ fontSize: "11px" }}>(No Cost EMI)</span>
            </div>
            <div className="col text-right">₹ 0</div>
          </div>
          <div className="row">
            <div className="col">Processing Fee</div>
            <div className="col text-right"> ₹{selectedTenor.total_cost - selectedTenor.amount}</div>
          </div>
          <div className="row">
            <div className="col">Total Amount Payable</div>
            <div className="col text-right">₹{selectedTenor.downpayment + selectedTenor.amount + (selectedTenor.total_cost - selectedTenor.amount)}</div>
          </div>
        </div>
      </Block>
      <div className="ag_continue">
        <button className=" button button-fill" onClick={updateProtium}>
          Agree and Continue
        </button>
      </div>
    </>
  );
}
