import { useState, useEffect } from "react";
import Api from "../../services/api.service";
import popularBanks from "./PopularBanksList";

const useNetBanking = (updatePaymentPayload, type) => {
  const [bankList, setBankList] = useState([]);
  const [open, setOpen] = useState(false);
  const [popularBankList, setPopularBankList] = useState(popularBanks);

  const [netBankingData, setNetBankingData] = useState({
    bank: null,
    method: "",
    bankName: null,
  });

  useEffect(() => {
    const getAndUpdatelistOfBank = async () => {
      let { data: { supportedBanksData = {} } = {} } = await Api.bankList();
      const banks = Object.keys(supportedBanksData).map((key) => ({
        key,
        value: supportedBanksData[key].name,
        icon: supportedBanksData[key].icon_url,
      }));
      setBankList(banks);
      // handleInputChange("ICIC", supportedBanksData["ICIC"].name);
    };
    getAndUpdatelistOfBank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatePayload = { methodDetails: netBankingData, paidThrough: type };
    updatePaymentPayload(updatePayload);
    // on change highlight 
    setPopularBankList(filterActivePopularIcons(netBankingData.bank));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [netBankingData]);

  const handleInputChange = (bank, bankName) => {
    const newSate = { ...netBankingData, bank, bankName, method: "netbanking" };
    setNetBankingData(newSate);
    setOpen(false);
  };

  const filterActivePopularIcons = (bank) => {
    return popularBanks.map((pbankItem) => {
      if (pbankItem.bankCode === bank) {
        pbankItem.active = true;
      } else {
        pbankItem.active = false;
      }
      return pbankItem;
    });
  };

  const handlePopularBankListChange = (bank, bankName) => {
    setPopularBankList(filterActivePopularIcons(bank));
    handleInputChange(bank, bankName);
  };

  return {
    bankList,
    open,
    setOpen,
    netBankingData,
    handleInputChange,
    popularBankList,
    handlePopularBankListChange,
  };
};

export default useNetBanking;
