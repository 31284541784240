import React from "react";
import PageLoader from "../pageLoader";
import { Popup } from "framework7-react";

const Verifying = ({ open = false, onPopupClose = () => {} }) => {
  return (
    <Popup className="demo-popup" opened={open} onPopupClose={onPopupClose}>
      <div className="gopay_otp_screen_container">
        <div className="gopay__otp_wrapper">
          <PageLoader noMargin />
          <p className="mb-0">Your payment is being verified</p>
          <h3 className="mb-0 mt-5">Please do not close the browser or refresh</h3>
        </div>
      </div>
    </Popup>
  );
};

export default Verifying;
