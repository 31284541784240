import React, { forwardRef } from "react";
import { Navbar, NavLeft, NavRight, Link } from "framework7-react";
import logo from "../../assets/logo/arzooo_pro.png";

const PageHeader = (props, ref) => {
  const { showBack = false, onBackPress = () => {}, style = {} } = props;
  const storeName = localStorage.getItem("storeName") || "Arzooo PRO";
  return (
    <>
      <Navbar style={{ ...style, width: "100%" }} noHairline noShadow themeDark ref={ref}>
        <NavLeft>
          {showBack && <Link onClick={onBackPress} className="color-theme-white" icon="icon-back" />}
          <p style={{ fontSize: 17, paddingLeft: 10 }}>{storeName}</p>
        </NavLeft>
        <NavRight style={{ color: "#fff" }}>
          <span>
            <div style={{ fontSize: 9, paddingRight: 2, alignSelf: "start" }}>Powered by</div>
            <span style={{ paddingRight: 11, display: "flex", alignItems: "start", marginTop: 3 }}>
              <img className="gopayLogo" src={logo} alt="logo" style={{ height: 13 }} />
            </span>
          </span>
        </NavRight>
      </Navbar>
    </>
  );
};

export default forwardRef(PageHeader);
