import React from "react";
import { isStyleObject } from "./otp-input.utils";
import SingleOtpInput from "./singleOtpInput";
import useOtpInput from "./useOtpInput.hooks.js";

const OtpInput = (props) => {
  const { getOtpValue, getPlaceholderValue, activeInput, handleOnChange, handleOnKeyDown, handleOnInput, handleOnPaste, setActiveInput } = useOtpInput(props);
  const {
    numInputs = 4,
    isDisabled = false,
    shouldAutoFocus = true,
    isInputSecure = false,
    inputStyle,
    focusStyle,
    separator,
    disabledStyle,
    hasErrored,
    errorStyle,
    isInputNum,
    className,
    containerStyle,
  } = props;
  const dataCy = props["data-cy"];
  const dataTestId = props["data-testid"];

  const renderInputs = () => {
    const inputs = [];
    const otp = getOtpValue();
    const placeholder = getPlaceholderValue();
    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          placeholder={placeholder && placeholder[i]}
          key={i}
          index={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onInput={handleOnInput}
          onPaste={handleOnPaste}
          onFocus={(e) => {
            setActiveInput(i);
            props.onFocus && props.onFocus();
            e.target.select();
          }}
          onBlur={() => {
            props.onBlur && props.onBlur();
            setActiveInput(-1);
          }}
          separator={separator}
          inputStyle={inputStyle}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={shouldAutoFocus}
          isInputNum={isInputNum}
          isInputSecure={isInputSecure}
          className={className}
          data-cy={dataCy && `${dataCy}-${i}`}
          data-testid={dataTestId && `${dataTestId}-${i}`}
        />
      );
    }

    return inputs;
  };
  return (
    <div style={Object.assign({ display: "flex", marginBottom: 24 }, isStyleObject(containerStyle) && containerStyle)} className={!isStyleObject(containerStyle) ? containerStyle : ""}>
      {renderInputs()}
    </div>
  );
};

export default OtpInput;
