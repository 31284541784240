import { useEffect } from "react"
import { isStyleObject } from './otp-input.utils'

const useSingleOtpInput = (props, input) => {
  const { focus, shouldAutoFocus, isInputSecure, isInputNum } = props
  // Focus on first render
  // Only when shouldAutoFocus is true
  useEffect(() => {
    const { current: inputEl } = input

    if (inputEl && focus && shouldAutoFocus) {
      inputEl.focus()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const { current: inputEl } = input
    if (inputEl && focus) {
      inputEl.focus()
      inputEl.select()
    }
  }, [focus, input])

  const getClasses = (...classes) =>
    classes.filter((c) => !isStyleObject(c) && c !== false).join(" ")

  const getType = () => {
    if (isInputSecure) {
      return "password"
    }

    if (isInputNum) {
      return "tel"
    }

    return "text"
  }
  return {
    getClasses,
    getType,
  }
}

export default useSingleOtpInput