import { createStore } from "framework7/lite";

const store = createStore({
  state: {
    selectedOffer: null,
    isSplashActive: true,
    isFloatingButtonActive: false,
  },
  actions: {
    setSelectedOffer({ state }, offer) {
      state.selectedOffer = offer;
    },
    setSplashStatus({ state }, status) {
      state.isSplashActive = status;
    },
    setIsFloatingButtonActive({ state }, status) {
      state.isFloatingButtonActive = status;
    },
  },
  getters: {
    getSelectedOffer: ({ state }) => {
      return state.selectedOffer;
    },
    getSplashStatus: ({ state }) => {
      return state.isSplashActive;
    },
    getFloatingButtonActiveStatus: ({ state }) => {
      return state.isFloatingButtonActive;
    },
  },
});

export const GET_SELECTED_OFFER = "getSelectedOffer";
export const GET_SPLASH_STATUS = "getSplashStatus";
export const GET_FLOATINGBUTTON_ACTIVE_STATUS = "getFloatingButtonActiveStatus";

export const DISPATCH_SET_SELECTED_OFFER = "setSelectedOffer";
export const DISPATCH_SET_SPLASH_ACTIVE = "setSplashStatus";
// to hide offer sheet and redirect to payment page
export const DISPATCH_SET_FLOATINGBUTTON_ACTIVE = "setIsFloatingButtonActive";
export default store;
