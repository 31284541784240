const defaultCurrency = "inr";
const formatter = (value = 0, format, digits = 2) => {
  if (!value) return "-";
  switch (format) {
    case "currency":
      return Intl.NumberFormat("en-IN", {
        currency: defaultCurrency,
        minimumFractionDigits: digits,
        style: "currency",
      }).format(value);
    case "number":
      return Intl.NumberFormat("en-IN", {
        minimumFractionDigits: 0,
      }).format(value);
    default:
      return value;
  }
};

export default formatter;
