import { Page, PageContent } from "framework7-react";
import { FraudulentTransactionTnC, FraudulentText } from "./constants";
import "./terms-contitions.css";

const TNC = () => (
  <Page name="login" isLoading={false} className="terms-container">
    <PageContent style={{ backgroundColor: "white" }} className="terms-content">
      <div className="logo-container">
        <h2 className="gopay-title">goPay</h2>
        <p className="gopay-title">A revolutionary digital payment platform</p>
      </div>
      <div className="tnc-content">
        <h3 className="text-center">Terms and Conditions</h3>

        <p>
          This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic
          records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical
          or digital signatures.
        </p>
        <p>
          This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing
          the rules and regulations, privacy policy and Terms of Use for access or usage of domain name [go-pay.in] (“Website”) (hereinafter referred to as “Platform”)
        </p>
        <p>
          Your use of the Platform and services and tools are governed by the following terms and conditions (&quot;Terms of Use&quot;) as applicable to the Platform including the
          applicable policies which are incorporated herein by way of reference. If You transact on the Platform, You shall be subject to the policies that are applicable to the
          Platform for such transaction. By mere use of the Platform, You shall be contracting with Sharkfin Tech Private Limited and these terms and conditions including the
          policies constitute Your binding obligations, with goPay (Sharkfin Tech Private Limited).
        </p>

        <h3>CUSTOMER/ CARDHOLDER</h3>
        <h4>User eligibility</h4>
        <p>
          Transaction on the Platform is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are &quot;incompetent to
          contract&quot; within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the Platform. If you are a minor i.e.
          under the age of 18 years, you may use the Platform or access content on the Platform only under the supervision and prior consent/ permission of a parent or legal
          guardian.
        </p>
        <h4>Your Account and Registration Obligations</h4>
        <p>
          If You use the Platform, You shall be responsible for all activities that occur under your Display Name. You agree that if You provide any information that is untrue,
          inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in
          accordance with this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform and refuse to provide
          You with access to the Platform.
        </p>
        <p>
          Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Platform. It is your responsibility to ensure that Your mobile phone number
          and your email address is up to date on the Platform at all times.
        </p>
        <h4>Communications</h4>
        <p>
          When You use the Platform or send emails or other data, information or communication to us, You agree and understand that You are communicating with Us through electronic
          records and You consent to receive communications via electronic records from Us periodically and as and when required. We may communicate with you by email or by other
          mode of communication, electronic or otherwise.
        </p>
        <h4>Platform for Transaction and Communication</h4>
        <p>The Platform is a platform that Users utilize to view offers and complete transactions.</p>
        <p>Henceforward:</p>
        <ol>
          <li>
            All commercial/contractual terms are offered by and agreed to between Buyers and Sellers alone. The commercial/contractual terms include without limitation price,
            shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services and after sales services related to
            products and services. goPay does not have any control or does not determine or advise or in any way involve itself in the offering or acceptance of such
            commercial/contractual terms between the Buyers and Sellers.
          </li>
          <li>
            Placement of order by a Buyer with Seller on the Platform is an offer to buy the product(s) in the order by the Buyer to the Seller and it shall not be construed as
            Seller's acceptance of Buyer's offer to buy the product(s) ordered. The Seller retains the right to cancel any such order placed by the Buyer, at its sole discretion
            and the Buyer shall be intimated of the same by way of an email/SMS. Any transaction price paid by Buyer in case of such cancellation by Seller, shall be refunded to
            the Buyer. Further, the Seller may cancel an order wherein the quantities exceed the typical individual consumption. This applies both to the number of products ordered
            within a single order and the placing of several orders for the same product where the individual orders comprise a quantity that exceeds the typical individual
            consumption. What comprises a typical individual's consumption quantity limit shall be based on various factors and at the sole discretion of the Seller and may vary
            from individual to individual.
          </li>
          <li>
            goPay does not make any representation or Warranty as to specifics (such as quality, value, salability, etc) of the products or services proposed to be sold or offered
            to be sold or purchased on the Platform. goPay does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Platform.
            goPay accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
          </li>
          <li>
            goPay is not responsible for any non-performance or breach of any contract entered into between Buyers and Sellers. goPay cannot and does not guarantee that the
            concerned Buyers and/or Sellers will perform any transaction concluded on the Platform. goPay shall not and is not required to mediate or resolve any dispute or
            disagreement between Buyers and Sellers.
          </li>
          <li>
            goPay does not make any representation or warranty as to the item-specifics (such as legal title, creditworthiness, identity, etc) of any of its Users. You are advised
            to independently verify the bona fides of any particular User that You choose to deal with on the Platform and use Your best judgment in that behalf.
          </li>
          <li>
            goPay does not at any point of time during any transaction between Buyer and Seller on the Platform come into or take possession of any of the products or services
            offered by Seller nor does it at any point gain title to or have any rights or claims over the products or services offered by Seller to Buyer.
          </li>
          <li>
            At no time shall goPay hold any right, title or interest over the products nor shall goPay have any obligations or liabilities in respect of such contract entered into
            between Buyers and Sellers. goPay is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of
            stock, unavailable or back ordered.
          </li>
          <li>
            The Platform is only a platform that can be utilized by Users to reach a larger base to buy products or services. goPay is only providing a platform for communication
            and it is agreed that the contract for sale of any of the products or services shall be a strictly bipartite contract between the Seller and the Buyer.
          </li>
        </ol>
        <p>At no time shall goPay hold any right, title or interest over the products nor shall goPay have any obligations or liabilities in respect of such contract.</p>
        <p>
          goPay is not responsible for unsatisfactory or delayed performance of services or damages or delays as a result of products which are out of stock, unavailable or back
          ordered.
        </p>
        <h4>Charges</h4>
        <p>Membership on the Platform is free for its buyers. </p>
        <h4>Use of the Platform</h4>
        <p>You agree, undertake and confirm that Your use of Platform shall be strictly governed by the following binding principles:</p>
        <ol>
          <li>
            You shall not host, display, upload, modify, publish, transmit, update or share any information which:
            <ol type="a">
              <li> belongs to another person and to which You does not have any right to;</li>
              <li>
                is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially,
                ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening
                or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition)
                Act, 1986;
              </li>
              <li>is misleading in any way;</li>
              <li>
                is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or
                physical harm of any kind against any group or individual;
              </li>
              <li> harasses or advocates harassment of another person;</li>
              <li> involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</li>
              <li>promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
              <li>
                infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation
                unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];
              </li>
              <li>
                promotes an illegal or unauthorized copy of another person's copyrighted work (see "Copyright complaint" below for instructions on how to lodge a complaint about
                uploaded copyrighted material), such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect
                devices, or providing pirated music or links to pirated music files;
              </li>
              <li>contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</li>
              <li>provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</li>
              <li>
                provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer
                viruses;
              </li>
              <li>contains video, photographs, or images of another person (with a minor or an adult).</li>
              <li>
                tries to gain unauthorized access or exceeds the scope of authorized access to the Platform or to profiles, blogs, communities, account information, bulletins,
                friend request, or other areas of the Platform or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;
              </li>
              <li>
                engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the
                buying or selling of "virtual" products related to the Platform. Throughout this Terms of Use, goPay's prior written consent means a communication coming from
                goPay's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;
              </li>
              <li>solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</li>
              <li>interferes with another USER's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services;</li>
              <li>
                refers to any Platform or URL that, in Our sole discretion, contains material that is inappropriate for the Platform or any other Platform, contains content that
                would be prohibited or violates the letter or spirit of these Terms of Use.
              </li>
              <li>harm minors in any way;</li>
              <li>
                infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent
                or involve the sale of counterfeit or stolen products;
              </li>
              <li> violates any law for the time being in force;</li>
              <li>deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
              <li>impersonate another person;</li>
              <li>
                contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or
                contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with,
                diminish value of, surreptitiously intercept or expropriate any system, data or personal information;
              </li>
              <li>
                threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the
                commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation
              </li>
              <li>shall not be false, inaccurate or misleading;</li>
              <li>
                shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner
                under the provisions of any applicable law, rule, regulation or guideline for the time being in force.
              </li>
              <li>
                shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers;
                <ol>
                  <li>
                    You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent
                    manual process, to access, acquire, copy or monitor any portion of the Platform or any Content, or in any way reproduce or circumvent the navigational structure
                    or presentation of the Platform or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made
                    available through the Platform. We reserve Our right to bar any such activity.
                  </li>
                  <li>
                    You shall not attempt to gain unauthorized access to any portion or feature of the Platform, or any other systems or networks connected to the Platform or to
                    any server, computer, network, or to any of the services offered on or through the Platform, by hacking, password "mining" or any other illegitimate means.
                  </li>
                  <li>
                    You shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform nor breach the security or authentication measures
                    on the Platform or any network connected to the Platform. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to
                    Platform, or any other customer, including any account on the Platform not owned by You, to its source, or exploit the Platform or any service or information
                    made available or offered by or through the Platform, in any way where the purpose is to reveal any information, including but not limited to personal
                    identification or information, other than Your own information, as provided for by the Platform.
                  </li>
                  <li>
                    You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us including the terms
                    goPay, goPay.com, or otherwise engage in any conduct or action that might tarnish the image or reputation, of goPay or sellers on platform or otherwise tarnish
                    or dilute any goPay's trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us.
                    You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Platform or goPay's
                    systems or networks, or any systems or networks connected to goPay.
                  </li>
                  <li>
                    You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Platform or any transaction being
                    conducted on the Platform, or with any other person's use of the Platform.
                  </li>
                  <li>
                    You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal You send to Us on or through the
                    Platform or any service offered on or through the Platform. You may not pretend that You are, or that You represent, someone else, or impersonate any other
                    individual or entity.
                  </li>
                  <li>
                    You may not use the Platform or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal
                    activity or other activity which infringes the rights of goPay and / or others.
                  </li>
                  <li>
                    You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as
                    amended from time to time and also all applicable Domestic laws, rules and regulations (including the provisions of any applicable Exchange Control Laws or
                    Regulations in Force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and Regulations (including, but not limited to Sales Tax/VAT, Income
                    Tax, Octroi, Service Tax, Central Excise, Custom Duty, Local Levies) regarding Your use of Our service and Your listing, purchase, solicitation of offers to
                    purchase, and sale of products or services. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any
                    applicable law including exchange control laws or regulations for the time being in force.
                  </li>
                  <li>
                    Solely to enable Us to use the information You supply Us with, so that we are not violating any rights You might have in Your Information, You agree to grant Us
                    a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database
                    rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to Your Information. We will only use Your
                    information in accordance with the Terms of Use and Privacy Policy applicable to use of the Platform.
                  </li>
                  <li>
                    You shall not engage in advertising to, or solicitation of, other Users of the Platform to buy or sell any products or services, including, but not limited to,
                    products or services related to that being displayed on the Platform or related to us. You may not transmit any chain letters or unsolicited commercial or junk
                    email to other Users via the Platform. It shall be a violation of these Terms of Use to use any information obtained from the Platform in order to harass,
                    abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
        <ol>
          <li>
            It is possible that other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Platform and that You may be
            involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about You due to your use of the Platform,
            and that the recipient may use such information to harass or injure You. We does not approve of such unauthorized uses, but by using the Platform You acknowledge and
            agree that We are not responsible for the use of any personal information that You publicly disclose or share with others on the Platform. Please carefully select the
            type of information that You publicly disclose or share with others on the Platform.
          </li>
          <li>
            goPay shall have all the rights to take necessary action and claim damages that may occur due to your involvement/participation in any way on your own or through
            group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services).
          </li>
        </ol>
        <h4>Payment Facility for Buyers:</h4>
        <ol>
          <li>
            You, as a Buyer, understand that upon initiating a Transaction You are entering into a legally binding and enforceable contract with the Seller to purchase the products
            and /or services from the Seller using the Payment Facility, and You shall pay the Transaction Price through Your Issuing Bank to the Seller using Payment Facility.
          </li>
          <li>
            You, as a Buyer, may agree with the Seller through electronic communication and electronic records and using the automated features as may be provided by Payment
            Facility on any extension / increase in the Dispatch and/or Delivery time and the Transaction shall stand amended to such extent. Any such extension / increase of
            Dispatch / Delivery time or subsequent novation / variation of the Transaction should be in compliance with Payment Facility Rules and Policies.
          </li>
          <li>
            You, as a Buyer, shall electronically notify Payment Facility using the appropriate goPay Platform features immediately upon Delivery or non Delivery within the time
            period as provided in Policies. Non notification by You of Delivery or non Delivery within the time period specified in the Policies shall be construed as a deemed
            Delivery in respect of that Transaction. In case of Cash On Delivery transactions, Buyer is not required to confirm the receipt of products or services.
          </li>
          <li>
            You, as a Buyer, shall be entitled to claim a refund of the Transaction Price (as Your sole and exclusive remedy) in case You do not receive the Delivery within the
            time period agreed in the Transaction or within the time period as provided in the Policies, whichever is earlier. In case you do not raise a refund claim using
            Platform features within the stipulated time than this would make You ineligible for a refund.
          </li>
          <li>
            You, as a Buyer, understand that the Payment Facility may not be available in full or in part for certain category of products and/or services and/or Transactions as
            mentioned in the Policies and hence You may not be entitled to a refund in respect of the Transactions for those products and /or services
          </li>
          <li>
            Except for Cash On Delivery transaction, refund, if any, shall be made at the same Issuing Bank from where Transaction Price was received, or through any other method
            available on the Platform, as chosen by You.
          </li>
          <li>For Cash On Delivery transactions, refunds, if any, will be made via electronic payment transfers.</li>
          <li>Refund shall be made in Indian Rupees only and shall be equivalent to the Transaction Price received in Indian Rupees.</li>
          <li>
            For electronics payments, refund shall be made through payment facility using NEFT / RTGS or any other online banking / electronic funds transfer system approved by
            Reserve Bank India (RBI).
          </li>
          <li>
            Refunds may be supported for select banks. Where a bank is not supported for processing refunds, You will be required to share alternate bank account details with us
            for processing the refund.
          </li>
          <li>Refund shall be conditional and shall be with recourse available to goPay in case of any misuse by Buyer.</li>
          <li>We may also request you for additional documents for verification.</li>
          <li>Refund shall be subject to Buyer complying with Policies.</li>
          <li>
            goPay reserves the right to impose limits on the number of Transactions or Transaction Price which goPay may receive from on an individual Valid Credit/Debit/ Cash Card
            / Valid Bank Account/ and such other infrastructure or any other financial instrument directly or indirectly through payment aggregator or through any such facility
            authorized by Reserve Bank of India to provide enabling support facility for collection and remittance of payment or by an individual Buyer during any time period, and
            reserves the right to refuse to process Transactions exceeding such limit.
          </li>
          <li>
            goPay reserves the right to refuse to process Transactions by Buyers with a prior history of questionable charges including without limitation breach of any agreements
            by Buyer with goPay or breach/violation of any law or any charges imposed by Issuing Bank or breach of any policy.
          </li>
          <li>
            goPay may do such checks as it deems fit before approving the receipt of/Buyers commitment to pay (for Cash On Delivery transactions) Transaction Price from the Buyer
            for security or other reasons at the discretion of goPay. As a result of such check if goPay is not satisfied with the creditability of the Buyer or genuineness of the
            Transaction or other reasons at its sole discretion, goPay shall have the right to reject the receipt of / Buyers commitment to pay Transaction Price. For avoidance of
            doubt, it is hereby clarified that the ‘Cash on Delivery’ feature for payment, may be disabled for certain account users, at the sole discretion of goPay.
          </li>
          <li>
            goPay may delay notifying the payment confirmation i.e. informing Seller to Dispatch, if goPay deems suspicious or for Buyers conducting high transaction volumes to
            ensure safety of the Transaction and Transaction Price. In addition, goPay may hold Transaction Price and goPay may not inform Seller to Dispatch or remit Transaction
            Price to law enforcement officials (instead of refunding the same to Buyer) at the request of law enforcement officials or in the event the Buyer is engaged in any form
            of illegal activity.
          </li>
          <li>
            The Buyer and Seller acknowledge that goPay will not be liable for any damages, interests or claims etc. resulting from not processing a Transaction/Transaction Price
            or any delay in processing a Transaction/Transaction Price which is beyond control of goPay.
          </li>
        </ol>
        <h5>Compliance with Laws:</h5>
        <ol>
          <li>
            As required by applicable law, if the Customer makes a purchase of an amount equal to or above INR 2,00,000.00, the Customer will be required to upload a scanned copy
            of his/her PAN card on the Platform, within 4 days of making the purchase, failing which, the purchase made by the Customer will be cancelled. The requirement to submit
            the PAN card arises only once and if it has been submitted once by the Customer, it need not be submitted again. The order of the Customer shall stand cancelled if
            there is a discrepancy between the name of the Customer and the name on the PAN Card.
          </li>
          <li>
            Buyer and Seller shall comply with all the applicable laws (including without limitation Foreign Exchange Management Act, 1999 and the rules made and notifications
            issued thereunder and the Exchange Control Manual as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act, 2000 as
            amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002 and the rules made there under, Foreign Contribution Regulation
            Act, 1976 and the rules made there under, Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India) applicable to them
            respectively for using Payment Facility and goPay Platform.
          </li>
        </ol>
        <h5>Buyer's arrangement with Issuing Bank:</h5>
        <ol>
          <li>
            All Valid Credit / Debit/ Cash Card/ and other payment instruments are processed using a Credit Card payment gateway or appropriate payment system infrastructure and
            the same will also be governed by the terms and conditions agreed to between the Buyer and the respective Issuing Bank and payment instrument issuing company.
          </li>
          <li>
            All Online Bank Transfers from Valid Bank Accounts are processed using the gateway provided by the respective Issuing Bank which support Payment Facility to provide
            these services to the Users. All such Online Bank Transfers on Payment Facility are also governed by the terms and conditions agreed to between Buyer and the respective
            Issuing Bank.
          </li>
        </ol>
        <h4>MERCHANT</h4>
        <p>
          As a registered seller, you are allowed to enter item(s) for sale on the Platform in accordance with the Policies which are incorporated by way of reference in this Terms
          of Use. You must be legally able to sell the item(s) you list for sale on our Platform. You must ensure that the listed items do not infringe upon the intellectual
          property, trade secret or other proprietary rights or rights of publicity or privacy rights of third parties. Entered items may only include text descriptions that
          describe your item for sale. All entered items must be entered in an appropriate category on the Platform. All entered items must be kept in stock for successful
          fulfilment of sales.
        </p>
        <p>Prohibited list of items:</p>
        <p>The following are product categories that goPay prohibits merchants from selling: </p>
        <ul>
          <li>Counterfeit products</li>
          <li>
            Products that infringe on goPay’s or any third party’s intellectual property rights, and unauthorized use of copyrighted material, registered trademarks and/or patents;
            examples include but are not limited to:
            <ul>
              <li>Unauthorized use of Celebrity Images</li>
              <li>Use of Stolen Images</li>
              <li>Use of Branded Boxes, Bags, or Hangers</li>
              <li>Sporting Trademark</li>
            </ul>
          </li>
          <li>
            {" "}
            Weapon; examples include but are not limited to:
            <ul>
              <li>Firearms</li>
              <li>Guns</li>
              <li>Ammunition</li>
              <li>Throwing stars</li>
              <li>Explosive weapons</li>
              <li>High-powered laser pointers</li>
              <li>All silencers, whether intended for firearms or other items</li>
              <li>Any item intended for use as a silencer or commonly misused as a silencer</li>
              <li>Any material component/parts that can be used to build a silencer or attach a silencer to a firearm</li>
              <li>Real firearm building kits</li>
              <li>Assault weapon conversion pieces</li>
            </ul>
          </li>
          <li>
            * Drugs and Drug Paraphernalia; examples include but are not limited to:
            <ul>
              <li>Tobacco and Nicotine Products</li>
              <li>Marijuana and Cannabis Products</li>
              <li>Cigars and Cigarettes</li>
              <li>E-cigarettes, Hookah Pens, and Vape Pens</li>
              <li>E-Juice, E-Liquid, Vape liquid, and other Variants</li>
              <li>Tobacco, Marijuana, Cannabis, and Hemp Seeds</li>
              <li>Poppers and Muscle Relaxants</li>
              <li>Mod Boxes and Vape Kits</li>
              <li>Recreational and/or Party Drugs</li>
              <li>Pill Press Machines</li>
            </ul>
          </li>
          <li>Recalled Items</li>
          <li>
            Virtual/Digital Goods, Intangible Items, or Services; examples:
            <ul>
              <li>Gift cards or access codes</li>
              <li>Subscriptions to channels, websites, or other memberships</li>
              <li>Virtual money for online games</li>
              <li>Housekeeping, tour packages, or other services</li>
              <li>Purchased Social Media Followers</li>
              <li>Cryptocurrency delivered in digital or physical form</li>
            </ul>
          </li>
          <li>
            Graphic Sexual Content; examples:
            <ul>
              <li>Hardcore Pornography</li>
              <li>Sexual Content Including or Relating to Minors</li>
              <li>Gratuitous Nudity</li>
              <li>Unnecessary Demonstration of Intimate Products</li>
            </ul>
          </li>
          <li>Hateful Symbols & Messages</li>
          <li>
            False Advertising; examples include:
            <ul>
              <li>Watermarks, Logos, or Trademarks from other E-Commerce Sites</li>
              <li>Projected Shipping Times or Costs</li>
              <li>Unauthorized use of goPay-Owned Trademarks</li>
              <li>Unverified Price Information</li>
              <li>Unsubstantiated Warranties or Guarantees</li>
              <li>
                Logos, Seals, Insignias, Credentials, ID cards, Badges, Trade Dress, or Branding of Accredited Agencies or Organizations – for example:
                <ul>
                  <li>U.S. government agencies such as military branches, U.S. Coast Guard, and U.S. Customs and Border Protection</li>
                  <li>Federal, state, and local law enforcement agencies</li>
                  <li>Ride-sharing entities, such as Uber, Lyft, Gett, Via, Curb, Didi Chuxing, Grab, and Ola</li>
                </ul>
              </li>
              <li>Merchant Contact or Referral Information</li>
            </ul>
          </li>
          <li>
            * Privacy and Technology Violations; examples include but are not limited to:
            <ul>
              <li>Products designed to intentionally block transmission or reception of signals</li>
              <li>Spy cameras or surveillance gadgets advertised for unlawful activities</li>
              <li>Any equipment or device used to change odometer readings</li>
              <li>Media streaming devices intended to access unauthorized content, including IPTV boxes described as “Fully Loaded”</li>
            </ul>
          </li>
          <li>Ambiguous Listing</li>
          <li>Unlicensed Media</li>
          <li>Misleading Listing</li>
          <li>
            Medically Regulated Materials;examples:
            <ul>
              <li>Contacts</li>
              <li>Contact Lens Solution</li>
              <li>Human Growth Hormone</li>
              <li>Chloroform</li>
              <li>Penicillin</li>
              <li>Eyelash Growth Serum</li>
              <li>Prescription Strength Items</li>
              <li>Substances administered via injection</li>
            </ul>
          </li>
          <li>
            Hazardous Materials; examples include but are not limited to:
            <ul>
              <li>Butane</li>
              <li>Hoverboards</li>
              <li>Human By-Products</li>
              <li>Pesticides</li>
              <li>Tire spikes</li>
              <li>Any product containing glyphosate</li>
            </ul>
          </li>
          <li>
            Materials for Consumption without Authorization:
            <ul>
              <li>Food Items</li>
              <li>Pet Food</li>
              <li>Beverages</li>
              <li>Supplements and Vitamins</li>
            </ul>
          </li>
          <li>
            Materials for Consumption prohibited under all circumstances:
            <ul>
              <li>Alcohol</li>
            </ul>
          </li>
          <li>
            Blurred Information; examples:
            <ul>
              <li>Blurred or censored watermarks</li>
              <li>Blurred or censored tags</li>
              <li>Blurred or censored logos</li>
              <li>Blurred or censored faces</li>
            </ul>
          </li>
          <li>
            Safety Equipment; examples:
            <ul>
              <li>Child car seats</li>
              <li>Booster seats</li>
              <li>Seatbelts & Seatbelt extenders</li>
              <li>Child-carrying backpacks</li>
              <li>Baby carriers</li>
              <li>Baby slings</li>
            </ul>
          </li>
          <li>
            * Counterfeit Currency; examples:
            <ul>
              <li>Listings not clearly marked with "reproduction," "replica," or "copy" in both the title and description</li>
              <li>
                Listings selling replica or reproduction of banknotes (for currently commercially exchangeable currency) that the general public might mistake for genuine banknotes
              </li>
              <li>Products that are double-sided</li>
              <li>Products that are larger than 76% and smaller than 149% the size of the genuine item</li>
              <li>Listings without a photo of the actual product being sold</li>
              <li>Play/prop/replica coins</li>
              <li>Listings aimed at intentionally deceiving a collector</li>
              <li>Currently commercially exchangeable notes</li>
              <li>Any replica or reproduction of U.S. currency (i.e. bills or coins), including prop/play/parody money and collectibles</li>
              <li>Any replica or reproduction of Euro currency (i.e. bills or coins), including prop and play money</li>
            </ul>
          </li>
          <li>Price Gouging</li>
          <li>
            Animal Products; examples:
            <ul>
              <li>Live animals</li>
              <li>Any animal (live or deceased) that is a threatened or endangered species</li>
              <li>Products containing or made from endangered species or their parts</li>
            </ul>
          </li>
          <li>Regional Restrictions</li>
          <li>
            Plants and Plant Seeds; examples
            <ul>
              <li>Plant</li>
              <li>Plant seeds</li>
            </ul>
          </li>
        </ul>
        <p>
          <b>
            (Note: goPay prohibits the sale of plant seeds that make impossible claims or use images that misrepresent the type of seeds being sold within the listing under all
            circumstances, whether or not sold by pre-approved merchants within specific regions.)
          </b>
        </p>
        <ul>
          <li>Dangerous and Unsafe Items</li>
          <li>Distasteful Content</li>
          <li>Graphic Violence</li>
        </ul>
        <p>Further:</p>
        <ol>
          <li>
            Transactions, Transaction Price and all commercial terms such as Delivery, Dispatch of products and/or services are as per principal to principal bipartite contractual
            obligations between Buyer and Seller and payment facility is merely used by the Buyer and Seller to facilitate the completion of the Transaction. Use of the payment
            facility shall not render goPay liable or responsible for the non-delivery, non-receipt, non-payment, damage, breach of representations and warranties, non-provision of
            after sales or warranty services or fraud as regards the products and /or services listed on goPay's Platform.
          </li>
          <li>
            You have specifically authorized goPay or its service providers to collect, process, facilitate and remit payments and / or the Transaction Price electronically or
            through Cash on Delivery to and from other Users in respect of transactions through Payment Facility. Your relationship with goPay is on a principal to principal basis
            and by accepting these Terms of Use you agree that goPay is an independent contractor for all purposes, and does not have control of or liability for the products or
            services that are listed on goPay's Platform that are paid for by using the Payment Facility. goPay does not guarantee the identity of any User nor does it ensure that
            a Buyer or a Seller will complete a transaction.
          </li>
          <li>
            You understand, accept and agree that the payment facility provided by goPay is neither a banking nor financial service but is merely a facilitator providing an
            electronic, automated online electronic payment, receiving payment through Cash On Delivery, collection and remittance facility for the Transactions on the goPay
            Platform using the existing authorized banking infrastructure and Credit Card payment gateway networks. Further, by providing Payment Facility, goPay is neither acting
            as trustees nor acting in a fiduciary capacity with respect to the Transaction or the Transaction Price.
          </li>
        </ol>
        <h4>Fraudulent transactions on the platform</h4>
        <ol>
          {FraudulentTransactionTnC.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
        <p>{FraudulentText}</p>
      </div>
      <div className="pp">
        <div>
          <h3 className="text-center">Privacy Policy</h3>
        </div>
        <div>
          <p>
            This privacy policy (“Policy”) details how [●] ("we" or "us" or "our") gathers and handles the personal information obtained when the customer (“you”) visit our stores
            (online and offline), in relation to any and all services provided by us at the store or via any other medium related to us (all these services collectively referred to
            as the "Services").
          </p>
          <p>By visiting our store, you are accepting and consenting to the practices described in this Policy, so please read this Policy carefully before using our Services.</p>
          <p>
            We understand that you care about how your personal information is obtained and used, and we appreciate your faith in our ability to uphold the highest standards of
            care and sensibility in handling your data. We are committed to respecting and upholding your privacy, and if you have any further questions in this regard please feel
            free to contact us at the coordinates listed below.
          </p>
          <h4>Personal Information</h4>
          <p>
            Personal information, for the purposes of this Policy, refers to information that can be used to specifically identify any individual customer of ours, such as the
            customer’s name, address, email address, payment data, telephone number etc. This would also include information about a customer’s activities with regard to the usage
            of our Services, and demographic information such as nationality, date of birth, gender, geographic area, and preferences, whenever any such information is linked to
            the personal information that identifies an individual.
          </p>
          <p>
            Accordingly, we collect personal information from customers to help us personalise and continually improve their shopping experience at our stores and [●].com. We use
            the information we gather from you to assist us in:
          </p>
          <ul>
            <li>Handling orders.</li>
            <li>Delivering products and services.</li>
            <li>Processing payments.</li>
            <li>Communicating with you about orders, products, services and promotional offers.</li>
            <li>Updating our records and generally maintaining your account with us.</li>
            <li>Displaying content such as wishlists and customer reviews.</li>
            <li>Recommending merchandise and services relevant to your interests.</li>
          </ul>
          <p>
            We also use this information to improve our platform, prevent or detect fraud or abuses of our Services and enable third parties to carry out technical, logistical or
            other such functions on our behalf.
          </p>
          <h4>Types of information gathered</h4>
          <p>
            <b>Information You Provide</b>: We receive and store any information submitted by the customer on our website, mobile applications, or through any other platform or
            medium of communication. Customers retain the right not to provide certain information, however, this may interfere with their ability to avail full advantage of
            several of our features.
          </p>
          <p>We use the information that you provide for the purpose of:</p>
          <ul>
            <li>Responding to your requests.</li>
            <li>Customising your future shopping experience.</li>
            <li>Improving our platform.</li>
            <li>Personalising communications with you .</li>
          </ul>
          <h4>Sharing Information with Third Parties:</h4>
          <p>
            Information about our customers is an important part of our business and we are not in the business of selling it to others. We share customer information only as
            described below and the subsidiaries which we control and that are either subject to this Privacy Notice or follow practices at least as protective as those described
            in this Privacy Notice.
          </p>
          <h4>Affiliated Businesses We Do Not Control:</h4>
          <ul>
            <li>
              We work closely with affiliated businesses. In some cases, such as Marketplace sellers, these businesses operate stores at [●].com or sell offerings to you at [●].
            </li>
            <li>In other cases, we provide services jointly with or on behalf of these businesses.</li>
          </ul>
          <h4>Third Party Service Providers:</h4>
          <ul>
            <li>
              We employ other companies and individuals to perform functions on our behalf. Examples include fulfilling orders, delivering packages, sending postal mail and e-mail,
              analysing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card payments and providing
              customer service.
            </li>
            <li>
              They have access to personal information needed to perform their functions, but may not use it for other purposes. Further, they must process the personal information
              in accordance with this Privacy Notice and as permitted by the applicable law.
            </li>
          </ul>
          <h4>Compliance with the Law:</h4>
          <ul>
            <li>
              We may be required to share the personal information with government authorities and agencies for, inter alia, the purposes of verification of identity or for
              prevention, detection, investigation including cyber incidents, prosecution and punishment of offences.
            </li>
            <li>
              Further, we may release account and other personal information when we believe release is appropriate to enforce or apply our conditions of use and other agreements;
              or protect our rights, property or safety, or of our users or others.
            </li>
            <li>This includes exchanging information with other companies, organisations, government or regulatory authorities for fraud protection and credit risk reduction.</li>
          </ul>
          <p>
            You agree and consent to our disclosure of your information, if so required under the applicable law. Obviously, however, this does not include selling, renting,
            sharing or otherwise disclosing personally identifiable information from customers for commercial purposes in a way that is contrary to the commitments made in this
            Privacy Notice
          </p>
          <p>Examples of Information Collected</p>
          <p>Information You Give Us:</p>
          <p>
            You provide most such information when you search, buy, post, participate in a contest or questionnaire, or communicate with customer service. For example, you provide
            information when you:
          </p>
          <ul>
            <li>Place an order through [●] or one of our third-party sellers</li>
            <li>Complete a questionnaire or a contest entry form.</li>
            <li>Compile Wish Lists, provide and rate Reviews.</li>
            <li>Employ other personal notification services and Order Notifications.</li>
          </ul>
          <p>
            As a result of those actions, you might supply us with such information as: your name; address and phone number; credit card information; people to whom purchases have
            been dispatched (including addresses and phone numbers); content of reviews and e-mails to us; the personal description in your "Your Profile" and financial
            information.
          </p>
          <h4>Use of Information and Duration:</h4>
          <p>
            We will strive to protect the privacy and legal rights of all its customers. Any and all Personal and sensitive Information you provide to us such as usernames, emails,
            phone number, passwords or any other login information or personal information will be available to us and we have the right to use this information until the you
            communicate with us and direct us not to use it further. By making use of the Service, you agree for all Personal Information to be retained up until the time it is
            required for the Purpose or required under applicable law, whichever is later.
          </p>
          <h4>Notices and Revisions:</h4>
          <p>Our business changes constantly and our Privacy Notice will change also. Accordingly, we keep updating this Policy from time to time.</p>
          <ul>
            <li>
              Any changes made to this document shall be indicated by the date of updation on the top of this document. Any such changes will be applicable to you provided your
              continued usage of the Services beyond the date of updation.
            </li>
            <li>We may periodically send you e-mails with reminders of our notices and conditions, unless and until you have explicitly instructed us not to.</li>
            <li>We recommend that you check this Policy from time to time to ensure that you are fully informed of any changes that might affect you.</li>
          </ul>
          <p>
            Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account. However, we stand behind the promises we make,
            and will never materially change our policies and practices in any way that makes them less protective of customer information collected in the past without the consent
            of affected customers
          </p>
          <p>
            Please Contact us for further queries: <a href="mailto:care@go-pay.in">care@go-pay.in</a>{" "}
          </p>
        </div>
      </div>
    </PageContent>
  </Page>
);

export default TNC;
