import { useState, useEffect } from "react";
import Api from "../../services/api.service";
import { f7 } from "framework7-react";
import { DISPATCH_SET_FLOATINGBUTTON_ACTIVE } from "../../store";

const usePayFloating = (storeId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sheetOpened, setSheetOpened] = useState(false);
  const [tokenValue, setTokenValue] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showPayBtn, setShowPayBtn] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState(false);

  useEffect(() => {
    const f7router = f7.views.main.router;
    // pageBeforeIn -> only on navigation it will work
    f7router.on("pageBeforeIn", ({ app }) => {
      try {
        if (f7router.currentRoute?.route?.showPayBtn && !f7router.currentRoute.query?.fromPay) {
          setShowPayBtn(true);
        } else {
          setShowPayBtn(false);
        }
      } catch (e) {}
    });

    // handle on reload
    if (f7router.currentRoute?.route?.showPayBtn) {
      setShowPayBtn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sheetOpened) {
      f7.store.dispatch(DISPATCH_SET_FLOATINGBUTTON_ACTIVE, true);
    } else {
      f7.store.dispatch(DISPATCH_SET_FLOATINGBUTTON_ACTIVE, false);
    }
  }, [sheetOpened]);

  const verifyToken = async () => {
    setErrorMsg(null);
    setIsLoading(true);
    let res = await Api.verifyToken({
      paymentCode: tokenValue,
      storeId: storeId,
    });
    setIsLoading(false);
    if (res.data.status === "error") {
      setErrorMsg("Invalid code. Please try again");
    } else {
      const f7router = f7.views.main.router;
      f7router.navigate(`/${storeId}/pay/${res.data.data.paymentId}`);
    }
  };

  const resetSheetValueOnClose = () => {
    setTokenValue(null);
    setSheetOpened(false);
    setErrorMsg(null);
  };

  const handleTokenSubmit = (e) => {
    e.preventDefault();
    verifyToken();
  };

  const handleOnFocus = () => {
    setDefaultHeight(false);
  };

  const handleOnBlur = () => {
    setDefaultHeight(true);
  };

  return {
    isLoading,
    sheetOpened,
    setSheetOpened,
    setTokenValue,
    tokenValue,
    errorMsg,
    showPayBtn,
    resetSheetValueOnClose,
    handleTokenSubmit,
    handleOnFocus,
    handleOnBlur,
    defaultHeight,
  };
};

export default usePayFloating;
