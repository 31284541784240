import React from "react";
import { Icon, PageContent, Row, Sheet } from "framework7-react";
import "../order_summary.css";
import OffersCard from "./OfferCard";

export default function PaymentOfferCard({ offerData, handleOfferSelect, selectedOffer }) {
  const [showAllOffers, setShowAllOffers] = React.useState(false);

  return (
    <div className="paymentoffercard-container">
      <div>
        {offerData.slice(0, 2).map((offer) => (
          <OffersCard key={offer.offerId} offer={offer} selectedOffer={selectedOffer} handleOfferSelect={handleOfferSelect} />
        ))}
        {offerData.length > 2 && (
          <div className="arz__view_more_offer" onClick={() => setShowAllOffers(true)}>
            More Offers
          </div>
        )}
        <Sheet
          opened={showAllOffers}
          style={{
            zIndex: 11000,
            height: "100%",
            paddingTop: "40vh",
            "--f7-sheet-bg-color": "rgba(0, 0, 0, 0.3)",
          }}
        >
          <PageContent className="arz__products_list_content">
            <Row className="arz__products_header">
              <h3>Offers</h3>
              <div onClick={() => setShowAllOffers(false)}>
                <Icon size="25" f7="multiply"></Icon>
              </div>
            </Row>
            <div className="arz__products_list">
              {offerData.map((offer) => (
                <OffersCard
                  key={offer.offerId}
                  offer={offer}
                  selectedOffer={selectedOffer}
                  handleOfferSelect={handleOfferSelect}
                  onSelectOffer={(flag) => setShowAllOffers(flag)}
                />
              ))}
            </div>
          </PageContent>
        </Sheet>
      </div>
    </div>
  );
}
