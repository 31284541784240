import { useState, useEffect } from "react"

const useBankList = (banks = [], open) => {
  const [searchText, setSearchText] = useState("")
  const [filteredBanks, setFilteredBanks] = useState(banks)

  useEffect(() => {
    if (searchText) {
      const filtered = banks.filter(
        (bank) =>
          bank.value.toLowerCase().search(searchText.toLowerCase()) !== -1
      )
      setFilteredBanks(filtered)
    } else {
      setFilteredBanks(banks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, banks])

  useEffect(() => {
    if (open) {
      setSearchText("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return {
    searchText,
    setSearchText,
    filteredBanks,
  }
}

export default useBankList
