/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Api from "../../services/api.service";
import { f7 } from "framework7-react";
import { DISPATCH_SET_SELECTED_OFFER } from "../../store";

export const useFetchCategoryOffers = (f7router, inputs) => {
  const [sheetOpened, setSheetOpened] = React.useState(false);
  const [offers, setOffers] = React.useState([]);
  const [categoryDetails, setCategoryDetails] = React.useState({});
  const [selectedOffer, setSelectedOffer] = React.useState(null);

  React.useEffect(() => {
    const getCategoryOffers = async () => {
      const { storeId, category } = f7router.currentRoute.params;
      const resp = await Api.currentCategoryOffers({}, `${storeId}/category/${category}`);
      setOffers(resp.data.categoryOffers);
      setCategoryDetails(resp.data.categoryDetails);
      setTimeout(() => setSheetOpened(true), 100);
    };
    getCategoryOffers();
    return () => {
      document.getElementById("category-view-sheet")?.remove();
    };
  }, []);

  const onOfferSelect = (offer) => {
    if (selectedOffer && selectedOffer === offer.offerId) {
      setSelectedOffer(null);
      f7.store.dispatch(DISPATCH_SET_SELECTED_OFFER, null);
    } else {
      setSelectedOffer(offer.offerId);
      f7.store.dispatch(DISPATCH_SET_SELECTED_OFFER, offer);
    }
  };

  const onOfferClick = ({ offerId }) => {
    // return () => {
    const storeId = f7router.currentRoute.params.storeId ?? inputs.storeId;
    const categoryId = f7router.currentRoute.params.category ?? inputs.categoryId;
    // const amount = inputs?.amount; -> this has disabled since we are loading offer details in same page
    setSheetOpened(false);

    let routeURL;
    if (f7router.currentRoute.name === "payment") {
      routeURL = `/offer/${storeId}/${categoryId}/${offerId}?fromPay=true`;
    } else {
      routeURL = `/offer/${storeId}/${categoryId}/${offerId}`;
    }
    f7router.navigate(routeURL);
    // };
  };

  return { sheetOpened, offers, onOfferClick, categoryDetails, onOfferSelect, selectedOffer };
};
