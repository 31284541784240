import React from "react";
import { PageContent, Row, Col, Button, Block, Icon, Input } from "framework7-react";

const SendOtp = ({ mobileNumber, setMobileNumber, isValidNumber, setIsValidNumber, error, sendOTP, loading, containerHeight = 200 }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    sendOTP();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Row className="send-otp-wrapper">
          <div className="mobile-icon">
            <Icon size="24" f7="phone" color="black"></Icon>
          </div>
          <Col width="80">
            <div className="input-label">Mobile Number</div>
            <Input
              type="tel"
              style={{ padding: 0 }}
              inputStyle={{ height: 28, color: "black", border: "none" }}
              name="mobile"
              maxlength={10}
              minlength={10}
              pattern="[0-9]{10}"
              validate
              onValidate={(valid) => setIsValidNumber(valid)}
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </Col>
        </Row>
        <PageContent className="content-wrapper" style={{ height: containerHeight }}>
          <p className="description opacity6">Payment confirmation will be sent to this mobile number</p>
          <p className="description error">{error}</p>
        </PageContent>
        <Block className="otp-button-wrapper">
          <Button type="submit" color="green" fill large disabled={!mobileNumber || !isValidNumber || loading} preloader loading={loading}>
            Next
          </Button>
        </Block>
      </form>
    </>
  );
};

export default SendOtp;
