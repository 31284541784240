import React from "react";
import { Block, Col, Row, PageContent, Sheet, f7, useStore } from "framework7-react";
import Page from "../../container/PageWithBg";
import NavBar from "../../components/NavBar";
import OfferCard from "../../components/OfferCard";
import { useFetchCategoryOffers } from "./useFetchCategoryOffers.hook";
import { NAV_BAR_HEIGHT_OFFSET } from "../../constants/values";
import { GET_FLOATINGBUTTON_ACTIVE_STATUS, DISPATCH_SET_SELECTED_OFFER } from "../../store";

const CategoryView = ({ f7router }) => {
  const { offers, onOfferClick, categoryDetails, onOfferSelect, selectedOffer } = useFetchCategoryOffers(f7router);
  const theBlock = React.useRef(null);
  const activeStatus = useStore(GET_FLOATINGBUTTON_ACTIVE_STATUS);

  return (
    <>
      <Page isLoading={offers.length === 0}>
        <NavBar closeSheetBeforeNav={true} f7router={f7router} showBack={true} onBackPress={() => f7.store.dispatch(DISPATCH_SET_SELECTED_OFFER, null)} />
        {/* <PageContent style={{ paddingTop: 0, overflow: "hidden" }}> */}
        <Block ref={theBlock} style={{ marginTop: 0, position: "fixed", top: "8%", width: "100%" }}>
          <Row>
            <Col>
              <h1>{categoryDetails.categoryName}</h1>
            </Col>
            <Col style={{ textAlign: "right" }}>{categoryDetails.categoryIcon && <img style={{ width: "75%" }} src={categoryDetails.categoryIcon} alt="category" />}</Col>
          </Row>
        </Block>
        <Sheet
          id="category-view-sheet"
          opened={!activeStatus}
          style={{
            height: theBlock.current ? window.innerHeight - (theBlock.current.el.offsetHeight + NAV_BAR_HEIGHT_OFFSET) : "75%",
            borderRadius: "30px 30px 0 0",
            "--f7-sheet-bg-color": "#e1e4e1",
            paddingTop: 2,
          }}
          backdrop={false}
        >
          <PageContent style={{ maxHeight: "100%", padding: 20 }}>
            {offers.map((offer, index) => (
              <OfferCard
                key={offer.offerId}
                onClick={onOfferClick}
                className={offers.length === index + 1 ? "offer-card-container" : ""}
                onSelect={onOfferSelect}
                selected={selectedOffer === offer.offerId}
                offer={offer}
                f7router={f7router}
              />
            ))}
          </PageContent>
        </Sheet>
        {/* </PageContent> */}
      </Page>
    </>
  );
};

export default CategoryView;
