import { useState } from "react";
import { fetchOfferDetailsApi } from "../../services/apiAbstractions.service";

const useOfferCard = (onClick, onSelect, offer) => {
  const [activeOfferData, setActiveOfferData] = useState(null);
  const [accordionActive, setAccordionActive] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleCardTitleClick = () => {
    try {
      onClick ? onClick?.(offer) : onSelect(offer);
    } catch (e) {}
  };

  const handleCardSelect = (e) => {
    try {
      onSelect(offer);
      e.stopPropagation();
    } catch (e) {}
  };

  const offerDetails = async (e, storeId, category, offerId, accordionActive) => {
    e.stopPropagation();
    if (!accordionActive) {
      const resp = await fetchOfferDetailsApi(offerId);
      const categoryOfferDetails = resp.offerDetails;
      setAccordionActive(true);
      setActiveOfferData(categoryOfferDetails);
      setLoading(false);
    } else {
      setAccordionActive(null);
    }
  };

  return { handleCardTitleClick, handleCardSelect, offerDetails, activeOfferData, loading, accordionActive };
};

export default useOfferCard;
