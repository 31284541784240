import { useEffect, useState } from "react";
import { KREDITBEE, ZEST, FLEX } from "../../utilities/constants";
import Publisher from "../../utilities/publisher";
import { event } from "../../utilities/subscriber.events";
import Api from "../../services/api.service";
import { f7 } from "framework7-react";

const useCardlessEMI = (updatePaymentPayload, selected, protium, f7router, paymentData, totalOrderAmount, orderId) => {
  const [selectedLender, setSelectedLender] = useState(null);
  const [promptShown, setShowConfirmation] = useState(false);
  const [flexLenders, setFlexLenders] = useState([]);
  const [showFlexLenders, setShowFlexLenders] = useState(false);
  // const [showZestWidget, setShowZestWidget] = useState(false);
  const [onboardingPrompt, setOnboardingPrompt] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const methodDefaultDetails = {
    methodDetails: { method: "cardless_emi", provider: "walnut369", paymentPartner: "RAZORPAY", selected },
    paidThrough: "CARDLESS_EMI",
  };

  const methodProtiumDetails = {
    methodDetails: { method: "cardless_emi", provider: "walnut369", paymentPartner: "RAZORPAY", selected },
    paidThrough: "CARDLESS_EMI",
    modeOfPayment: "PROTIUM",
  };

  useEffect(() => {
    updatePaymentPayload(protium ? methodProtiumDetails : methodDefaultDetails);
    // eslint-disable-next-line
  }, [selected, protium]);

  const onSelectLender = (lender) => {
    setErrorMessage(false);
    console.log("On Lender Selectetion =====>", lender);
    const { provider, partner, mode } = lender;
    setSelectedLender(partner);
    methodDefaultDetails.lender = partner;
    methodDefaultDetails.methodDetails.provider = provider;
    methodDefaultDetails.modeOfPayment = mode;
    updatePaymentPayload(methodDefaultDetails);
    // To Enable the Pay button
    Publisher.togglePayBtn(false);
    // the condition to check the selected lender is Zest
    if (partner === ZEST) {
      setShowConfirmation(true);
    }
    if (partner === KREDITBEE) {
      redirectToKb(lender);
    }
    if (partner === FLEX) {
      if (mode === FLEX) {
        proceedToPay();
      } else {
        redirectToFlex(lender);
      }
    }
  };

  // Logic to redirect user to Flex Lenders Screen
  const redirectToFlex = async (lender) => {
    f7.dialog.preloader("Please wait...");
    // const payload = {
    //   amount: totalOrderAmount,
    //   phone: paymentData?.mobileNumber,
    // };
    const apiRes = await Api.getFlexLenders(orderId);
    f7.dialog.close();
    if (apiRes.ok) {
      const resData = apiRes?.data?.data;
      if (resData.isEligible) {
        setFlexLenders(resData?.lenders);
        setShowFlexLenders(true);
      } else {
        setErrorMessage(resData.inEligibleReason || "Something went wrong");
      }
    } else {
      setErrorMessage(apiRes?.data.msg || "Something went wrong");
    }
  };

  // Logic to redirect user to Kreditbee onboarding page
  const redirectToKb = (lender) => {
    const { storeId, transactionId } = f7router.currentRoute.params;
    const { partner, mode } = lender;
    const objToStore = { lender: partner, modeOfPayment: mode, orderId, totalOrderAmount, mobileNumber: paymentData?.mobileNumber, customerEmail: paymentData?.customerEmail };
    localStorage.setItem("stored_payload", JSON.stringify(objToStore));
    localStorage.setItem("storeId", storeId);
    localStorage.setItem("transactionId", transactionId);
    f7router.navigate(`/${storeId}/lender-onboarding/${transactionId}`, { transition: "f7-cover" });
  };

  const onBoardNewMember = () => {
    Publisher.openZestWidget(true);
    setShowConfirmation(false);
  };
  const proceedToPay = () => {
    Publisher.applyAndPay(methodDefaultDetails.paidThrough);
    setShowConfirmation(false);
    setOnboardingPrompt(false);
  };

  const resetTransaction = () => {
    setOnboardingPrompt(false);
    setSelectedLender(null);
    Publisher.togglePayBtn(true);
  };
  useEffect(() => {
    event.on("onboardingPropmt", (e) => setOnboardingPrompt(e));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    event.on("showPayModeError", (e) => {
      setErrorMessage(e);
    });
    // eslint-disable-next-line
  }, []);
  return {
    selectedLender,
    onSelectLender,
    promptShown,
    onBoardNewMember,
    onboardingPrompt,
    resetTransaction,
    proceedToPay,
    errorMessage,
    setErrorMessage,
    flexLenders,
    showFlexLenders,
    redirectToFlex,
  };
};
export default useCardlessEMI;
