import { Card } from 'framework7-react'
import React from 'react'

export default function ShowNotification({ msg }) {
    return (
        <div style={{ color: "black" }}>
            <Card style={{ background: "white", color: "#000" }} content={msg} />
        </div>
    )
}
