import React, { useEffect, useState } from "react";
import "./style.css";
import LenderOfferCard from "../../components/LenderOffers/LenderOfferCard";
import { Button, Col, Navbar, NavLeft, NavRight, Page, PageContent, Row } from "framework7-react";
import useLenderOffers from "../../components/LenderOffers/hooks/useLenderOffer";
import formatter from "../../utilities/formatter";
import OTPVerification from "../../components/LenderOffers/OTPVerification";
import PageHeader from "../../components/LenderOffers/PageHeader";

const summaryDetailsArr = [
  { name: "Interest payable", prop: "interest_payable", filter: "currency" },
  { name: "Processing fee", prop: "fee", filter: "currency" },
  { name: "Discount", prop: null, filter: "currency" },
];
const LenderOffers = ({ f7router, pageData }) => {
  const { activeOfferID, setActiveOfferID, otpActive, otp, setOtp, onCloseOtpScreen, proceedWithPlan, onVerifyOtpOtp, otpErr, showOTPErr } = useLenderOffers(f7router);
  const [storedPageData, setPageData] = useState({});
  // const { orderId, paymentData, transactionAmount, logoUrl } = pageData;

  useEffect(() => {
    if (pageData) {
      localStorage.setItem("lenderOffers_store", JSON.stringify(pageData));
      setPageData(pageData);
    } else {
      const storeData = localStorage.getItem("lenderOffers_store");
      if (storeData) {
        setPageData(JSON.parse(storeData));
      }
    }
  }, [pageData]);

  // console.log("storedPageData", storedPageData);
  const { paymentData, logoUrl, transactionAmount } = storedPageData;
  return (
    <Page className="gopay__lender_page">
      <PageHeader f7router={f7router} />
      <PageContent style={{ paddingTop: 0, backgroundColor: "white" }}>
        <div className="gopay_lender_offers">
          <Navbar className="gopay_order_amount_header" noHairline noShadow>
            <NavLeft>
              <h5>Order Amount:</h5>
            </NavLeft>
            <NavRight>
              <h4>{formatter(transactionAmount, "currency")}</h4>
            </NavRight>
          </Navbar>
          {paymentData && paymentData?.offers && paymentData?.offers.length > 0
            ? paymentData?.offers.map((offer) => (
                <div key={offer.scheme_id} className="gopay__shadow_card">
                  {offer.scheme_id ? <LenderOfferCard offer={offer} onSelectOffer={(id) => setActiveOfferID(id)} opened={Boolean(activeOfferID === offer?.scheme_id)} /> : null}
                  {/* Summary */}
                  {activeOfferID === offer?.scheme_id ? (
                    <div className="gopay__summary_card card">
                      <div className="card-content">
                        {/* Header */}
                        <Row className="gopay__summary_header">
                          <Col>
                            <h3>Summary</h3>
                          </Col>
                        </Row>
                        {/* Details */}
                        <Row className="gopay__summary_items">
                          <Col>Order Amount</Col>
                          <Col className="value__right">{formatter(transactionAmount, "currency")}</Col>
                        </Row>
                        {summaryDetailsArr.map((item) => (
                          <Row key={item.name} className="gopay__summary_items">
                            <Col>{item.name}</Col>
                            <Col className="value__right">{formatter(offer[item.prop], item.filter)}</Col>
                          </Row>
                        ))}
                        <Row className="gopay__summary_items total">
                          <Col>Total amount payable</Col>
                          <Col className="value__right">{formatter(offer?.amount * offer?.tenure + offer?.fee, "currency")}</Col>
                        </Row>
                      </div>

                      <div className="flex space-between items-center mt-10">
                        <Button className="gopay__color_green no-transform" onClick={() => setActiveOfferID(null)}>
                          {/* <Icon f7="arrow_left" className="mr-2" size={18} /> */}
                          Change Plan?
                        </Button>
                        <div />
                        <Button fill className="gopay_proceed_btn" onClick={() => proceedWithPlan(offer, storedPageData)}>
                          Proceed
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))
            : null}
        </div>
      </PageContent>
      <OTPVerification
        logoUrl={logoUrl}
        f7router={f7router}
        open={otpActive}
        onDismiss={onCloseOtpScreen}
        setValue={(e) => {
          setOtp(e);
          showOTPErr(null);
        }}
        value={otp}
        otpErr={otpErr}
        onSumbit={() => onVerifyOtpOtp(storedPageData)}
      />
    </Page>
  );
};
export default LenderOffers;
