import { useState } from "react"
import {
  BACKSPACE,
  LEFT_ARROW,
  RIGHT_ARROW,
  DELETE,
  SPACEBAR,
} from "./otp-input.utils"

const useOtpInput = (props) => {
  const [activeInput, setActiveInput] = useState(0)
  const {
    numInputs = 4,
    onChange = (otp) => console.log(otp),
    isDisabled = false,
    value = "",
    placeholder,
    isInputNum,
  } = props

  const getOtpValue = () => (value ? value.toString().split("") : [])

  const getPlaceholderValue = () => {
    if (typeof placeholder === "string") {
      if (placeholder.length === numInputs) {
        return placeholder
      }

      if (placeholder.length > 0) {
        console.error(
          "Length of the placeholder should be equal to the number of inputs."
        )
      }
    }
  }

  // Helper to return OTP from input
  const handleOtpChange = (otp) => {
    const otpValue = otp.join("")
    onChange(otpValue)
  }

  const isInputValueValid = (value) => {
    const isTypeValid = isInputNum
      ? !isNaN(parseInt(value, 10))
      : typeof value === "string"
    return isTypeValid && value.trim().length === 1
  }

  // Focus on input by index
  const focusInput = (input) => {
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0)
    setActiveInput(activeInput)
  }

  // Focus on next input
  const focusNextInput = () => {
    focusInput(activeInput + 1)
  }

  // Focus on previous input
  const focusPrevInput = () => {
    focusInput(activeInput - 1)
  }

  // Change OTP value at focused input
  const changeCodeAtFocus = (value) => {
    const otp = getOtpValue()
    otp[activeInput] = value[0]

    handleOtpChange(otp)
  }

  // Handle pasted OTP
  const handleOnPaste = (e) => {
    e.preventDefault()
    if (isDisabled) {
      return
    }

    const otp = getOtpValue()
    let nextActiveInput = activeInput

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData("text/plain")
      .slice(0, numInputs - activeInput)
      .split("")
    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift()
        nextActiveInput++
      }
    }

    setActiveInput(nextActiveInput)
  }

  const handleOnChange = (e) => {
    const { value } = e.target
    if (isInputValueValid(value)) {
      changeCodeAtFocus(value)
    }
  }

  // Handle cases of backspace, delete, left arrow, right arrow, space
  const handleOnKeyDown = (e) => {
    if (e.keyCode === BACKSPACE || e.key === "Backspace") {
      e.preventDefault()
      changeCodeAtFocus("")
      focusPrevInput()
    } else if (e.keyCode === DELETE || e.key === "Delete") {
      e.preventDefault()
      changeCodeAtFocus("")
    } else if (e.keyCode === LEFT_ARROW || e.key === "ArrowLeft") {
      e.preventDefault()
      focusPrevInput()
    } else if (e.keyCode === RIGHT_ARROW || e.key === "ArrowRight") {
      e.preventDefault()
      focusNextInput()
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === " " ||
      e.key === "Spacebar" ||
      e.key === "Space"
    ) {
      e.preventDefault()
    }
  }

  // The content may not have changed, but some input took place hence change the focus
  const handleOnInput = (e) => {
    if (isInputValueValid(e.target.value)) {
      focusNextInput()
    } else {
      // This is a workaround for dealing with keyCode "229 Unidentified" on Android.

      if (!isInputNum) {
        const { nativeEvent } = e

        if (
          nativeEvent.data === null &&
          nativeEvent.inputType === "deleteContentBackward"
        ) {
          e.preventDefault()
          changeCodeAtFocus("")
          focusPrevInput()
        }
      }
    }
  }

  return {
    getOtpValue,
    getPlaceholderValue,
    activeInput,
    handleOnChange,
    handleOnKeyDown,
    handleOnInput,
    handleOnPaste,
    setActiveInput,
  }
}

export default useOtpInput