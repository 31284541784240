import React from "react";
import "./payment.css";
import { MARCHENT_ID, PG_ID, ENV, WIDGET_TYPE } from "../../utilities/constants";
import Publisher from "../../utilities/publisher";


const ZestWidget = () => {
  const closeZestWidget = () => {
    Publisher.openZestWidget(false)
    Publisher.onboardingPropmt(true)
  };

  React.useEffect(() => {
    async function loadZest() {
      var zestMoneyWidgetElement = document.getElementById("zestmoney-widget-container");
      var zestmoneyWidgetControl = document.getElementById("zestmoney-widget-control");
      const zest_price = parseFloat("3000") / 100;
      var zestMoneyWidget = window.ZestMoneyWidget(zestMoneyWidgetElement, {
        merchantId: MARCHENT_ID,
        environment: ENV,
        basketAmount: zest_price || 0,
        downpaymentAmount: null,
        paymentGatewayId: PG_ID,
        widgetType: WIDGET_TYPE,
      });
      let displayHtml = await zestMoneyWidget.getDisplayHTML();
      if (displayHtml) {
        zestmoneyWidgetControl.innerHTML = displayHtml;
        var zestmoneyWidgetTrigger = document.getElementById("zestmoney-widget-trigger");
        if (zestmoneyWidgetTrigger) {
          zestmoneyWidgetTrigger.addEventListener("click", function () {
            zestMoneyWidget.open();
          });
        }
        zestmoneyWidgetTrigger.click();

        let closeBtn = document.querySelector(".zestmoney-widget-close");
        if (closeBtn) {
          closeBtn.addEventListener("touchend", closeZestWidget);
          closeBtn.addEventListener("click", closeZestWidget);
        }
      }
    }
    loadZest();
  }, []);
  return (
    <>
      <div id="zestmoney-widget-control" />
      <div id="zestmoney-overlay" />
      <div id="zestmoney-widget-container" className="zestmoney-widget-wrapper hide zestmoney-widget" style={{ display: "none" }} />
    </>
  );
};

export default ZestWidget;
