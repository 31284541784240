import React, { useRef } from "react";
import { PageContent, Button, Block } from "framework7-react";
import OtpInput from "../../components/OtpInput";

const VerifyOtp = ({ mobileNumber, otp, setOtp, verifyOTP, error, sendOTP, loading }) => {
  const nextButtonRef = useRef(null);
  const resendOTP = () => {
    setOtp("");
    sendOTP();
  };

  const handleVerifyOTPSubmit = (e) => {
    e.preventDefault();
    nextButtonRef.current.el.focus(); // changing the focus to hide the keyboard
    verifyOTP();
  };

  return (
    <>
      <form onSubmit={handleVerifyOTPSubmit}>
        <Block className="verify-otp-wrapper">
          <div className="otp-title">OTP Verification</div>
          <div className="description">
            <span className="opacity6">Enter the OTP you received to </span>
            <span>{mobileNumber}</span>
          </div>
        </Block>
        <PageContent className="content-wrapper center-content">
          <OtpInput value={otp} onChange={setOtp} numInputs={4} isInputNum={true} />
          <div className="resend-otp" onClick={resendOTP}>
            Resend OTP
          </div>
          <p className="description error">{error}</p>
        </PageContent>
        <Block className="button-wrapper">
          <Button ref={nextButtonRef} type="submit" onClick={verifyOTP} color="green" fill large disabled={otp.length < 4 || loading} preloader loading={loading}>
            Next
          </Button>
        </Block>
      </form>
    </>
  );
};

export default VerifyOtp;
