import React from "react";
import { Button } from "framework7-react";
import transactionFailed from "../../assets/transaction_failed.png";
import "./payment_failed.css";
import copyIcon from "../../assets/copy_black_24dp.svg";

export default function PaymentFailed({ data, route, handleOnCopyClick }) {
  return (
    <div className="page pg-bgcolor page-failed">
      <div className="failed_card">
        <div className="card-header"></div>
        <div className="card_content">
          <img className="card-img" alt="party" src={transactionFailed} />
          <h3 className="payment_name">Transaction Failed</h3>
          {data?.failureReason ? (
            <div className="failure_message">{data?.failureReason}</div>
          ) : (
            <div className="failure_message">Your Payment worth ₹ {data.totalOrderAmount} failed. Any amount deducted will be refunded within 3-5 working days.</div>
          )}

          <p className="transaction_id_success">
            Your Payment Id is &nbsp;<b style={{ backgroundColor: "#e1e4e1", padding: "2%", borderRadius: "5px" }}>#{data.orderId}</b>{" "}
            <img src={copyIcon} width="17" height="17" style={{ verticalAlign: "bottom" }} alt="copy" onClick={handleOnCopyClick} />
          </p>

          <div className="failure_message" style={{ marginTop: 10 }}>
            ( For more information contact <b>gopay_support@arzooo.com</b> )
          </div>
          <div className="btm_drop"></div>
        </div>

        <Button fill color="green" onClick={() => route.f7router.navigate(`/${route.storeId}/pay/${route.transactionId}`)}>
          {" "}
          <span>Retry</span>
        </Button>
      </div>
    </div>
  );
}
