import React from "react";
import Api from "../../services/api.service";

const useHomePageInit = (f7router) => {
  const [storeData, setStoreData] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [banners, setBanners] = React.useState([]);
  const retailerId = React.useRef(null);

  React.useEffect(() => {
    // f7router.clearPreviousHistory();
    const RETAILER_ID = f7router.currentRoute.params.storeId;
    const fetchBanners = async (rid) => {
      const response = await Api.fetchHomeBanners({}, rid);
      setBanners(response.data.data || []);
    };
    const fetchCategoryData = async (rid) => {
      const response = await Api.fetchCategories({}, rid);
      setCategories(response.data.categoryDetails || []);
    };

    const fetchStoreData = async (rid) => {
      const response = await Api.storeDetails(rid);
      if (response.data.data) {
        setStoreData(response.data.data);
        fetchCategoryData(RETAILER_ID);
        fetchBanners(RETAILER_ID);
        return;
      }
    };
    retailerId.current = RETAILER_ID;
    fetchStoreData(RETAILER_ID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { categories, banners, storeData, showHomepage: storeData && categories.length > 0 && banners.length > 0, retailerId: retailerId.current };
};

export default useHomePageInit;
