import "./page.css";
import { Page } from "framework7-react";
import PageLoader from "../components/pageLoader";
import bg from "../assets/bg.png";
import protiumBg from "../assets/protium_bg.png";
const PageWithBg = ({ style = {}, isLoading = false, bgImage = false, ...props }) => {
  return (
    <Page {...props} style={{ ...style, backgroundImage: !bgImage ? `url(${bg})` : `url(${protiumBg})`, backgroundSize: "cover" }} noSwipeback>
      {isLoading && <PageLoader />}
      {!isLoading && props.children}
    </Page>
  );
};

export default PageWithBg;
