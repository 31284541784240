import React from "react";
import Page from "../../container/PageWithBg";
import "./protiumoffer.css";
import useProtium from "./useProtium";
import ProtiumOffer from "./ProtiumOffer";
import NoOffer from "./NoOffer";

export default function ProtiumOffers({ f7router }) {
  const { loading, isApproved, protiumOfferData, selectedTenor, handleSelectedTenor, updateProtium, timeLeft } = useProtium(f7router);
  return (
    <Page isLoading={loading} bgImage={true} style={{ backgroundColor: "#FFFFFF", color: "#283B75" }}>
      {isApproved ? (
        <ProtiumOffer protiumOfferData={protiumOfferData} selectedTenor={selectedTenor} handleSelectedTenor={handleSelectedTenor} updateProtium={updateProtium} />
      ) : (
        <NoOffer timeLeft={timeLeft} />
      )}
    </Page>
  );
}
