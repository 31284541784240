import { Button, Icon, Popup, f7 } from "framework7-react";
import React, { useEffect, useState } from "react";
import PageHeader from "../LenderOffers/PageHeader";
import "../../screens/LenderOffers/style.css";
import formatter from "../../utilities/formatter";
import Publisher from "../../utilities/publisher";

const SubLenders = ({ open, onPopupClose = () => {}, lenders = [] }) => {
  const [selectedLender, setSelectedLender] = useState(null);

  const onSelectLender = (lender) => {
    setSelectedLender((prevState) => (prevState?.name === lender.name ? null : lender));
  };

  useEffect(() => {
    setSelectedLender(lenders[0]);
    f7.dialog.close();
  }, [lenders]);

  const proceed = () => {
    Publisher.applyAndPay(selectedLender?.paidThrough, selectedLender?.rzpBankCode, 'RAZORPAY');
  };

  return (
    <Popup className="demo-popup" opened={open} onPopupClose={onPopupClose}>
      <PageHeader showBack={false} />
      <div style={{ paddingTop: 0, overflow: "auto", height: "calc(100vh - 100px)", paddingBottom: 80 }}>
        <div className="px-15">
          <h3 className="mb-0">Select Lender</h3>
          {lenders.length > 0 ? (
            <div className="gopay_grid_layout">
              {lenders.map((lender) => (
                <div className={`gopay__sub_lenders ${selectedLender?.name === lender.name ? "selected_lender" : ""}`} key={lender.name} onClick={() => onSelectLender(lender)}>
                  {selectedLender?.name === lender.name ? (
                    <Icon size={25} icon="arz__selected_lender" f7="checkmark_circle_fill" color="green" />
                  ) : (
                    <Icon size={25} icon="arz__selected_lender" f7="circle" />
                  )}
                  <img slot="media" src={lender.logo} width={50} height={50} alt={lender.name} style={{ verticalAlign: "middle", objectFit: "scale-down" }} />
                  <p style={{ color: "#000", margin: "5px 0 0" }}>{lender.name}</p>
                </div>
              ))}
            </div>
          ) : (
            <p>No Lender Available</p>
          )}
        </div>

        {/* EMI History */}
        {selectedLender && selectedLender?.name && (
          <div className="">
            <div className="data-table table-emilist gopay__lender_emi_list">
              <div className="flex px-15 items-center">
                <img slot="media" src={selectedLender?.logo} width={70} height={60} alt={selectedLender?.name} style={{ verticalAlign: "middle", objectFit: "scale-down" }} />
                <p className="gopay_scheme_info">EMI tenure selection will happen on the next page.</p>
              </div>
              <table>
                <thead>
                  <tr>
                    <th className="label-cell">Months</th>
                    {/* <th className="label-cell numeric-cell">Interest Rate</th> */}
                    <th className="label-cell numeric-cell">EMI</th>
                    <th className="label-cell">Total Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedLender?.emiPlans.map((plan) => {
                    return (
                      <tr key={plan?.emiPlanId}>
                        <td className="label-cell">{plan?.tenure}</td>
                        {/* <td className="label-cell">{plan?.interestRate}%</td> */}
                        <td className="label-cell numeric-cell text-right">
                          <b style={{ fontWeight: 500 }}>{formatter(plan?.emi, "currency")}</b>
                        </td>
                        <td className="label-cell text-right">{formatter(plan?.totalAmountPayableToBank, "currency")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* Proceed with Payment */}
            <div className="px-15">
              <Button large fill onClick={proceed} color="green" className="mt-15">
                Proceed
              </Button>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default SubLenders;
