export const CARDLESS_EMI = "CARDLESS_EMI";
export const CREDIT_CARD_EMI = "CREDIT_CARD_EMI";
export const UPI = "UPI";
export const CREDIT_CARD = "CREDIT_CARD";
export const DEBIT_CARD = "DEBIT_CARD";
export const NET_BANKING = "NET_BANKING";
export const DEBIT_CARD_EMI = "DEBIT_CARD_EMI";
export const CARD_EMI = "CARD_EMI";
export const CARD = "CARD";

// UPI Apps
export const GPAY = "GPay";

export const INVALIDUPI = "Please enter a valid UPI/VPA ID";
export const SOME_ERROR = "Something went wrong";

export const AXIO = "AXIO";
export const ZEST = "ZEST";
export const KREDITBEE = "KREDITBEE";
export const FLEX = "FLEXMONEY";

export const MARCHENT_ID = process.env.REACT_APP_ZEST_MARCHENT_ID;
export const PG_ID = process.env.REACT_APP_ZEST_PG_ID;
export const ENV = process.env.REACT_APP_ZEST_ENV;
export const WIDGET_TYPE = process.env.REACT_APP_ZEST_WIDGET_TYPE;
export const WAIT_TIME = 300;
