// import config from "./config";

const paymentKeys = {
  9110870398: {
    type: "goretail",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GORETAIL_RAZORPAY_KEY,
  },
  9690222869: {
    type: "paywall",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GORETAIL_RAZORPAY_KEY,
  },
  9690226669: {
    type: "GoCare",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GORETAIL_RAZORPAY_KEY,
  },
  8436484022: {
    type: "gostor",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GOSTOR_RAZORPAY_KEY,
  },
  5847325275: {
    type: "gostor_driver",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GOSTOR_RAZORPAY_KEY,
  },
  7060863011: {
    type: "orbit",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GORETAIL_RAZORPAY_KEY,
  },
  9690222812: {
    type: "arzooo",
    account_id: false,
    razorpayKey: process.env.REACT_APP_GORETAIL_RAZORPAY_KEY,
  },
  9304180559: {
    type: "arzoooProSubvented",
    account_id: false,
    razorpayKey: process.env.REACT_APP_ARZ_SUBVENTION_RZP_KEY
  },
  7647001342: {
    type: "arzoooProSubvented_1",
    account_id: false,
    razorpayKey: process.env.REACT_APP_ARZ_SUBVENTION_1_RZP_KEY
  },
  7691683571: {
    type: "arzoooProSubvented_2",
    account_id: false,
    razorpayKey: process.env.REACT_APP_ARZ_SUBVENTION_2_RZP_KEY
  }
};

export default paymentKeys;
