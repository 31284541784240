import "./categoryGrid.css";
import React from "react";
import { Block, Col } from "framework7-react";

const BUTTONS_PER_ROW = 3;
const getBlankRenderArray = (totalCategories) => {
  const modulus = totalCategories % BUTTONS_PER_ROW;
  const blankArray = [];
  if (modulus) {
    const arrayLength = BUTTONS_PER_ROW - modulus;
    for (let i = 0; i < arrayLength; i++) {
      blankArray.push(`idx_${i}`);
    }
  }
  return blankArray;
};

const CategoryGrid = ({ categories, f7router, getClassName, height }) => {
  const onTileClick = (id) => {
    f7router.navigate(`offer/category/${id}`);
  };

  const blanks = getBlankRenderArray(categories.length);
  const size = (window.innerWidth - 46) / 3;

  return (
    <Block
      className="category-container"
      style={{ height: `calc(100vh - ${height}px)`, overflowY: 'scroll', paddingBottom: 50 }}
    >
      {categories.map((cat) => (
        <div
          onClick={() => onTileClick(cat.category_id)}
          key={cat.category_id}
          className="category-holder"
          style={{ width: size, minHeight: size + 10 }}
        >
          <div className="category-image-holder">
            <img src={cat.img_src} alt={cat.name} className="category-image" />
          </div>
          <div className="category-title">{cat.name}</div>
        </div>
      ))}
      {blanks.map((i) => (
        <Col className="transparent-category-grid" key={i} width="33" />
      ))}
    </Block>
  )
};

export default CategoryGrid;
