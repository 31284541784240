import React from "react";

export default function NoOffer({ timeLeft }) {
  return (
    <div>
      <div style={{ padding: "40px", textAlign: "center" }}>
        <h2>Sorry!! You are not eligible for a No Cost EMI</h2>
      </div>
      <div style={{ padding: "40px", textAlign: "center" }}>
        <h3>Redirecting in {timeLeft} seconds...</h3>
      </div>
    </div>
  );
}
