import { useEffect, useState, useRef } from "react";
import Api from "../../../services/api.service";
// import config from "../../../config";
import { RAZOR_PAY_SCRIPT } from "../../../constants/values";
import { f7 } from "framework7-react";
import { paymentValidation } from "../../../utilities/paymentValidation";
import paymentKeys from "../../../paymentkeys";
import { MIN_OFFER_AMOUNT } from "../../../constants/values";
import verifyVPAUpi from "../../../utilities/verifyVPAUpi";
import { UPI, INVALIDUPI, SOME_ERROR, CARDLESS_EMI, KREDITBEE, FLEX } from "../../../utilities/constants"; // KREDITBEE
import Publisher from "../../../utilities/publisher";
import { event } from "../../../utilities/subscriber.events";
import config from "../../../config";
import { useFeature } from "@growthbook/growthbook-react";
import useHandleFlexPayment from "../../../hooks/useHandleFlexPayment";
// Example of razorpay implementation is here https://www.w3schools.com/code/tryit.asp?filename=GSR3DSTA0YGD
// https://razorpay.com/docs/payments/payments/test-card-upi-details/

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const useAndLoadRazorPay = () => {
  useEffect(() => {
    const loadRazorPay = async () => {
      const res = await loadScript(RAZOR_PAY_SCRIPT);
      if (!res) {
        console.log("RazorPay not loaded");
      }
    };
    loadRazorPay();
  }, []);
};

const offerApplicable = ["CREDIT_CARD"];

export const usePaymentMethod = (paymentData, orderId, paymentId, totalOrderAmount, selectedOffer, f7router, paymentSheet, offerData) => {
  useAndLoadRazorPay();
  const { startFlexPayment } = useHandleFlexPayment(f7router);
  // const razorPayWebview = useFeature("gopay.open_razorpay_screen").on;
  const arzoooCredit = useFeature("gopay.arzooo_credit").on;
  // const upiIntentEnabled = useFeature("gopay_upi_intent_enabled").on;
  const [loading, setLoading] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const { RAZORPAY = {}, PROTIUM = {}, mobileNumber, customerEmail, instrumentData = {}, RUPIFI = {} } = paymentData;
  const razorPayPaymentTypes = Object.keys(RAZORPAY).filter((method) => !!RAZORPAY[method]) || [];
  const protiumPaymentTypes = Object.keys(PROTIUM).filter((method) => !!PROTIUM[method]) || [];
  const rupifiType = Object.keys(RUPIFI).filter((method) => !!RUPIFI[method]) || [];
  const [rupifiEligibleValue, setRupifiEligibleValue] = useState(0);
  const paymentPaylods = useRef({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const getFinalPaymentPayload = (method) => {
    let payMethod = selectedPaymentMethod ? selectedPaymentMethod : method;
    return paymentPaylods.current[payMethod];
  };
  const [errorMsg, setErrorMsg] = useState(null);
  const [offerAmount, setOfferAmount] = useState(null);
  const [protiumNotificationStatus, setProtiumNotificationStatus] = useState(false);
  // eslint-disable-next-line
  const [isShopseVerified, setIsShopseVerified] = useState(false);
  const [minimumAmountSheet, setMinimumAmountSheet] = useState(false);
  const [minOfferPrice, setMinOfferPrice] = useState(null);
  const [mop, setMOP] = useState(null);
  const [isCardOpen, setIcardOpen] = useState(false);
  const [disabledPayBtn, setPayBtnDisabled] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  // const instacredEnabled = useFeature("gopay.enable-instacred").on;
  const handleCallBack = (error) => {
    if (error === true) {
      setErrorMsg(null);
    } else if (error === false) {
      setErrorMsg(null);
    } else {
      setErrorMsg(error);
    }
  };

  useEffect(() => {
    if (arzoooCredit) {
      if (RUPIFI["BNPL"]) {
        if (RUPIFI["BNPL"] > totalOrderAmount) {
          setRupifiEligibleValue(RUPIFI["BNPL"]);
        }
      }
    }
    // eslint-disable-next-line
  }, [arzoooCredit]);

  useEffect(() => {
    // when offer is selected do something
    if (selectedOffer) {
      setSelectedPaymentMethod(selectedOffer.paidThrough);
    } else {
      setSelectedPaymentMethod(null);
    }
    setOfferAmount(selectedOffer.discountAmount);
  }, [selectedOffer, paymentSheet]);

  const handleCloseProtiumNotication = () => {
    setProtiumNotificationStatus(false);
  };

  const updatePaymentPayload = (methodDetails, payload = {}) => {
    if (selectedOffer && selectedOffer?.type !== methodDetails?.paidThrough) {
      setShowNotice(true);
    }
    // methodDetails contains the payment method details eg: cc number, expiry date, cvv etc
    const defaults = {
      mobileNumber,
      customerEmail,
      orderId,
      paymentId,
      totalOrderAmount,
      useWallet: false,
      usedWalletBalance: 0,
      modeOfPayment: "RAZORPAY",
    };
    const finalPayload = { ...paymentPaylods.current, [methodDetails.paidThrough]: { ...defaults, ...methodDetails, ...payload } };
    paymentPaylods.current = finalPayload;
    setMOP(methodDetails.modeOfPayment);
  };

  const handlePaymentData = (payload) => {
    // debugger;
    const isGpay = payload?.gpay ? true : false;
    if (payload.method === "netbanking") {
      delete payload.bankName;
      return { payload };
    } else if (payload.method === "upi") {
      delete payload?.upi?.valid;
      if (isGpay) {
        delete payload.gpay;
      }

      return { payload, gpay: isGpay };
    }
    return { payload };
  };

  //4111111111111111
  const handleRazorPayPayment = ({ paymentData }, payMethod, upiAppName, provider = null) => {
    const { callBackUrl: callback_url, customerEmail: email, mobileNumber: contact, razorpayId: account_id, id: order_id, amount, offer_id } = paymentData;
    const { paymentPartner, selected, ...remainingMethodDetails } = getFinalPaymentPayload(payMethod).methodDetails;
    const { storeId, transactionId } = f7router.currentRoute.params;
    const paymentConfigDetails = paymentKeys[storeId];
    let finalPayloadSample = {
      ...remainingMethodDetails,
      currency: "INR",
      ...(remainingMethodDetails.method === "upi" ? { callback_url } : {}),
      email,
      contact,
      amount,
      order_id,
      account_id,
    };
    if (f7router?.currentRoute?.query?.callback_url) {
      finalPayloadSample.callback_url = `${config.apiUrl}transactions/v2/payment/razorpay/callback_url/${storeId}/${transactionId}?cb=${f7router?.currentRoute?.query?.callback_url}`;
    }

    // console.log(finalPayloadSample);

    let finalPayLoad;
    if (paymentConfigDetails?.account_id) {
      finalPayLoad = finalPayloadSample;
    } else {
      delete finalPayloadSample.account_id;
      finalPayLoad = finalPayloadSample;
    }
    if (provider) {
      finalPayLoad.provider = provider;
    }
    if (offer_id) finalPayLoad.offer_id = offer_id;

    // finalPayLoad = paymentConfigDetails?.account_id ? finalPayLoad : delete finalPayLoad.account_id;

    // const configKey = account_id === "acc_I2PlJkU6JUshzl" ? "rzp_live_t8TpXBLV9ZnwlX" : config.razorPayKey;

    if (upiAppName) {
      const eventObj = { upiAppName, finalPayLoad, storeId, transactionId };
      Publisher.openUpiApp(eventObj);
      // payNow(upiAppName, finalPayLoad, storeId, transactionId);
      return;
    }

    const configKey = paymentConfigDetails.razorpayKey;

    let keySettings = {
      key: configKey,
    };

    if (f7router?.currentRoute?.query?.callback_url) {
      keySettings.redirect = true;
    }

    const { payload, gpay } = handlePaymentData(finalPayLoad);
    let options = {};

    if (gpay) {
      options["gpay"] = true;
    }
    if (provider || selectedPaymentMethod === CARDLESS_EMI) {
      setIsVerifying(true);
    }
    const razorPay = new window.Razorpay(keySettings);
    // Logic to open RazorPay in Arzooo App
    const isOnApp = localStorage.isOnArzooo;
    // console.log("is on App", isOnApp, 'cb', f7router?.currentRoute?.query?.callback_url, 'payload', payload, 'options', options);
    // console.log("razorpay enabled razorPayWebview", razorPayWebview, finalPayLoad);
    if (isOnApp === "true" && f7router?.currentRoute?.query?.callback_url) {
      // console.log("inside if condition", finalPayLoad);
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "OPEN_RAZORPAY_SCREEN",
          data: { payload, options },
        })
      );
      return;
    }
    // debugger;
    // console.log("[final] payload -> ", payload, gpay, options);
    f7.dialog.close();
    const isAndriodChrome = f7.device.android && f7.device.androidChrome;
    if (isAndriodChrome) {
      // debugger;
      razorPay.createPayment(payload, options);
    } else {
      setTimeout(() => {
        f7.dialog.confirm("Are you sure?", "This will initiate your transaction.", (e) => {
          razorPay.createPayment(payload, options);
          setLoading(true);
        });
      }, 200);
    }

    // console.log("[final] payload::2 -> ", payload, gpay, options);
    razorPay.on("payment.success", async function (initiatePaymentResponse) {
      setIsVerifying(false);
      await Api.paymentEvents(
        {
          event_type: "SUCCESS",
          data: initiatePaymentResponse,
        },
        `/${transactionId}`
      );
      setLoading(false);
      window.location = `/${storeId}/order-status/${transactionId}`;
    });

    razorPay.on("payment.error", async function (initiatePaymentResponse) {
      setIsVerifying(false);
      await Api.paymentEvents(
        {
          event_type: "FAILED",
          data: initiatePaymentResponse,
        },
        `/${transactionId}`
      );

      setLoading(false);
      console.log("error reason from error -> ", initiatePaymentResponse);
      window.location = `/${storeId}/order-status/${transactionId}`;
    });

    razorPay.on("payment.failed", async function (initiatePaymentResponse) {
      setIsVerifying(false);
      await Api.paymentEvents(
        {
          event_type: "FAILED",
          data: initiatePaymentResponse,
        },
        `/${transactionId}`
      );

      setLoading(false);
      console.log("error reason from failed -> ", initiatePaymentResponse);
      window.location = `/${storeId}/order-status/${transactionId}`;
    });
  };

  const handleShopsePayment = (data) => {
    window.location.href = data.paymentData.paymentRedirectUrl;
  };

  const showUpiError = (msg) => {
    f7.dialog.close();
    setTimeout(() => Publisher.upi(msg), 200);
    setLoading(false);
  };

  const verifyUPI = async (payload) => {
    let VPA = payload?.methodDetails?.upi?.vpa;
    setLoading(true);
    f7.dialog.preloader("Verifing...");
    let res = await verifyVPAUpi(VPA);
    try {
      if (res.success) {
        updatePaymentPayload({
          methodDetails: { method: "upi", upi: { vpa: VPA, flow: "collect", valid: true } },
          paidThrough: UPI,
        });
        return true;
      } else if (!res.success) {
        showUpiError(INVALIDUPI);
        return false;
      }
    } catch (err) {
      showUpiError(SOME_ERROR);
      return false;
    }
  };

  // Proceed the Final Payment
  const processPayment = async (handleErrorMsgCallBack, payMethod, upiAppName = null, provider = null, modeOfPayment = null) => {
    const _paymentPayload = getFinalPaymentPayload(payMethod);
    if (!_paymentPayload) return;
    console.log("_paymentPayload", _paymentPayload);
    // if the payment type is UPI it will verify the UPI first and then proceed to pay
    if (_paymentPayload?.paidThrough === UPI && !upiAppName) {
      let verified = await verifyUPI(_paymentPayload);
      if (!verified) return;
      f7.dialog.close();
    }
    const { totalOrderAmount: amount } = _paymentPayload;
    const { methodDetails, ...initiatePaymentPayload } = _paymentPayload;
    initiatePaymentPayload["offerId"] = selectedOffer.offerId;
    if (modeOfPayment) {
      initiatePaymentPayload["modeOfPayment"] = modeOfPayment;
    }
    let validaionParms = { method: methodDetails, paidThrough: initiatePaymentPayload.paidThrough };
    const validation = paymentValidation(validaionParms, upiAppName);
    if (validation === true) {
      handleErrorMsgCallBack(validation); // to clear all error msg in ui
      f7.dialog.preloader("Please wait...");
      const resp = await Api.startPaymentProcess(initiatePaymentPayload);
      if (resp?.data?.status === "error") {
        f7.dialog.close();
        setTimeout(() => {
          setLoading(false);
          if (selectedPaymentMethod === CARDLESS_EMI) {
            Publisher.showPayModeError(resp.data.msg || "Please Select lender for Cardless Emi");
          } else {
            f7router.app.dialog.alert(resp.data.msg || "Unable to initate payment, please try again.", (e) => {
              setSelectedPaymentMethod("");
            });
          }
        }, 500);
        return;
      }
      if (_paymentPayload.modeOfPayment === "PROTIUM") {
        if (resp.data?.data?.existing_customer) {
          f7.dialog.close();
          if (_paymentPayload.modeOfPayment === "PROTIUM" && !isShopseVerified) {
            setProtiumNotificationStatus(true);
            return;
          }
          handleShopsePayment({ ...resp.data, amount });
        } else {
          window.location = resp.data.data.customer_journey_url;
          return false;
        }
      }

      if (_paymentPayload.modeOfPayment === "RUPIFI") {
        if (resp.data.status === "success") {
          window.location.href = resp.data.paymentData.paymentUrl;
        }
      }
      // logic to open a page to show the tenures.
      if (_paymentPayload.modeOfPayment === KREDITBEE) {
        showLenderOffers(resp?.data);
        return;
      }
      if (_paymentPayload.modeOfPayment === FLEX) {
        startFlexPayment(resp?.data);
        return;
      }
      if (!!resp.data?.paymentData.razorpayId) {
        handleRazorPayPayment({ ...resp.data, amount }, payMethod, upiAppName, provider);
        return;
      }
      if (!!resp?.data?.paymentData?.transaction?.url) {
        const URL = resp?.data?.paymentData?.transaction?.url;
        window.open(URL) || window.location.assign(URL);
      }
    } else {
      handleErrorMsgCallBack(validation); // on error msg
    }
  };

  const showLenderOffers = async (resData = {}) => {
    if (!resData) return;
    localStorage.removeItem("lenderOffers_store");
    const { orderId, paymentData, transactionAmount, paymentId } = resData;
    const { storeId } = f7router.currentRoute.params;
    const pageData = { orderId, paymentData, transactionAmount, paymentId, logoUrl: "https://gopay-prod-static.gostor.com/icons/banks/kreditbee" };
    if (paymentData?.is_eligible) {
      f7router.navigate(`/${storeId}/lender-offers/${orderId}`, {
        props: { pageData },
        transition: "f7-cover",
      });
    } else {
      f7router.navigate(`/${storeId}/order-status/${paymentId}`, { transition: "f7-cover" });
    }
  };

  const handlePaymentModeSelectionLogic = (mode) => {
    if (selectedOffer.discountAmount > 0 && offerApplicable.includes(mode)) {
      setSelectedPaymentMethod(mode);
      setOfferAmount(selectedOffer.discountAmount);
    } else {
      const offerMissMatch = selectedOffer && selectedOffer.paidThrough !== mode;
      if (offerMissMatch) {
        setShowNotice(mode);
      } else {
        setSelectedPaymentMethod(mode);
      }
    }
  };

  const handleOtherStoreOrderValues = () => {
    setMinimumAmountSheet(true);
    setMinOfferPrice(MIN_OFFER_AMOUNT);
    return false;
  };

  const handlePaymentModeSelection = (mode, notifyCardLessEMI = false) => {
    setPayBtnDisabled(false);
    if (notifyCardLessEMI && (mode === "CARDLESS_EMI" || mode === "CREDIT_CARD_EMI")) {
      handleOtherStoreOrderValues();
    } else {
      const offer = offerData.filter((item) => item.paidThrough === mode);
      if (offer.length > 0) {
        if (totalOrderAmount >= offer[0].minOfferAmount) {
          handlePaymentModeSelectionLogic(mode);
        } else {
          setMinimumAmountSheet(true);
          setMinOfferPrice(offer[0].minOfferAmount);
        }
      } else if (offer.length === 0) {
        handlePaymentModeSelectionLogic(mode);
      }
    }
  };

  const handleNoticeProceed = (setSelectedOffer) => {
    setSelectedOffer(null);
    if (offerAmount > 0 && offerApplicable.includes(showNotice)) {
      setOfferAmount(selectedOffer?.discountAmount);
    } else if (!offerApplicable.includes(showNotice)) {
      setOfferAmount(0);
    }
    setSelectedPaymentMethod(showNotice);
    setShowNotice(false);
  };

  const handleProtiumSuccess = () => {
    // redirect to protium url
    const { storeId, transactionId } = f7router.currentRoute.params;
    window.location = `/${storeId}/emi_offers/${transactionId}`;
  };

  const handleMinAmountCloseSheet = () => {
    setMinimumAmountSheet(false);
  };

  // OPEN GPAY APP IF THE GPAY SERVICE IS ENABLED
  useEffect(() => {
    event.on("openGpay", ({ method, appName }) => {
      processPayment(handleCallBack, method, appName);
    });
    return () => {
      event.unload("openGpay");
    };
    // eslint-disable-next-line
  }, []);

  // ZEST IMPLEMENTATION
  useEffect(() => {
    event.on("applyAndPay", (e, selectedLender, modeOfPayment) => {
      processPayment(handleCallBack, e, null, selectedLender, modeOfPayment);
    });
    // eslint-disable-next-line
  }, []);

  // TOGGLE THE PAY BUTTON TO MAKE ENABLE AND DISABLE
  useEffect(() => {
    event.on("togglePayBtn", (e) => {
      setPayBtnDisabled(e);
    });
    // eslint-disable-next-line
  }, []);

  return {
    loading,
    protiumPaymentTypes,
    razorPayPaymentTypes,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    updatePaymentPayload,
    processPayment,
    showNotice,
    setShowNotice,
    handlePaymentModeSelection,
    handleNoticeProceed,
    errorMsg,
    handleCallBack,
    offerAmount,
    protiumNotificationStatus,
    handleCloseProtiumNotication,
    handleProtiumSuccess,
    handleMinAmountCloseSheet,
    minimumAmountSheet,
    minOfferPrice,
    mop,
    isCardOpen,
    setIcardOpen,
    disabledPayBtn,
    instrumentData,
    rupifiType,
    rupifiEligibleValue,
    isVerifying,
  };
};
