import { useEffect, useState } from "react";

const useAppliedOffers = (data) => {
  const [appliedOffer, setOfferApplied] = useState({});

  const { offerId: appliedOfferId, offerData, totalOrderAmount } = data;
  useEffect(() => {
    if (appliedOfferId) {
      let offer = offerData.find(({ offerId }) => offerId === appliedOfferId);
      if (offer) setOfferApplied(offer);
    }
  }, [offerData, appliedOfferId]);

  return {
    appliedOffer,
    totalOrderAmount,
    appliedOfferId,
  };
};

export default useAppliedOffers;
