import { f7 } from "framework7-react";
import Api from "../../services/api.service";
import { useState } from "react";
const useKreditBee = ({ f7router }) => {
  const [isLoading, setIsloading] = useState(false);
  const processPayment = async (payMode) => {
    const storedData = JSON.parse(localStorage.getItem("stored_payload"));
    if (!storedData) return;
    const { transactionId } = f7router.currentRoute.params;
    setIsloading(true);
    const payloadObj = {
      ...storedData,
      useWallet: false,
      usedWalletBalance: 0,
      paymentId: transactionId,
      paidThrough: payMode,
    };
    f7.dialog.preloader("Please wait...");
    const resp = await Api.startPaymentProcess(payloadObj);
    debugger;
    if (resp?.data?.status === "error") {
      f7.dialog.close();
      setTimeout(() => {
        setIsloading(false);
        f7router.app.dialog.alert(resp.data.msg || "Unable to initate payment, please try again.", (e) => {});
      }, 50);
      return;
    }
    showLenderOffers(resp?.data);
    return;
  };

  const showLenderOffers = async (resData = {}) => {
    if (!resData) return;
    localStorage.removeItem("lenderOffers_store");
    const { orderId, paymentData, transactionAmount, paymentId } = resData;
    const { storeId } = f7router.currentRoute.params;
    const pageData = { orderId, paymentData, transactionAmount, paymentId, logoUrl: "https://gopay-prod-static.gostor.com/icons/banks/kreditbee" };
    // Removing Stored data from localstorage
    localStorage.removeItem("stored_payload");
    localStorage.removeItem("storeId");
    localStorage.removeItem("transactionId");
    if (paymentData?.is_eligible) {
      f7router.navigate(`/${storeId}/lender-offers/${orderId}`, {
        props: { pageData },
        transition: "f7-cover",
      });
    } else {
      f7router.navigate(`/${storeId}/order-status/${paymentId}`, { transition: "f7-cover" });
    }
  };

  return {
    processPayment,
    isLoading,
  };
};
export default useKreditBee;
