import "./App.css";
import React from "react";
import { App, View, f7 } from "framework7-react";
// import FloatingButton from "./components/floatingButton";
import PayFloatingButton from "./components/PayFloatingButton";
import routes from "./routes";
import store from "./store";
import GrowthBookFeatureProvider from "./components/GrowthBookFeatureProvider";

const config = {
  routes,
  name: "go-pay",
  id: "com.go-pay.arzooo",
  theme: "md",
};

function TheApp({ f7router }) {
  const [storeId, setStoreId] = React.useState(null);

  React.useEffect(() => {
    const f7router = f7.views.main.router;
    setStoreId(f7router?.currentRoute?.params?.storeId);

    f7router.on("pageBeforeOut", ({ app }) => {
      try {
        app.sheet.close(".modal-in"); // .sheet-modal has two sheet so it was not working
        app.popup.close(".popup");
        app.photoBrowser.close(".photo-browser-popup");
      } catch (e) {}
    });

    //  causing a issue which was gopay-291 and gopay-292

    // f7router.on("routeChanged", () => {
    //   try {
    //     window.history.pushState(null, null, window.location.href)
    //   } catch (e) {}
    // })

    window.addEventListener("popstate", () => {
      const openSheets = document.getElementsByClassName("sheet-modal modal-in");
      const openPopUp = document.getElementsByClassName("popup modal-in");
      if (openSheets.length > 1) {
        window.history.go(1);
        setTimeout(() => {
          f7router.app.sheet.close(".payToken-sheet", true, openSheets[openSheets.length - 1]);
        }, 100);
      } else if (openPopUp.length) {
        window.history.go(1);
        setTimeout(() => {
          f7router.app.sheet.close(".popup", true, openPopUp[0]);
        }, 100);
      } else {
        f7router.back();
      }
    });

    window.addEventListener("keyup", (e) => {
      // dismiss keyboard when submit button press on hardware keyboard
      if (e.target.tagName === "INPUT") {
        if (e.keyCode === 13) {
          e.preventDefault();
          e.target.blur();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <GrowthBookFeatureProvider>
      <App {...config} store={store}>
        <View themeDark main={true} url={"/"} preloadPreviousPage={false} browserHistory browserHistorySeparator="" browserHistoryStoreHistory={true} />
      </App>
      {/* <FloatingButton f7router={f7router} /> */}
      <PayFloatingButton storeId={storeId} />
    </GrowthBookFeatureProvider>
  );
}

export default TheApp;
