import { useState, useEffect } from "react";
import Api from "../../services/api.service";

const useEmiDetails = (offer, loanAmount, popupActivated, titleRef, bankSelectorRef) => {
  const [calHeight, setCalHeight] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [emiDetails, setEMIDetails] = useState({});
  const [bankList, setBankList] = useState([]);
  const [bankEMIList, setBankEMIList] = useState(null);
  const [interestRates, setInterestRates] = useState([]);
  const [initialLoanAmount, setInitialLoanAmount] = useState(loanAmount || 10000);

  useEffect(() => {
    const getEmiDetailsAPI = async () => {
      let payload = {
        paidThrough: offer.paidThrough,
        modeOfPayment: offer.modeOfPayment,
      };
      let { data } = await Api.getEMIDetails(payload);
      data.status && setEMIDetails(data.emiPlans);
    };
    offer.paidThrough && getEmiDetailsAPI();
  }, [offer.paidThrough, offer.modeOfPayment]);

  useEffect(() => {
    const emiBankList = Object.keys(emiDetails);
    if (emiDetails) {
      const banks = Object.keys(emiDetails).map((key) => ({
        key,
        value: key,
        icon: emiDetails[key].icon_url,
      }));
      setBankList(banks);
      const emiBank = emiBankList.includes("HDFC Bank") ? "HDFC Bank" : Object.keys(emiDetails)[0];
      handleBankChange(emiBank);
      setSelectedBank(emiBank); // although handleBankChange is called, it is not updating the state hence this line
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emiDetails]);

  useEffect(() => {
    if (bankEMIList?.annual_interest_rate) {
      const annualRates = bankEMIList.annual_interest_rate;
      const interestsRates = Object.keys(annualRates).map((monthDuration) => {
        const currentInterestRate = annualRates[monthDuration];
        const netEmi = calculateEMI(initialLoanAmount, monthDuration, currentInterestRate).toFixed(2);
        // passing initial loan amount , to avoid > value or < value of initial loan amount
        const netAmount = currentInterestRate === 0 ? initialLoanAmount : totalAmountCalculate(netEmi, monthDuration);
        const rate = annualRates[monthDuration];
        return {
          emi: netEmi,
          amount: handleNan(netAmount),
          monthDuration,
          rate,
        };
      });
      setInterestRates(interestsRates);
    }
  }, [bankEMIList?.annual_interest_rate, initialLoanAmount]);

  useEffect(() => {
    if (popupActivated) {
      const ADDITIONAL_MARGIN = 80; // for extra bottom space , title margin and bank selector margin
      let calculatorHeight = titleRef.current?.el?.offsetHeight + bankSelectorRef.current.el.offsetHeight + ADDITIONAL_MARGIN;
      setCalHeight(calculatorHeight);
    }
  }, [bankSelectorRef, popupActivated, setCalHeight, titleRef]);

  const handleNan = (value) => Math.round(isNaN(value) ? 0 : value);

  const handleBankChange = (key) => {
    const emiList = emiDetails[key];
    setBankEMIList(emiList);
  };

  const calculateEMI = (amount, time, r) => {
    // debugger;
    if (r === 0) {
      let val = amount / time;
      return val;
    } else {
      let rr = r / (12 * 100);
      let up = Math.pow(1 + rr, parseInt(time));
      let down = up - 1;
      let emi = amount * rr * (up / down);
      return emi;
    }
  };

  const totalAmountCalculate = (emi, month) => {
    let total = parseInt(emi) * parseInt(month);
    return total.toFixed(2);
  };

  const handleRangeChange = (val) => {
    setInitialLoanAmount(val);
  };

  const formatScaleLabel = (value) => {
    if (value === 0) {
      return "₹0";
    }
    if (value === 150000) {
      return "₹1.5L";
    }
    return "";
  };

  const handleBankSelect = (bank, cb = () => {}) => {
    setSelectedBank(bank);
    handleBankChange(bank);
    cb();
  };

  return {
    formatScaleLabel,
    emiDetails,
    bankList,
    bankEMIList,
    initialLoanAmount,
    calculateEMI,
    totalAmountCalculate,
    handleRangeChange,
    open,
    setOpen,
    selectedBank,
    calHeight,
    interestRates,
    handleBankSelect,
  };
};

export default useEmiDetails;
