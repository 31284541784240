import { useState, useEffect, useRef } from "react";
// import useGooglePayActiveStatus from "../../hooks/useGooglePayActiveStatus.hook";
import { UPI } from "../../utilities/constants";
import verifyVPAUpi from "../../utilities/verifyVPAUpi";
import { UpiIDs, popularUPIs, upiIconsMap } from "./upis.json";
import useOutsideClick from "../../hooks/useOutsideClick";
import { event } from "../../utilities/subscriber.events";
import Publisher from "../../utilities/publisher";
import { f7 } from "framework7-react";

const useUpi = (props) => {
  const [vpaAddress, setVpaAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [validateVPA, setValidateVPA] = useState(null);
  const [gpaySeleciton, setGpaySelection] = useState(null);
  const [showOptions, setshowOptions] = useState(false);
  const [suggestions, setSuggetstions] = useState(UpiIDs);
  const [selectedUpiAPP, setSelectedUpiAPP] = useState(null);
  const [upiResMsg, setUpiResMsg] = useState(null);
  const upiInputRef = useRef(null);

  // const { isGooglePayActive } = useGooglePayActiveStatus();

  const onChange = (e) => {
    setValidateVPA(null);
    setUpiResMsg(null);
    setGpaySelection(false);
    const value = e.target.value;
    setVpaAddress(e.target.value);
    if (value.search("@") !== -1) {
      let filtered = UpiIDs.filter((e) => e.split("@")[1].toLowerCase().startsWith(value.split("@")[1].toLowerCase()));
      setSuggetstions(filtered);
      setshowOptions(true);
    } else setshowOptions(false);
    props.updatePaymentPayload({
      methodDetails: { method: "upi", upi: { vpa: value, flow: "collect" } },
      paidThrough: props.type,
      // upiValidate:validateVPA
    });
  };

  const verifyVPA = async () => {
    if (/^[\w.-]+@[\w.-]+$/.test(vpaAddress)) {
      setLoading(true);
      setValidateVPA(null);
      let res = await verifyVPAUpi(vpaAddress);
      try {
        if (res.success) {
          setLoading(false);
          setValidateVPA(true);
          props.updatePaymentPayload({
            methodDetails: { method: "upi", upi: { vpa: vpaAddress, flow: "collect", valid: true } },
            paidThrough: props.type,
          });
        } else if (!res.success) {
          setLoading(false);
          setValidateVPA(false);
        }
      } catch (e) {
        setLoading(false);
        setValidateVPA(false);
      }
    } else {
      setValidateVPA(false);
    }
  };

  useEffect(() => {
    if (gpaySeleciton) {
      props.updatePaymentPayload({
        methodDetails: { method: "upi", gpay: true },
        paidThrough: props.type,
      });
    } else {
      props.updatePaymentPayload({
        methodDetails: { method: "upi", gpay: false },
        paidThrough: props.type,
      });
    }
    // eslint-disable-next-line
  }, [gpaySeleciton]);

  const handleGpaySelection = () => {
    setGpaySelection(!gpaySeleciton);
  };

  const updatePayload = (vpaId) => {
    props.updatePaymentPayload({
      methodDetails: { method: "upi", upi: { vpa: vpaId, flow: "collect" } },
      paidThrough: props.type,
    });
  };

  const onSelectUPIApp = (upiExt, appName = null, isUPIApp = false) => {
    // Check the case of other payment modes.
    setSelectedUpiAPP(null);
    const isDesktop = f7.device.desktop || f7.device.macos;
    const isIOS = f7.device.ios;
    // Check if is on Desktop or IOS Device
    if (isDesktop || (isIOS && appName === "any")) {
      onUpiUpdate(upiExt, appName);
      return;
    }
    if (isUPIApp) {
      Publisher.openGpayApp({ method: UPI, appName });
      return;
    }
    onUpiUpdate(upiExt, appName);
  };
  /**
   * select the popular UPI Apps
   * */
  const onUpiUpdate = (upiExt, appName = null) => {
    setSelectedUpiAPP(appName);
    /**
     * to check if the GPay Service is active or not along with app name should be "GPay"
     * */
    //  isGooglePayActive &&
    // if (isGooglePayActive && appName === GPAY) {
    //   setGpaySelection(true);
    //   setTimeout(() => Publisher.openGpayApp(UPI), 100);
    //   return;
    // }
    let upiStr = vpaAddress.split("@")[0];
    const UPIADDRESS = upiStr.concat(upiExt);
    setVpaAddress(UPIADDRESS);
    updatePayload(UPIADDRESS);
    setTimeout(() => {
      upiInputRef.current.focus();
      upiInputRef.current.setSelectionRange(0, 0);
    }, 10);
    setshowOptions(false);
  };

  const clearField = () => {
    setVpaAddress("");
    setValidateVPA(null);
  };

  /**
   * to close the upi suggestion on clicking outside
   *  */
  useOutsideClick(upiInputRef, () => {
    if (showOptions) setshowOptions(false);
  });

  useEffect(() => {
    event.on("upiValidation", (e) => {
      setUpiResMsg(e);
    });
  }, []);

  return {
    vpaAddress,
    loading,
    validateVPA,
    handleGpaySelection,
    onChange,
    verifyVPA,
    gpaySeleciton,
    popularUPIs,
    onUpiUpdate,
    setVpaAddress,
    clearField,
    showOptions,
    suggestions,
    upiInputRef,
    selectedUpiAPP,
    upiResMsg,
    upiIconsMap,
    onSelectUPIApp,
  };
};

export default useUpi;
